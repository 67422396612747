import { Button } from '@/shared/components/Button'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import Switch from 'react-switch'
import styled from 'styled-components'
import { MemberResterBankSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { searchNtryMiniGameManagement, updateNtryMiniGame } from '../../../../utils/api/systemManage/miniGameManageApi'
import { priceNumPattern } from '../../../../utils/sportUtills'
import { decodeAccessToken } from '../../../../utils/token'

const NtryEosSpeedKenoForm = ({ miniGameType }) => {
  const authority = decodeAccessToken()?.adminAuthority

  const [loading, setLoading] = useState(false)

  const [isBettingOption, setBettingOption] = useState(0)
  // 마감시간
  const [isEndTime, setEndTime] = useState(0)
  const [bettingPerModel, setBettingPerModel] = useState({})

  // 노출 여부
  const [isShowOptionValue, setIsShowOptionValue] = useState(null)
  const isShowOption = [
    {
      value: true,
      label: '정상',
    },
    {
      value: false,
      label: '점검',
    },
  ]

  const fetchMiniGameManagement = () => {
    setLoading(true)

    searchNtryMiniGameManagement({
      miniGameType,
    })
      .then(res => {
        const { show, endTime, bettingOption, bettingPerModel } = res.data

        setIsShowOptionValue({
          value: show,
          label: show ? '정상' : '점검',
        })

        setEndTime(endTime)
        setBettingOption(bettingOption)
        setBettingPerModel(JSON.parse(bettingPerModel))
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChangeStatusHandler = option => {
    setIsShowOptionValue(option)
  }

  useEffect(() => {
    fetchMiniGameManagement()
  }, [miniGameType])

  const onSubmit = e => {
    if (loading) return

    if (window.confirm('설정을 변경 하시겠습니까? ')) {
      const body = {
        miniGameType,
        show: isShowOptionValue.value,
        endTime: isEndTime,
        bettingOption: isBettingOption,
        bettingPerModel: JSON.stringify(bettingPerModel),
      }

      setLoading(true)

      updateNtryMiniGame(body)
        .then(res => {
          alert('설정이 변경됐습니다.')
        })
        .catch(error => {
          alert('변경 실패했습니다. 다시 시도해주세요.')
        })
        .finally(() => {
          setLoading(false)
          fetchMiniGameManagement()
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          bettingPerModel,
          isBettingOption,
          isEndTime,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <MiniGameForm horizontal onSubmit={handleSubmit}>
            <MiniGameFormInnerBox>
              <table>
                <tr>
                  <th scope="row">상태</th>
                  <td>
                    <Field name="isShowOptionValue">
                      {({ input, meta }) => (
                        <MemberResterBankSelect
                          {...input}
                          value={isShowOptionValue}
                          onChange={option => {
                            onChangeStatusHandler(option)
                          }}
                          options={isShowOption}
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                      )}
                    </Field>
                  </td>

                  <th scope="row">동일라운드 배팅횟수</th>
                  <td>
                    <Field name="isBettingOption">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={isBettingOption}
                          onChange={e => {
                            setBettingOption(e.target.value)
                          }}
                        />
                      )}
                    </Field>
                  </td>

                  <th scope="row">마감 시간 (초)</th>
                  <td>
                    <Field name="isEndTime">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={isEndTime}
                          onChange={e => {
                            setEndTime(e.target.value)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">홀짝</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedOddEven: !bettingPerModel?.usedOddEven ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedOddEven ?? false}
                    />
                  </td>
                  <th scope="row">홀</th>
                  <td>
                    <Field name="odd">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.odd ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                odd: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">짝</th>
                  <td>
                    <Field name="even">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.even ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                even: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">언더오버</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedUnderOver: !bettingPerModel?.usedUnderOver ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedUnderOver ?? false}
                    />
                  </td>
                  <th scope="row">언더</th>
                  <td>
                    <Field name="under">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.under ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                under: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">오버</th>
                  <td>
                    <Field name="over">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.over ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                over: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">홀짝 + 언더오버 조합</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedOddEvenUnderOver: !bettingPerModel?.usedOddEvenUnderOver ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedOddEvenUnderOver ?? false}
                    />
                  </td>
                  <th scope="row">홀+언더</th>
                  <td>
                    <Field name="oddUnder">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.oddUnder ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                oddUnder: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">홀+오버</th>
                  <td>
                    <Field name="oddOver">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.oddOver ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                oddOver: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">짝+언더</th>
                  <td>
                    <Field name="evenUnder">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.evenUnder ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                evenUnder: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">짝+오버</th>
                  <td>
                    <Field name="evenOver">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.evenOver ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                evenOver: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
              </table>
            </MiniGameFormInnerBox>
            {(authority === 'SUPER' || authority === 'DB_DOWN') && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Button size="lg" variant="outline-secondary" type="submit">
                  변경하기
                </Button>
              </div>
            )}
          </MiniGameForm>
        )}
      </Form>
    </>
  )
}

export default NtryEosSpeedKenoForm

NtryEosSpeedKenoForm.propTypes = {
  miniGameType: PropTypes.string.isRequired,
}

const MiniGameForm = styled.form`
  display: flex;
  flex-direction: column;

  padding: 20px;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #000;
    color: #646777;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const MiniGameFormInnerBox = styled.div`
  width: 100%;

  table {
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      text-align: right;
      padding-right: 15px;
    }

    td {
      padding: 10px;
    }
  }
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
