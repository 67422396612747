import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { SportMarketType } from '../../../enums/Sport/SportMarketEnums'
import { sportResultStatusEnums, SportsRealTimeResultStatusEnums } from '../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import { searchRealTimeSportsBettingV2 } from '../../../utils/api/logManage/logApi'
import useAudio from '../../../utils/audioHook'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import SportDetailBettingLog from '../SportBettingLogV2/SportDetailBettingLog'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const RealTimeSportBettingLogListV2 = () => {
  const [play, pause, paused, audioRef] = useAudio(`${process.env.PUBLIC_URL}/audio/betting.mp3`, {
    playbackRate: 1,
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const authority = decodeAccessToken()?.adminAuthority

  const [muteAudio, setMuteAudio] = useState(JSON.parse(sessionStorage.getItem('BTSA')))

  const onCheckMuteAudioHandler = e => {
    setMuteAudio(e.target.checked)
    // BTSA = Betting Sports Audio
    sessionStorage.setItem('BTSA', e.target.checked)
  }

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      sportsResultStatus: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }
  const [sportTotalRealTimeCount, setSportsTotalRealTimeCount] = useState(0)
  const [prevTotalElement, setPrevTotalElement] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [sportsBettingLogList, setSportsBettingLogList] = useState([])

  const sportsBettingLogListRef = useRef(sportsBettingLogList)

  useEffect(() => {
    sportsBettingLogListRef.current = sportsBettingLogList
  }, [sportsBettingLogList])

  const fetchSportsBettingLog = async () => {
    await searchRealTimeSportsBettingV2(bettingLogparams)
      .then(res => {
        const existingDataMap = new Map(sportsBettingLogListRef.current.map(item => [item.sportsBettingLogKey, item]))

        const newSportsBettingLogList = res.data.sportsBettingLogList.map(newItem => {
          const existingItem = existingDataMap.get(newItem.sportsBettingLogKey)

          return {
            ...newItem,
            isVisible: existingItem ? existingItem.isVisible : false,
          }
        })

        setSportsBettingLogList(newSportsBettingLogList)

        setTotalElement(res.data.totalElements)
        setSportsTotalRealTimeCount(res.data.totalRealTimeCount)
        setLoading(false)
        if (res.data.totalElements > prevTotalElement) {
          if (!muteAudio && res.data.totalRealTimeCount > 0 && paused()) {
            play()
          }
          setPrevTotalElement(res.data.totalElements)
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSportsBettingLog()

    const interval = setInterval(() => {
      fetchSportsBettingLog()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [bettingLogparams, prevTotalElement, muteAudio])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 특정 배팅로그 펼치기
  const onClickSportsBettingLogHandler = sportsBettingLogKey => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.sportsBettingLogKey === sportsBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  return (
    <Card>
      <CardBody style={{ padding: '0px' }}>
        <HeaderWrap>
          <h2 style={{ color: 'green' }}>스포츠 실시간 내역 (진행중 + 미적중예정)</h2>
          <label
            htmlFor="miniGameCheck"
            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '10px' }}
          >
            <input
              type="checkbox"
              id="miniGameCheck"
              checked={muteAudio}
              onChange={e => {
                onCheckMuteAudioHandler(e)
              }}
              style={{ marginRight: '10px' }}
            />
            <span style={{ color: 'red', fontWeight: '500', fontSize: '13px' }}>
              * 스포츠 음소거하려면 체크해주세요.
            </span>
          </label>
        </HeaderWrap>
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당회원의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * 해당 페이지는 <span style={{ color: 'red', fontWeight: '600' }}>배팅 진행중</span>의 회원들을 볼 수
              있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <div style={{ display: 'flex', gap: '10px' }}>
          <SearchStatusWrap>
            <Button
              size="sm"
              active={searchStatusOptionValue === null}
              variant="outline-secondary"
              onClick={e => {
                onChangeSearchStatusHandler(null)
              }}
            >
              전체
            </Button>
            {Object.keys(SportsRealTimeResultStatusEnums).map(status => {
              return (
                <Button
                  id={status}
                  size="sm"
                  active={searchStatusOptionValue === status}
                  variant="outline-secondary"
                  onClick={e => {
                    onChangeSearchStatusHandler(status)
                  }}
                >
                  {SportsRealTimeResultStatusEnums[status]}
                </Button>
              )
            })}
          </SearchStatusWrap>
        </div>
        <SportsGameListTableWrap>
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>상위파트너 (이름)</th>
                <th>유저 (이름)</th>
                <th>배팅전 머니</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>배팅후 머니</th>
                <th>타입</th>
                <th>폴더</th>
                <th>배당</th>
                <th>예상/최종 당첨금</th>
                <th>결과</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {sportsBettingLogList.map(sportsBettingLog => {
                return (
                  <>
                    <tr
                      key={sportsBettingLog.sportsBettingLogKey}
                      onClick={e => {
                        onClickSportsBettingLogHandler(sportsBettingLog.sportsBettingLogKey)
                      }}
                    >
                      <td>{sportsBettingLog.num}</td>
                      <td>{sportsBettingLog.edgeName}</td>
                      <td>{convertToKstSportsEventDateTime(sportsBettingLog.bettingAt)}</td>
                      <td>
                        {sportsBettingLog.topUserId ? (
                          <>
                            {sportsBettingLog.topUserId} ({sportsBettingLog.topUserName})
                          </>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${sportsBettingLog.userId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {sportsBettingLog.userId} ({sportsBettingLog.userName})
                        </span>
                        <span style={{ marginLeft: '5px' }}>
                          <CustomAuthorityButton authority={sportsBettingLog.userAuthority}>
                            {NoteAuthorityEnums[sportsBettingLog.userAuthority]}
                          </CustomAuthorityButton>
                        </span>
                        <span>
                          <span>
                            <MemberDepositInfo memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberNote memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={sportsBettingLog.userId} />
                          </span>
                        </span>
                      </td>
                      <td>{sportsBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                      <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                        {sportsBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                      </td>
                      <td>{sportsBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                      <CustomTd sportMarketType={sportsBettingLog.sportMarketType}>
                        {SportMarketType[sportsBettingLog.sportMarketType]}
                      </CustomTd>
                      <td>{sportsBettingLog.folderSize} 폴더</td>
                      <td>
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = sportsBettingLog
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                          if (reduceBettingValue === null) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                            <TooltipWrapper text="적용배당 / (원본배당 - 조정배당)" place="bottom">
                              {Number(reduceBettingValue) < 0 ? (
                                <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              ) : (
                                <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              )}
                            </TooltipWrapper>
                          )
                        })()}
                      </td>

                      <td>
                        <span style={{ color: 'blue' }}>
                          {sportsBettingLog.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                        </span>
                        <span style={{ margin: '0 5px' }}>/</span>
                        <span style={{ color: 'red' }}>
                          {sportsBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                        </span>
                      </td>
                      <td>
                        <CustomButton type="button" bettingResult={sportsBettingLog.sportsResultStatus}>
                          {sportResultStatusEnums[sportsBettingLog.sportsResultStatus]}
                        </CustomButton>
                      </td>
                      <td>
                        {sportsBettingLog.showFlag ? <span>노출</span> : <span style={{ color: 'red' }}>비노출</span>}
                      </td>
                    </tr>
                    {sportsBettingLog.isVisible && (
                      <SportDetailBettingLog
                        sportsDetailBettingLogList={sportsBettingLog.sportsDetailBettingLogList}
                        bonsuFolder={{
                          bonusFolderNum: sportsBettingLog.bonusFolderNum,
                          bonusFolderPer: sportsBettingLog.bonusFolderPer,
                        }}
                      />
                    )}
                  </>
                )
              })}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default RealTimeSportBettingLogListV2
const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`

const CustomButton = styled.button`
  width: 70px;
  height: 25px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`
const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 13%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResult2Box = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentHomeBox = styled.div`
  width: 15%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 15%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  width: 100px;
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 10%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`
const NewBadge = styled(Badge)`
  width: 20px;
  height: 14px;
  background-color: #ff4861;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    width: 20px;
    text-align: center;
  }
`

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  padding-left: 15px;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
