import { ButtonToolbar } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorText,
  colorWhite,
} from '@/utils/palette'
import { Checkbox } from '@mui/material'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Badge, Modal as BootstrapModal, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import {
  BonusDepositType,
  BonusDepositTypeKr,
  BonusShowFullTypeKr,
  BonusShowTodayTypeKr,
  BonusShowTypeKr,
  BonusShowWeekTypeKr,
  DepositBounsTypeKrV2,
  DepositBounsTypeV2,
  DepositVisibleTimeType,
  DepositVisibleTimeTypeKr,
  RollingDepositType,
  RollingDepositTypeKr,
} from '../../../enums/MemberHistory/MemberDepositEnums'
import { MemberDepositLevelEnum } from '../../../enums/MemberManage/MemberInfoEnums'
import { DatePickerWrap } from '../../../shared/components/form/date-pickers/DatePickerElements'
import Loading from '../../../shared/components/Loading'
import { createDepositBonusV2 } from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import { convertToKst } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { depositLevelBonusValidateV2 } from '../../../utils/validate/systemManagement/levelManage'

function DepositBonusAddModalV2({
  depositLevel,
  depositBonusAddModalShow,
  onCloseDepositBonusAddModalHnadler,
  fetchSearchDepositBonus,
}) {
  const [apiLoading, setApiLoading] = useState(false)

  const [bonusType, setBonusType] = useState(DepositBounsTypeV2.BONUS_NONE)
  const [bonusDepositType, setBonusDepositType] = useState(BonusDepositType.RATIO_PAYMENT)
  const [bonusTitle, setBonusTitle] = useState('')
  const [bonusContent, setBonusContent] = useState('')
  const [bonusPer, setBonusPer] = useState('')
  const [depositCount, setDepositCount] = useState('')
  const [bonusOneMaxAmount, setBonusOneMaxAmount] = useState('')
  const [includeHoldemType, setIncludeHoldemType] = useState(true)
  const [alwaysVisible, setAlwaysVisible] = useState(false)
  const [includeLastDepositAt, setIncludeLastDepositAt] = useState(false)
  const [bonusShowType, setBonusShowType] = useState([])
  const [withdrawalRollingType, setWithdrawalRollingType] = useState(RollingDepositType.NONE)
  const [orderNum, setOrderNum] = useState('')
  const [usedVisibleTime, setUsedVisibleTime] = useState(false)
  const [depositVisibleTimeType, setDepositVisibleTimeType] = useState(null)
  const [visibleStartTime, setVisibleStartTime] = useState(new Date())
  const [visibleEndTime, setVisibleEndTime] = useState(new Date())

  const handleBonuShowCheckboxChange = item => {
    setBonusShowType(prev => (prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]))
  }

  const onSubmit = allLevel => {
    if (apiLoading) return

    setApiLoading(true)

    const body = {
      allLevel,
      depositLevel,
      bonusType,
      bonusDepositType,
      bonusTitle,
      bonusContent,
      bonusPer,
      depositCount,
      bonusOneMaxAmount,
      bonusShowType,
      withdrawalRollingType,
      orderNum,
      includeHoldemType,
      alwaysVisible,
      includeLastDepositAt,
      usedVisibleTime,
      depositVisibleTimeType,
      visibleStartTime: !visibleStartTime ? null : convertToKst(visibleStartTime),
      visibleEndTime: !visibleEndTime ? null : convertToKst(visibleEndTime),
    }

    if (window.confirm(allLevel ? '정말로 모든 레벨에 추가하시겠습니까?' : '정말로 추가하시겠습니까?')) {
      createDepositBonusV2(body)
        .then(res => {
          alert('추가되었습니다.')
          onCloseDepositBonusAddModalHnadler()
        })
        .catch(error => {
          alert('실패했습니다.')
        })
        .finally(() => {
          fetchSearchDepositBonus()
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal show={depositBonusAddModalShow} onHide={onCloseDepositBonusAddModalHnadler} $header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseDepositBonusAddModalHnadler}
          />
          <ModalTitle>
            <span>충전 보너스 추가</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>입금 레벨</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>{MemberDepositLevelEnum[depositLevel]}</SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                depositLevel,
                bonusType,
                bonusDepositType,
                bonusTitle,
                bonusContent,
                bonusPer,
                depositCount,
                bonusOneMaxAmount,
                bonusShowType,
                withdrawalRollingType,
                orderNum,
                includeHoldemType,
                alwaysVisible,
              }}
              validate={depositLevelBonusValidateV2}
            >
              {({ handleSubmit, form: { reset } }) => (
                <SportsMarketFormContainer horizontal onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  <FormGroup>
                    <FormGroupLabel>게임 타입</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusType">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <CustomLabel htmlFor="BONUS_NONE">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_NONE"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_NONE}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_NONE}
                                />
                                <span>{DepositBounsTypeKrV2.BONUS_NONE}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_SPORTS">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_SPORTS"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_SPORTS}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_SPORTS}
                                />

                                <span>{DepositBounsTypeKrV2.BONUS_SPORTS}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_CASINO">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_CASINO"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_CASINO}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_CASINO}
                                />

                                <span>{DepositBounsTypeKrV2.BONUS_CASINO}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_SLOT_CASINO">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_SLOT_CASINO"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_SLOT_CASINO}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_SLOT_CASINO}
                                />

                                <span>{DepositBounsTypeKrV2.BONUS_SLOT_CASINO}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_MINI_GAME">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_MINI_GAME"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_MINI_GAME}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_MINI_GAME}
                                />
                                <span>{DepositBounsTypeKrV2.BONUS_MINI_GAME}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_VIRTUAL_GAME">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_VIRTUAL_GAME"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_VIRTUAL_GAME}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_VIRTUAL_GAME}
                                />
                                <span>{DepositBounsTypeKrV2.BONUS_VIRTUAL_GAME}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="BONUS_HOLDEM">
                                <input
                                  {...input}
                                  type="radio"
                                  id="BONUS_HOLDEM"
                                  name="BonusType"
                                  value={DepositBounsTypeV2.BONUS_HOLDEM}
                                  onChange={e => {
                                    setBonusType(e.target.value)
                                  }}
                                  checked={bonusType === DepositBounsTypeV2.BONUS_HOLDEM}
                                />

                                <span>{DepositBounsTypeKrV2.BONUS_HOLDEM}</span>
                              </CustomLabel>
                            </div>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>지급 타입</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusDepositType">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CustomLabel htmlFor="RATIO_PAYMENT">
                                <input
                                  {...input}
                                  type="radio"
                                  id="RATIO_PAYMENT"
                                  name="BonusDepositType"
                                  value={BonusDepositType.RATIO_PAYMENT}
                                  onChange={e => {
                                    setBonusDepositType(e.target.value)
                                  }}
                                  checked={bonusDepositType === BonusDepositType.RATIO_PAYMENT}
                                />
                                <span>{BonusDepositTypeKr.RATIO_PAYMENT}</span>
                              </CustomLabel>

                              <CustomLabel htmlFor="MONEY_PAYMENT">
                                <input
                                  {...input}
                                  type="radio"
                                  id="MONEY_PAYMENT"
                                  name="BonusDepositType"
                                  value={BonusDepositType.MONEY_PAYMENT}
                                  onChange={e => {
                                    setBonusDepositType(e.target.value)
                                  }}
                                  checked={bonusDepositType === BonusDepositType.MONEY_PAYMENT}
                                />
                                <span>{BonusDepositTypeKr.MONEY_PAYMENT}</span>
                              </CustomLabel>
                            </div>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>보너스 명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusTitle">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={bonusTitle}
                              onChange={e => {
                                setBonusTitle(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>보너스 내용</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusContent">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={bonusContent}
                              onChange={e => {
                                setBonusContent(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>지급 비율 (%)</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusPer">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              width: '20%',
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={bonusPer}
                              placeholder="0"
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setBonusPer(onlyNumber)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>충전 회차 및 노출여부</FormGroupLabel>
                    <FormGroupField>
                      <Field name="depositCount">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              width: '20%',
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={depositCount}
                              placeholder="0"
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setDepositCount(onlyNumber)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                      <Field name="depositCountShow">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              marginLeft: '10px',
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <label
                              key="DEPOSIT_COUNT"
                              htmlFor="DEPOSIT_COUNT"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0 5px',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                {...input}
                                type="checkbox"
                                id="DEPOSIT_COUNT"
                                checked={bonusShowType.includes('DEPOSIT_COUNT')}
                                onChange={e => {
                                  handleBonuShowCheckboxChange('DEPOSIT_COUNT')
                                }}
                                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              />

                              {BonusShowTypeKr.DEPOSIT_COUNT}
                            </label>
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>1회 지급 최대액 (원)</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusOneMaxAmount">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={bonusOneMaxAmount?.toString().replace(commonReg2, ',')}
                              placeholder="0"
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setBonusOneMaxAmount(onlyNumber)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>출금 롤링타입</FormGroupLabel>
                    <FormGroupField>
                      <Field name="withdrawalRollingType">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CustomLabel htmlFor="NONE">
                                <input
                                  {...input}
                                  type="radio"
                                  id="NONE"
                                  name="RollingDepositType"
                                  value={RollingDepositType.NONE}
                                  onChange={e => {
                                    setWithdrawalRollingType(e.target.value)
                                  }}
                                  checked={withdrawalRollingType === RollingDepositType.NONE}
                                />
                                {RollingDepositTypeKr.NONE}
                              </CustomLabel>

                              <CustomLabel htmlFor="FIRST">
                                <input
                                  {...input}
                                  type="radio"
                                  id="FIRST"
                                  name="RollingDepositType"
                                  value={RollingDepositType.FIRST}
                                  onChange={e => {
                                    setWithdrawalRollingType(e.target.value)
                                  }}
                                  checked={withdrawalRollingType === RollingDepositType.FIRST}
                                />
                                {RollingDepositTypeKr.FIRST}
                              </CustomLabel>

                              <CustomLabel htmlFor="EVERY">
                                <input
                                  {...input}
                                  type="radio"
                                  id="EVERY"
                                  name="RollingDepositType"
                                  value={RollingDepositType.EVERY}
                                  onChange={e => {
                                    setWithdrawalRollingType(e.target.value)
                                  }}
                                  checked={withdrawalRollingType === RollingDepositType.EVERY}
                                />
                                {RollingDepositTypeKr.EVERY}
                              </CustomLabel>

                              <CustomLabel htmlFor="NEW">
                                <input
                                  {...input}
                                  type="radio"
                                  id="NEW"
                                  name="RollingDepositType"
                                  value={RollingDepositType.NEW}
                                  onChange={e => {
                                    setWithdrawalRollingType(e.target.value)
                                  }}
                                  checked={withdrawalRollingType === RollingDepositType.NEW}
                                />
                                {RollingDepositTypeKr.NEW}
                              </CustomLabel>
                            </div>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>상시 노출 여부</FormGroupLabel>
                    <FormGroupField style={{ flexDirection: 'column' }}>
                      <Field name="alwaysVisible">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0',
                            }}
                          >
                            <label
                              key="alwaysVisible"
                              htmlFor="alwaysVisible"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0 5px',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                {...input}
                                type="checkbox"
                                id="alwaysVisible"
                                checked={alwaysVisible}
                                onChange={e => {
                                  setAlwaysVisible(e.target.checked)
                                }}
                                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              />
                              상시 노출 허용
                            </label>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>

                      <span>
                        * 노출 조건과 상관없이 <strong style={{ color: 'red' }}>[ 항상 ]</strong> 유저에게 노출이
                        됩니다.
                      </span>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>노출 시간 설정</FormGroupLabel>
                    <FormGroupField style={usedVisibleTime ? { marginBottom: '3px' } : {}}>
                      <Field name="usedVisibleTime">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              width: '30%',
                              padding: '5px 0',
                              marginRight: '10px',
                            }}
                          >
                            <label
                              key="usedVisibleTime"
                              htmlFor="usedVisibleTime"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0 5px',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                {...input}
                                type="checkbox"
                                id="usedVisibleTime"
                                checked={usedVisibleTime}
                                onChange={e => {
                                  setUsedVisibleTime(e.target.checked)
                                }}
                                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              />
                              노출 시간 설정
                            </label>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>

                      {usedVisibleTime && (
                        <>
                          <Field name="visibleStartTime">
                            {({ input, meta }) => (
                              <CustomDatePickerWrap>
                                <DatePicker
                                  selected={visibleStartTime}
                                  onChange={date => {
                                    setVisibleStartTime(date)
                                  }}
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  dropDownMode="select"
                                  timeFormat="HH:mm"
                                  showTimeInput
                                  popperPlacement="bottom-start"
                                  placeholderText="시작일"
                                  locale={ko}
                                  isClearable
                                />
                              </CustomDatePickerWrap>
                            )}
                          </Field>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '18px',
                              margin: '0 5px',
                            }}
                          >
                            {' '}
                            ~{' '}
                          </div>
                          <Field name="visibleEndTime">
                            {({ input, meta }) => (
                              <CustomDatePickerWrap>
                                <DatePicker
                                  selected={visibleEndTime}
                                  onChange={date => {
                                    setVisibleEndTime(date)
                                  }}
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  dropDownMode="select"
                                  timeFormat="HH:mm"
                                  showTimeInput
                                  popperPlacement="bottom-start"
                                  placeholderText="시작일"
                                  locale={ko}
                                  isClearable
                                />
                              </CustomDatePickerWrap>
                            )}
                          </Field>
                        </>
                      )}
                    </FormGroupField>

                    {usedVisibleTime && (
                      <FormGroupField>
                        <Field name="withdrawalRollingType">
                          {({ input, meta }) => (
                            <FormInputWrap
                              style={{
                                border: '1px solid black',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <CustomLabel htmlFor="ONCE">
                                  <input
                                    {...input}
                                    type="radio"
                                    id="ONCE"
                                    name="DepositVisibleTimeType"
                                    value={DepositVisibleTimeType.ONCE}
                                    onChange={e => {
                                      setDepositVisibleTimeType(e.target.value)
                                    }}
                                    checked={depositVisibleTimeType === DepositVisibleTimeType.ONCE}
                                  />
                                  {DepositVisibleTimeTypeKr.ONCE}
                                </CustomLabel>
                                <CustomLabel htmlFor="INFINITY">
                                  <input
                                    {...input}
                                    type="radio"
                                    id="INFINITY"
                                    name="DepositVisibleTimeType"
                                    value={DepositVisibleTimeType.INFINITY}
                                    onChange={e => {
                                      setDepositVisibleTimeType(e.target.value)
                                    }}
                                    checked={depositVisibleTimeType === DepositVisibleTimeType.INFINITY}
                                  />
                                  {DepositVisibleTimeTypeKr.INFINITY}
                                </CustomLabel>
                                <CustomLabel htmlFor="BEFORE_WITHDRAWAL">
                                  <input
                                    {...input}
                                    type="radio"
                                    id="BEFORE_WITHDRAWAL"
                                    name="DepositVisibleTimeType"
                                    value={DepositVisibleTimeType.BEFORE_WITHDRAWAL}
                                    onChange={e => {
                                      setDepositVisibleTimeType(e.target.value)
                                    }}
                                    checked={depositVisibleTimeType === DepositVisibleTimeType.BEFORE_WITHDRAWAL}
                                  />
                                  {DepositVisibleTimeTypeKr.BEFORE_WITHDRAWAL}
                                </CustomLabel>
                              </div>
                            </FormInputWrap>
                          )}
                        </Field>
                      </FormGroupField>
                    )}

                    <FormGroupField>
                      <span>
                        * 체크시 노출조건과 상관없이 <strong style={{ color: 'red' }}>[ 해당 시간대 ] </strong>에
                        노출됩니다.
                      </span>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>노출 조건</FormGroupLabel>
                    <FormGroupField style={{ flexDirection: 'column' }}>
                      <Field name="bonusOneMaxAmount">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                            }}
                          >
                            <div style={{ width: '100%', height: '100%', borderRight: '1px solid black' }}>
                              {Object.entries(BonusShowTodayTypeKr).map(([key, value], index) => {
                                return (
                                  <label
                                    key={key}
                                    htmlFor={key}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: '0',
                                      padding: '0 5px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <input
                                      {...input}
                                      type="checkbox"
                                      id={key}
                                      checked={bonusShowType.includes(key)}
                                      onChange={e => {
                                        handleBonuShowCheckboxChange(key)
                                      }}
                                      style={{ width: '15px', height: '15px', marginRight: '5px' }}
                                    />

                                    {value}
                                  </label>
                                )
                              })}
                            </div>
                            <div style={{ width: '100%', height: '100%', borderRight: '1px solid black' }}>
                              {Object.entries(BonusShowWeekTypeKr).map(([key, value], index) => {
                                return (
                                  <label
                                    key={key}
                                    htmlFor={key}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: '0',
                                      padding: '0 5px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <input
                                      {...input}
                                      type="checkbox"
                                      id={key}
                                      checked={bonusShowType.includes(key)}
                                      onChange={e => {
                                        handleBonuShowCheckboxChange(key)
                                      }}
                                      style={{ width: '15px', height: '15px', marginRight: '5px' }}
                                    />

                                    {value}
                                  </label>
                                )
                              })}
                            </div>
                            <div style={{ width: '100%', height: '100%' }}>
                              {Object.entries(BonusShowFullTypeKr).map(([key, value], index) => {
                                return (
                                  <label
                                    key={key}
                                    htmlFor={key}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: '0',
                                      padding: '0 5px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <input
                                      {...input}
                                      type="checkbox"
                                      id={key}
                                      checked={bonusShowType.includes(key)}
                                      onChange={e => {
                                        handleBonuShowCheckboxChange(key)
                                      }}
                                      style={{ width: '15px', height: '15px', marginRight: '5px' }}
                                    />

                                    {value}
                                  </label>
                                )
                              })}
                            </div>
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>

                      <span>
                        * 노출조건이 <strong style={{ color: 'red' }}>[ 모두 ]</strong> 부합되면, 유저에게 노출이
                        됩니다.
                      </span>
                      <span>
                        * 금주 기준은 <strong style={{ color: 'red' }}>[ 월요일 ~ 일요일 ]</strong> 입니다.
                      </span>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>당일 환전이력 기준</FormGroupLabel>
                    <FormGroupField style={{ flexDirection: 'column' }}>
                      <Field name="alwaysVisible">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0',
                            }}
                          >
                            <label
                              key="includeLastDepositAt"
                              htmlFor="includeLastDepositAt"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0 5px',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                {...input}
                                type="checkbox"
                                id="includeLastDepositAt"
                                checked={includeLastDepositAt}
                                onChange={e => {
                                  setIncludeLastDepositAt(e.target.checked)
                                }}
                                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              />
                              당일 입금 기준으로 설정
                            </label>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>

                      <span style={{}}>
                        * 체크시 <strong style={{ color: 'red' }}>[ 당일 입금에 대한 환전이력 ] </strong> 기준으로{' '}
                        <strong style={{ color: 'red' }}>[ 노출조건 ]</strong>이 처리됩니다.
                      </span>

                      <span style={{}}>
                        * 해제시 <strong style={{ color: 'red' }}>[ 당일 환전이력 ] </strong> 기준으로{' '}
                        <strong style={{ color: 'red' }}>[ 노출조건 ]</strong>이 처리됩니다.
                      </span>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>홀덤 포함 여부</FormGroupLabel>
                    <FormGroupField style={{ flexDirection: 'column' }}>
                      <Field name="includeHoldemType">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0',
                            }}
                          >
                            <label
                              key="includeHoldemType"
                              htmlFor="includeHoldemType"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0 5px',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                {...input}
                                type="checkbox"
                                id="includeHoldemType"
                                checked={includeHoldemType}
                                onChange={e => {
                                  setIncludeHoldemType(e.target.checked)
                                }}
                                style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              />
                              홀덤 포함
                            </label>

                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>

                      <span>
                        * 노출 조건 전종목 기준에 <strong style={{ color: 'red' }}>[ 홀덤 입/출금 내역을 ]</strong>{' '}
                        포함할지 결정하는 곳입니다.{' '}
                      </span>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>순서</FormGroupLabel>
                    <FormGroupField>
                      <Field name="orderNum">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              width: '20%',
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={orderNum}
                              placeholder="0"
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setOrderNum(onlyNumber)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        onSubmit(false)
                      }}
                    >
                      추가하기
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        onSubmit(true)
                      }}
                    >
                      모든 레벨 추가하기
                    </Button>
                  </FormButtonToolbar>
                </SportsMarketFormContainer>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default DepositBonusAddModalV2

DepositBonusAddModalV2.propTypes = {
  depositLevel: PropTypes.string.isRequired,
  depositBonusAddModalShow: PropTypes.bool,
  onCloseDepositBonusAddModalHnadler: PropTypes.func,
  fetchSearchDepositBonus: PropTypes.func,
}

DepositBonusAddModalV2.defaultProps = {
  depositBonusAddModalShow: false,
  onCloseDepositBonusAddModalHnadler: () => {},
  fetchSearchDepositBonus: () => {},
}

const CustomDatePickerWrap = styled(DatePickerWrap)`
  border-radius: 0;

  input {
    border: 1px solid black;
  }
`

const CustomLabel = styled.label`
  min-width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  margin-right: 5px;

  input {
    width: 15px;
    margin-right: 5px;
  }
`

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const ErrorText = styled.span`
  font-size: 10px;
  font-weight: 650;
  line-height: 13px;
  color: red;
  margin-bottom: -5px;
  display: block;
  // margin-top: -10px;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 20px 0;
  // border: 1px solid gray;
`

const SportsMarketShowCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-right: 2px solid #888;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsMarketNameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 오른쪽 끝으로 정렬 */

  width: 100%;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;

  background-color: #888;
  border: 1px solid black;
`

const SportsMarketOddWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.name && `repeat(${props.name}, minmax(0, 1fr))`};
  &:hover {
    cursor: pointer;
    border: 2px solid red;
  }
`

const SportsMarketOddItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  font-size: 14px;
`

const SportsMarketExchangeItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  color: blue;
  font-weight: 500;
  font-size: 14px;
`

const SportsMarketOddItemTitle = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  background: #a6a6a6;
  color: white;
  font-size: 14px;
`

const StopBadge = styled(Badge)`
  width: 40px;
  height: 16px;
  background-color: #ff4861;
  font-weight: 500;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
  span {
    width: 100%;
  }
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
    margin-top: 50px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion

const CustomSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
