import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import styled from 'styled-components'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {
  MiniGameBettingResultEnumsSelectOptions,
  MiniGameTypeEnumsSelectOptions,
  MiniGameVendor,
} from '../../../../../enums/Log/miniGameBettingEnums'
import { searchMiniGameBetting, useSearchMiniGameBetting } from '../../../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../../../utils/dateTime'
import MiniGameBettingLogListData from '../../../../BettingManagement/MiniGameBettingLog/MiniGameBettingLogListData'

const MemberHistoryBettingMini = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  /**
     배팅로그 조회
     */
  const [bettingLogparams, setBettingLogParams] = useState({
    memberId,
    page,
    size,
    isExact: true,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [miniGameVendor, setMiniGameVendor] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setMiniGameVendor(type)

    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      miniGameVendor: type || null,
    }))
  }

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMiniGameBetting = async () => {
    if (loading) return
    setLoading(true)
    await searchMiniGameBetting(bettingLogparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMiniGameBetting()
  }, [bettingLogparams])

  const [bettingLogListData, setBettingLogListData] = useState(MiniGameBettingLogListData(content))
  useEffect(() => {
    setBettingLogListData(MiniGameBettingLogListData(content))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }
  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = MiniGameBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = MiniGameTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId,
      gameResult: !searchGameResult ? null : searchGameResult,
      miniGameType: !searchGameType ? null : searchGameType,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  return (
    <>
      <SearchCardTitleWrap style={{ background: 'none' }}>
        <MiniGameTypeSelectWrapper>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            active={!miniGameVendor}
            onClick={() => {
              onClickMiniGameTypeHandler(null)
            }}
          >
            전체
          </Button>
          {Object.entries(MiniGameVendor).map(([key, value]) => {
            return (
              <Button
                type="button"
                size="sm"
                variant="outline-secondary"
                active={key === miniGameVendor}
                onClick={() => {
                  onClickMiniGameTypeHandler(key)
                }}
              >
                {value}
              </Button>
            )
          })}
        </MiniGameTypeSelectWrapper>
      </SearchCardTitleWrap>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>게임결과</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchGameResult"
              placeholder="게임결과"
              onChange={option => {
                onSelectGameResultOptionHandler(option)
              }}
              value={searchGameResultOptionValue}
              options={searchGameResultOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>게임종류</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchGameType"
              placeholder="종류"
              onChange={option => {
                onSelectGameTypeOptionHandler(option)
              }}
              value={searchGameTypeOptionValue}
              options={searchGameTypeOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    </>
  )
}

export default MemberHistoryBettingMini

MemberHistoryBettingMini.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`
