import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

/**
 * 서버 to 프론트 convert
 *
 */
export const convertFromServer = content => {
  if (content) {
    let safeContent = content.trim()

    // ✅ 첫 번째 태그가 <img>라면 앞에 빈 <p> 추가
    if (/^<img/.test(safeContent)) {
      safeContent = `<p></p>${safeContent}`
    }

    const blocksFromHTML = htmlToDraft(safeContent)
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
    )
  }

  return EditorState.createEmpty()
}

/**
 * 프론트 to 서버 convert
 *
 */
export const convertFromFront = content => {
  return draftToHtml(convertToRaw(content.getCurrentContent()))
}

/**
 * ... 처리
 */
const MAX_LENGTH = 10
export const convertLongData = value => {
  if (value.length <= 10) {
    return value
  }
  return `${value.substring(0, MAX_LENGTH)}...`
}
