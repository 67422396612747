import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import {
  BoardStatusEnumsRadioOptions,
  BoardTargetEnumsRadioOptions,
  BoardTypeEnumsRaidoOptions,
} from '../../../enums/Operation/boardEnums'
import { createBoardList } from '../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../utils/dateTime'
import { convertFromFront } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import './Board.css'
import Loading from '../../../shared/components/Loading'

function BoardWriteForm({ fetchSearchBoardList, onClickHandler }) {
  const { edgeNameRadio } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
    }
  })

  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [content, setContent] = useState(EditorState.createEmpty())

  const contentChange = editorState => {
    setContent(editorState)
  }

  // 등록일 시작
  const [searchRegistrationDate, setSearchRegistrationDate] = useState(null)
  const onSearchRegistrationDateChangeHandler = date => {
    setSearchRegistrationDate(date)
  }
  const [type, setType] = useState('NOTICE')
  const onSubmit = e => {
    if (writeLoading) return
    if (!e.title || !searchRegistrationDate || !content.getCurrentContent().hasText()) {
      alert('필수사항을 모두 입력 후 등록해주세요.')
      return
    }

    const body = {
      edgeName: e.edgeName,
      boardTitle: e.title.trim(),
      type: e.boardType,
      status: e.boardStatus,
      imgUrl: e.imgUrl,
      boardContent: convertFromFront(content),
      registrationDate: convertToKst(searchRegistrationDate),
    }
    setWriteLoading(true)
    createBoardList(body)
      .then(res => {
        setWriteLoading(false)
        alert('게시판 등록이 완료됐습니다.')
        onClickHandler()
        fetchSearchBoardList()
        return res
      })
      .catch(error => {
        setWriteLoading(false)
        alert('게시판 등록이 실패했습니다, 잠시 후 다시 시도해주세요.')
        return error
      })
  }
  return (
    <>
      {writeLoading ? (
        <Loading />
      ) : (
        <>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>사이트명</FormGroupLabel>
                  {edgeNameRadio.map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>제목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="title" component="input" type="text" placeholder="제목을 입력하세요" />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>구분</FormGroupLabel>
                  {BoardTypeEnumsRaidoOptions().map(item => {
                    return (
                      <>
                        <FormGroupField
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name={`${item.name}`}
                            onCustomChange={e => {
                              setType(item.radioValue)
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </FormGroupField>
                      </>
                    )
                  })}
                </FormGroup>
                {/* <FormGroup>
                  <FormGroupLabel>권한</FormGroupLabel>
                  {BoardTargetEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup> */}
                <FormGroup>
                  <FormGroupLabel>노출</FormGroupLabel>
                  {BoardStatusEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>등록일</FormGroupLabel>
                  <FormGroupField>
                    <SearchDatePickerWrap>
                      <DatePicker
                        selected={searchRegistrationDate}
                        onChange={date => onSearchRegistrationDateChangeHandler(date)}
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeFormat="HH:mm"
                        showTimeInput
                        dropDownMode="select"
                        popperPlacement="bottom-start"
                        placeholderText="클릭하세요"
                        isClearable
                        locale={ko}
                      />
                    </SearchDatePickerWrap>
                  </FormGroupField>
                </FormGroup>
                {type === 'EVENT' && (
                  <>
                    <FormGroup>
                      <FormGroupLabel>썸네일 이미지URL</FormGroupLabel>
                      <FormGroupField style={{ flexDirection: 'column', alignItems: 'start' }}>
                        <Field name="imgUrl" component="input" type="text" placeholder="URL을 입력하세요" />
                        <div style={{ color: 'red' }}>이미지 크기는 348px * 192px 로 올리셔야합니다.</div>
                      </FormGroupField>
                    </FormGroup>
                  </>
                )}
                {type === 'EVENT' && (
                  <>
                    <div style={{ color: 'red' }}>
                      이벤트 내용에 이미지를 올리시려면 아래 내용 부분에서 올리셔야 합니다.
                    </div>
                  </>
                )}
                <FormGroup>
                  <FormGroupLabel>내용</FormGroupLabel>
                  <Editor
                    editorState={content}
                    onEditorStateChange={editorState => contentChange(editorState)}
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    toolbarClassName="toolbar"
                  />
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          글쓰기
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default BoardWriteForm

BoardWriteForm.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  fetchSearchBoardList: PropTypes.func.isRequired,
}
