export const MoneySortEnums = {
  NORMAL_MONEY: '일반',
  HOLDEM_MONEY: '홀덤',
}

export const MemberDepositStatusEnums = {
  APPLY: '신청',
  APPROVE: '승인',
  WAITING: '대기',
  CANCEL: '취소',
}

export const MemberDepositStatusEnumsSelectOptions = () => {
  return Object.entries(MemberDepositStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberBounsTypeEnums = {
  BONUS_SPORTS: '[스포츠 이용] : 포인트를 받겠습니다.',
  BONUS_SPORTS_ETC: '[스포츠 이용] : 단폴,두폴,실시간 이용시 포인트를 받지 않겠습니다.',
  BONUS_CASINO: '[카지노,슬롯 이용] : 포인트를 받지 않겠습니다.',
}

/**
 * 충전 보너스에서 사용
 */
export const DepositBounsTypeEnums = {
  BONUS_NONE: '보너스 없음',
  BONUS_SPORTS: '스포츠(첫충/매충)',
  BONUS_SPORTS_NEW: '스포츠 신규',
  BONUS_CASINO: '카지노(첫충/매충)',
  BONUS_CASINO_NEW: '카지노 신규',
  BONUS_SLOT_CASINO: '슬롯(첫충/매충)',
  BONUS_SLOT_CASINO_NEW: '슬롯 신규',
  BONUS_MINI_GAME: '미니게임(첫충/매충)',
  BONUS_MINI_GAME_NEW: '미니게임 신규',
  BONUS_VIRTUAL_GAME: '가상게임(첫충/매충)',
  BONUS_VIRTUAL_GAME_NEW: '가상게임 신규',
  BONUS_HOLDEM: '홀덤(첫충/매충)',
  BONUS_HOLDEM_NEW: '홀덤 신규',
  BONUS_PLUS: '전종목 이벤트머니',
}

export const DepositBounsTypeEnumsSelectOptions = () => {
  return Object.entries(DepositBounsTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberDepositTypeEnums = {
  FIRST: '첫충',
  EVERY: '매충',
  SURPRISE: '돌발',
  NONE: '-',
  WEEK: '-',
}

export const MemberDepositTypeEnums2 = {
  FIRST: '첫충',
  EVERY: '매충',
  SURPRISE: '돌발',
  WEEK: '-',
}

export const BonusStatusEnums = {
  0: '기본',
  1: '0%',
  5: '5%',
  10: '10%',
  15: '15%',
  20: '20%',
  25: '25%',
  30: '30%',
  35: '35%',
  40: '40%',
}

export const BonusStatusEnumsRadioOptions = () => {
  return Object.entries(BonusStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bonusPercent',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bonusPercent',
          label: value,
          radioValue: key,
        },
  )
}

export const MemberDepositTypeEnumsRadioOptions = () => {
  return Object.entries(MemberDepositTypeEnums2).map(([key, value], i) =>
    i === 0
      ? {
          name: 'depositType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'depositType',
          label: value,
          radioValue: key,
        },
  )
}

export const RollingDepositType = {
  FIRST: 'FIRST',
  EVERY: 'EVERY',
  NEW: 'NEW',
  NONE: 'NONE',
}

export const RollingDepositTypeKr = {
  FIRST: '첫충',
  EVERY: '매충',
  NEW: '신규',
  NONE: '보너스 없음',
}

export const DepositVisibleTimeType = {
  ONCE: 'ONCE',
  INFINITY: 'INFINITY',
  BEFORE_WITHDRAWAL: 'BEFORE_WITHDRAWAL',
}

export const DepositVisibleTimeTypeKr = {
  ONCE: '한번만',
  INFINITY: '무제한',
  BEFORE_WITHDRAWAL: '환전 전까지',
}

export const BonusShowType = {
  DEPOSIT_COUNT: 'DEPOSIT_COUNT',

  TODAY_NOT_DEPOSIT: 'TODAY_NOT_DEPOSIT',
  TODAY_EXIST_DEPOSIT: 'TODAY_EXIST_DEPOSIT',
  TODAY_NOT_WITHDRAW: 'TODAY_NOT_WITHDRAW',
  TODAY_EXIST_WITHDRAW: 'TODAY_EXIST_WITHDRAW',
  TODAY_EQUAL_TYPE_NOT_DEPOSIT: 'TODAY_EQUAL_TYPE_NOT_DEPOSIT',
  TODAY_EQUAL_TYPE_EXIST_DEPOSIT: 'TODAY_EQUAL_TYPE_EXIST_DEPOSIT',
  TODAY_EQUAL_TYPE_NOT_WITHDRAW: 'TODAY_EQUAL_TYPE_NOT_WITHDRAW',
  TODAY_EQUAL_TYPE_EXIST_WITHDRAW: 'TODAY_EQUAL_TYPE_EXIST_WITHDRAW',
  TODAY_DIFF_TYPE_NOT_DEPOSIT: 'TODAY_DIFF_TYPE_NOT_DEPOSIT',
  TODAY_DIFF_TYPE_EXIST_DEPOSIT: 'TODAY_DIFF_TYPE_EXIST_DEPOSIT',
  TODAY_DIFF_TYPE_NOT_WITHDRAW: 'TODAY_DIFF_TYPE_NOT_WITHDRAW',
  TODAY_DIFF_TYPE_EXIST_WITHDRAW: 'TODAY_DIFF_TYPE_EXIST_WITHDRAW',
  FULL_NOT_DEPOSIT: 'FULL_NOT_DEPOSIT',
  FULL_EXIST_DEPOSIT: 'FULL_EXIST_DEPOSIT',
  FULL_NOT_WITHDRAW: 'FULL_NOT_WITHDRAW',
  FULL_EXIST_WITHDRAW: 'FULL_EXIST_WITHDRAW',
  FULL_EQUAL_TYPE_NOT_DEPOSIT: 'FULL_EQUAL_TYPE_NOT_DEPOSIT',
  FULL_EQUAL_TYPE_EXIST_DEPOSIT: 'FULL_EQUAL_TYPE_EXIST_DEPOSIT',
  FULL_EQUAL_TYPE_NOT_WITHDRAW: 'FULL_EQUAL_TYPE_NOT_WITHDRAW',
  FULL_EQUAL_TYPE_EXIST_WITHDRAW: 'FULL_EQUAL_TYPE_EXIST_WITHDRAW',
  FULL_DIFF_TYPE_NOT_DEPOSIT: 'FULL_DIFF_TYPE_NOT_DEPOSIT',
  FULL_DIFF_TYPE_EXIST_DEPOSIT: 'FULL_DIFF_TYPE_EXIST_DEPOSIT',
  FULL_DIFF_TYPE_NOT_WITHDRAW: 'FULL_DIFF_TYPE_NOT_WITHDRAW',
  FULL_DIFF_TYPE_EXIST_WITHDRAW: 'FULL_DIFF_TYPE_EXIST_WITHDRAW',

  WEEK_NOT_DEPOSIT: 'WEEK_NOT_DEPOSIT',
  WEEK_EXIST_DEPOSIT: 'WEEK_EXIST_DEPOSIT',
  WEEK_NOT_WITHDRAW: 'WEEK_NOT_WITHDRAW',
  WEEK_EXIST_WITHDRAW: 'WEEK_EXIST_WITHDRAW',
  WEEK_EQUAL_TYPE_NOT_DEPOSIT: 'WEEK_EQUAL_TYPE_NOT_DEPOSIT',
  WEEK_EQUAL_TYPE_EXIST_DEPOSIT: 'WEEK_EQUAL_TYPE_EXIST_DEPOSIT',
  WEEK_EQUAL_TYPE_NOT_WITHDRAW: 'WEEK_EQUAL_TYPE_NOT_WITHDRAW',
  WEEK_EQUAL_TYPE_EXIST_WITHDRAW: 'WEEK_EQUAL_TYPE_EXIST_WITHDRAW',
  WEEK_DIFF_TYPE_NOT_DEPOSIT: 'WEEK_DIFF_TYPE_NOT_DEPOSIT',
  WEEK_DIFF_TYPE_EXIST_DEPOSIT: 'WEEK_DIFF_TYPE_EXIST_DEPOSIT',
  WEEK_DIFF_TYPE_NOT_WITHDRAW: 'WEEK_DIFF_TYPE_NOT_WITHDRAW',
  WEEK_DIFF_TYPE_EXIST_WITHDRAW: 'WEEK_DIFF_TYPE_EXIST_WITHDRAW',
}

export const BonusShowTypeKr = {
  DEPOSIT_COUNT: '충전 회차별',

  TODAY_NOT_DEPOSIT: '당일 전종목 충전이력 X',
  TODAY_EXIST_DEPOSIT: '당일 전종목 충전이력 O',
  TODAY_NOT_WITHDRAW: '당일 전종목 환전이력 X',
  TODAY_EXIST_WITHDRAW: '당일 전종목 환전이력 O',

  TODAY_EQUAL_TYPE_NOT_DEPOSIT: '당일 동일종목 충전이력 X',
  TODAY_EQUAL_TYPE_EXIST_DEPOSIT: '당일 동일종목 충전이력 O',
  TODAY_EQUAL_TYPE_NOT_WITHDRAW: '당일 동일종목 환전이력 X',
  TODAY_EQUAL_TYPE_EXIST_WITHDRAW: '당일 동일종목 환전이력 O',

  TODAY_DIFF_TYPE_NOT_DEPOSIT: '당일 다른종목 충전이력 X',
  TODAY_DIFF_TYPE_EXIST_DEPOSIT: '당일 다른종목 충전이력 O',
  TODAY_DIFF_TYPE_NOT_WITHDRAW: '당일 다른종목 환전이력 X',
  TODAY_DIFF_TYPE_EXIST_WITHDRAW: '당일 다른종목 환전이력 O',

  FULL_NOT_DEPOSIT: '전기간 전종목 충전이력 X',
  FULL_EXIST_DEPOSIT: '전기간 전종목 충전이력 O',
  FULL_NOT_WITHDRAW: '전기간 전종목 환전이력 X',
  FULL_EXIST_WITHDRAW: '전기간 전종목 환전이력 O',

  FULL_EQUAL_TYPE_NOT_DEPOSIT: '전기간 동일종목 충전이력 X',
  FULL_EQUAL_TYPE_EXIST_DEPOSIT: '전기간 동일종목 충전이력 O',
  FULL_EQUAL_TYPE_NOT_WITHDRAW: '전기간 동일종목 환전이력 X',
  FULL_EQUAL_TYPE_EXIST_WITHDRAW: '전기간 동일종목 환전이력 O',

  FULL_DIFF_TYPE_NOT_DEPOSIT: '전기간 다른종목 충전이력 X',
  FULL_DIFF_TYPE_EXIST_DEPOSIT: '전기간 다른종목 충전이력 O',
  FULL_DIFF_TYPE_NOT_WITHDRAW: '전기간 다른종목 환전이력 X',
  FULL_DIFF_TYPE_EXIST_WITHDRAW: '전기간 다른종목 환전이력 O',

  WEEK_NOT_DEPOSIT: '금주 전종목 충전이력 X',
  WEEK_EXIST_DEPOSIT: '금주 전종목 충전이력 O',
  WEEK_NOT_WITHDRAW: '금주 전종목 환전이력 X',
  WEEK_EXIST_WITHDRAW: '금주 전종목 환전이력 O',
  WEEK_EQUAL_TYPE_NOT_DEPOSIT: '금주 동일종목 충전이력 X',
  WEEK_EQUAL_TYPE_EXIST_DEPOSIT: '금주 동일종목 충전이력 O',
  WEEK_EQUAL_TYPE_NOT_WITHDRAW: '금주 동일종목 환전이력 X',
  WEEK_EQUAL_TYPE_EXIST_WITHDRAW: '금주 동일종목 환전이력 O',
  WEEK_DIFF_TYPE_NOT_DEPOSIT: '금주 다른종목 충전이력 X',
  WEEK_DIFF_TYPE_EXIST_DEPOSIT: '금주 다른종목 충전이력 O',
  WEEK_DIFF_TYPE_NOT_WITHDRAW: '금주 다른종목 환전이력 X',
  WEEK_DIFF_TYPE_EXIST_WITHDRAW: '금주 다른종목 환전이력 O',
}

export const BonusShowTodayTypeKr = {
  TODAY_NOT_DEPOSIT: '당일 전종목 충전이력 X',
  TODAY_EXIST_DEPOSIT: '당일 전종목 충전이력 O',
  TODAY_NOT_WITHDRAW: '당일 전종목 환전이력 X',
  TODAY_EXIST_WITHDRAW: '당일 전종목 환전이력 O',

  TODAY_EQUAL_TYPE_NOT_DEPOSIT: '당일 동일종목 충전이력 X',
  TODAY_EQUAL_TYPE_EXIST_DEPOSIT: '당일 동일종목 충전이력 O',
  TODAY_EQUAL_TYPE_NOT_WITHDRAW: '당일 동일종목 환전이력 X',
  TODAY_EQUAL_TYPE_EXIST_WITHDRAW: '당일 동일종목 환전이력 O',

  TODAY_DIFF_TYPE_NOT_DEPOSIT: '당일 다른종목 충전이력 X',
  TODAY_DIFF_TYPE_EXIST_DEPOSIT: '당일 다른종목 충전이력 O',
  TODAY_DIFF_TYPE_NOT_WITHDRAW: '당일 다른종목 환전이력 X',
  TODAY_DIFF_TYPE_EXIST_WITHDRAW: '당일 다른종목 환전이력 O',
}

export const BonusShowFullTypeKr = {
  FULL_NOT_DEPOSIT: '전기간 전종목 충전이력 X',
  FULL_EXIST_DEPOSIT: '전기간 전종목 충전이력 O',
  FULL_NOT_WITHDRAW: '전기간 전종목 환전이력 X',
  FULL_EXIST_WITHDRAW: '전기간 전종목 환전이력 O',

  FULL_EQUAL_TYPE_NOT_DEPOSIT: '전기간 동일종목 충전이력 X',
  FULL_EQUAL_TYPE_EXIST_DEPOSIT: '전기간 동일종목 충전이력 O',
  FULL_EQUAL_TYPE_NOT_WITHDRAW: '전기간 동일종목 환전이력 X',
  FULL_EQUAL_TYPE_EXIST_WITHDRAW: '전기간 동일종목 환전이력 O',

  FULL_DIFF_TYPE_NOT_DEPOSIT: '전기간 다른종목 충전이력 X',
  FULL_DIFF_TYPE_EXIST_DEPOSIT: '전기간 다른종목 충전이력 O',
  FULL_DIFF_TYPE_NOT_WITHDRAW: '전기간 다른종목 환전이력 X',
  FULL_DIFF_TYPE_EXIST_WITHDRAW: '전기간 다른종목 환전이력 O',
}

export const BonusShowWeekTypeKr = {
  WEEK_NOT_DEPOSIT: '금주 전종목 충전이력 X',
  WEEK_EXIST_DEPOSIT: '금주 전종목 충전이력 O',
  WEEK_NOT_WITHDRAW: '금주 전종목 환전이력 X',
  WEEK_EXIST_WITHDRAW: '금주 전종목 환전이력 O',
  WEEK_EQUAL_TYPE_NOT_DEPOSIT: '금주 동일종목 충전이력 X',
  WEEK_EQUAL_TYPE_EXIST_DEPOSIT: '금주 동일종목 충전이력 O',
  WEEK_EQUAL_TYPE_NOT_WITHDRAW: '금주 동일종목 환전이력 X',
  WEEK_EQUAL_TYPE_EXIST_WITHDRAW: '금주 동일종목 환전이력 O',
  WEEK_DIFF_TYPE_NOT_DEPOSIT: '금주 다른종목 충전이력 X',
  WEEK_DIFF_TYPE_EXIST_DEPOSIT: '금주 다른종목 충전이력 O',
  WEEK_DIFF_TYPE_NOT_WITHDRAW: '금주 다른종목 환전이력 X',
  WEEK_DIFF_TYPE_EXIST_WITHDRAW: '금주 다른종목 환전이력 O',
}

export const BonusDepositType = {
  RATIO_PAYMENT: 'RATIO_PAYMENT',
  MONEY_PAYMENT: 'MONEY_PAYMENT',
}

export const BonusDepositTypeKr = {
  RATIO_PAYMENT: '비율 지급',
  MONEY_PAYMENT: '금액 지급',
}

/**
 * V2 버전
 */
export const DepositBounsTypeV2 = {
  BONUS_NONE: 'BONUS_NONE',
  BONUS_SPORTS: 'BONUS_SPORTS',
  BONUS_CASINO: 'BONUS_CASINO',
  BONUS_SLOT_CASINO: 'BONUS_SLOT_CASINO',
  BONUS_MINI_GAME: 'BONUS_MINI_GAME',
  BONUS_VIRTUAL_GAME: 'BONUS_VIRTUAL_GAME',
  BONUS_HOLDEM: 'BONUS_HOLDEM',
}

export const DepositBounsTypeKrV2 = {
  BONUS_NONE: '없음',
  BONUS_SPORTS: '스포츠',
  BONUS_CASINO: '카지노',
  BONUS_SLOT_CASINO: '슬롯',
  BONUS_MINI_GAME: '미니게임',
  BONUS_VIRTUAL_GAME: '가상게임',
  BONUS_HOLDEM: '홀덤',
}
