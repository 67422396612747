import {
  AssetIncDecMethodTypeEnumsSelectOptions,
  MemberMoneyTypeEnumsSelectOptions,
  MoneyIncDecTypeEnumsSelectOptions,
} from '@/enums/MemberManage/MemberMoneyIncDecEnums'
import { MemberIncDecSelect } from '@/shared/components/CustomSelect'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
  MemberMoneyIncDecFormButtonToolbar,
} from '@/shared/components/form/FormElements'
import FormField from '@/shared/components/form/FormField'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Loading from '../../../../../shared/components/Loading'
import Error from '../../../../../shared/components/form/Error'
import {
  searchAssetIncDec,
  updateMemberAssetIncDecInfo,
  useSearchAssetIncDec,
  useSearchMemberAssetInfo,
} from '../../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../../utils/token'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import { UserAssetIncDecValidate } from '../../../../../utils/validate/memberManage/UserAssetIncDecValidate'
import MemberMoneyIncDecListData from '../../MemberMoneyIncDec/MemberMoneyIncDecListData'

const MemberHistoryMoneyIncDec = ({ memberId }) => {
  const [moneyIncDecLoading, setMoneyIncDecLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority

  /**
   * 회원의 머니 증감 이력 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [moneyIncDecParams, setMoneyIncDecParams] = useState({
    userId: memberId,
    isExact: true,
    assetType: null,
    incDecType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchAssetIncDec = async () => {
    if (loading) return
    setLoading(true)
    await searchAssetIncDec(moneyIncDecParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchAssetIncDec()
  }, [moneyIncDecParams])

  const [memberMoneyIncDecListData, setMemberMoneyIncDecListData] = useState(MemberMoneyIncDecListData(content))

  useEffect(() => {
    setMemberMoneyIncDecListData(MemberMoneyIncDecListData(content))
  }, [content])

  const [memberMoneyIncDecRows, setMemberMoneyIncDecRows] = useState(memberMoneyIncDecListData.tableRowsData)

  useEffect(() => {
    setMemberMoneyIncDecRows(memberMoneyIncDecListData.tableRowsData)
  }, [memberMoneyIncDecListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 종류
  const [searchMoneyIncDecType, setSearchMoneyIncDecType] = useState('')

  const [searchMoneyIncDecTypeOptionValue, setSearchMoneyIncDecTypeOptionValue] = useState(null)
  const searchMoneyIncDecTypeOption = MoneyIncDecTypeEnumsSelectOptions()

  const onSelectMoneyIncDecTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyIncDecTypeOptionValue(null)
      setSearchMoneyIncDecType(null)
      return
    }
    setSearchMoneyIncDecTypeOptionValue(option)
    setSearchMoneyIncDecType(option.value)
  }

  // 구분 - 포인트 , 머니 , 콤프
  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = MemberMoneyTypeEnumsSelectOptions()

  const onSelectTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  // 처리일시 시작 + 끝
  const [searchMoneyIncDecStartDate, setSearchMoneyIncDecStartDate] = useState(null)
  const [searchMoneyIncDecEndDate, setSearchMoneyIncDecEndDate] = useState(null)

  const onSearchMoneyIncDecStartDateChangeHandler = date => {
    setSearchMoneyIncDecStartDate(date)
  }
  const onSearchMoneyIncDecEndDateChangeHandler = date => {
    setSearchMoneyIncDecEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      assetType: !searchMoneyType ? null : searchMoneyType,
      incDecType: !searchMoneyIncDecType ? null : searchMoneyIncDecType,
      startDate: !searchMoneyIncDecStartDate ? null : convertToKst(searchMoneyIncDecStartDate),
      endDate: !searchMoneyIncDecEndDate ? null : convertToKst(searchMoneyIncDecEndDate),
    }))
  }

  /**
   * 회원 머니증감 정보 조회
   * partnerId 있으면 매장 보유머니도 같이 조회해야 함
   */
  const [memberMoneyIncDecInfoParams, setMemberMoneyIncDecInfoParams] = useState({
    memberId,
  })

  const [userMoneyAmount, setUserMoneyAmount] = useState('0')
  const [userRollingPointAmount, setUserRollingPointAmount] = useState('0')
  const [userLosingPointAmount, setUserLosingPointAmount] = useState('0')
  const [userHoldemMoneyAmount, setUserHoldemMoneyAmount] = useState('0')

  const { memberMoneyIncDecInfoContent, fetchSearchMemberAssetInfo } =
    useSearchMemberAssetInfo(memberMoneyIncDecInfoParams)

  useEffect(() => {
    setUserMoneyAmount(memberMoneyIncDecInfoContent?.holdingMoney)
    setUserRollingPointAmount(memberMoneyIncDecInfoContent?.holdingRollingPoint)
    setUserLosingPointAmount(memberMoneyIncDecInfoContent?.holdingLosingPoint)
    setUserHoldemMoneyAmount(memberMoneyIncDecInfoContent?.holdingHoldemMoney)
  }, [memberMoneyIncDecInfoContent])

  // 증차감 선택
  const [moneyIncDecType, setMoneyIncDecType] = useState('')

  const moneyIncDecTypeOption = AssetIncDecMethodTypeEnumsSelectOptions()

  // 머니/콤프 선택
  const [assetType, setAssetType] = useState('')

  const assetTypeOption = MemberMoneyTypeEnumsSelectOptions()

  // 증차감 금액
  const [moneyIncDecAmount, setMoneyIncDecAmount] = useState('')

  // 증차감 메모
  const [moneyIncDecMemo, setMoneyIncDecMemo] = useState('')

  // 적용하기
  const onSubmit = e => {
    if (moneyIncDecLoading) return

    const body = {
      memberId,
      assetType: e.assetType.value,
      incDecType: e.moneyIncDecType.value,
      assetIncDecAmount: e.moneyIncDecAmount,
      memo: e.moneyIncDecMemo ?? '',
    }
    setMoneyIncDecLoading(true)
    updateMemberAssetIncDecInfo(body)
      .then(res => {
        setMoneyIncDecLoading(false)
        alert('적용 되었습니다.')
        fetchSearchMemberAssetInfo()
        fetchSearchAssetIncDec()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('적용 실패하였습니다.')
        }
        setMoneyIncDecLoading(false)
      })
  }

  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>자산 증감</h3>
      {moneyIncDecLoading ? (
        <Loading />
      ) : (
        <Form
          onSubmit={onSubmit}
          validate={UserAssetIncDecValidate}
          initialValues={{
            memberId,
            memberMoneyAmount: userMoneyAmount?.toString().replace(commonReg2, ','),
            memberRollingPointAmount: userRollingPointAmount?.toString().replace(commonReg2, ','),
            memberLosingPointAmount: userLosingPointAmount?.toString().replace(commonReg2, ','),
            holdemMoneyAmount: userHoldemMoneyAmount?.toString().replace(commonReg2, ','),
            moneyIncDecAmount,
            moneyIncDecMemo,
            assetType,
            moneyIncDecType,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormGroup>
                <FormGroupLabel>유저 아이디</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input style={{ border: 'solid 1px black' }} {...input} type="text" disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>보유머니</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="memberMoneyAmount"
                    component={FormField}
                    type="text"
                    value={userMoneyAmount}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>홀덤머니</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="holdemMoneyAmount"
                    component={FormField}
                    type="text"
                    value={userHoldemMoneyAmount}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>보유 롤링포인트</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="memberRollingPointAmount"
                    component={FormField}
                    type="text"
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>보유 루징포인트</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="memberLosingPointAmount"
                    component={FormField}
                    type="text"
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>머니/포인트</FormGroupLabel>
                <FormGroupField>
                  <Field name="assetType">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <MemberIncDecSelect
                          {...input}
                          value={input.value}
                          onChange={option => {
                            input.onChange(option)
                          }}
                          options={assetTypeOption}
                          isClearable
                          placeholder="머니/포인트 선택"
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                        {meta.touched && meta.error && <Error error={meta.error} top />}
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>지급/감소</FormGroupLabel>
                <FormGroupField>
                  <Field name="moneyIncDecType">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <MemberIncDecSelect
                          {...input}
                          value={input.value}
                          onChange={option => {
                            input.onChange(option)
                          }}
                          options={moneyIncDecTypeOption}
                          isClearable
                          placeholder="지급방법 선택"
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                        {meta.touched && meta.error && <Error error={meta.error} top />}
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>증감액</FormGroupLabel>
                <FormGroupField>
                  <Field name="moneyIncDecAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap>
                          <input
                            {...input}
                            style={{ border: 'solid 1px black' }}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                  <FormGroupIcon>원</FormGroupIcon>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>지급 메모</FormGroupLabel>
                <FormGroupField>
                  <Field name="moneyIncDecMemo">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap>
                          <input
                            {...input}
                            style={{ border: 'solid 1px black' }}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              {
                {
                  SUPER: (
                    <MemberMoneyIncDecFormButtonToolbar>
                      <Button variant="primary" type="submit">
                        적용하기
                      </Button>
                    </MemberMoneyIncDecFormButtonToolbar>
                  ),
                }[authority]
              }
            </FormContainer>
          )}
        </Form>
      )}

      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>자산 증감 내역</h3>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>종류</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchMoneyType"
              onChange={option => {
                onSelectMoneyIncDecTypeOptionHandler(option)
              }}
              value={searchMoneyIncDecTypeOptionValue}
              options={searchMoneyIncDecTypeOption}
              placeholder="종류"
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>구분</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchMoneyType"
              onChange={option => {
                onSelectTypeOptionHandler(option)
              }}
              value={searchMoneyTypeOptionValue}
              options={searchMoneyTypeOption}
              placeholder="구분"
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchMoneyIncDecStartDate}
                onChange={date => onSearchMoneyIncDecStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchMoneyIncDecEndDate}
                onChange={date => onSearchMoneyIncDecEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase
        key="common"
        columns={memberMoneyIncDecListData.tableHeaderData}
        data={memberMoneyIncDecRows}
        tableConfig={tableConfig}
      />
    </>
  )
}

export default MemberHistoryMoneyIncDec

MemberHistoryMoneyIncDec.propTypes = {
  memberId: PropTypes.string.isRequired,
}
