import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { FriendCompEnumsRaidoOptions } from '../../../enums/SystemManagement/siteEnums'
import Loading from '../../../shared/components/Loading'
import { FormButtonToolbar } from '../../../shared/components/form/FormElements'
import { searchCompEventManage, updateCompEventManage } from '../../../utils/api/systemManage/systemManageApi'
import { colorAdditional, colorFieldsBorder } from '../../../utils/palette'
import { decodeAccessToken } from '../../../utils/token'

const FriendLevelDateForm = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [status, setStatus] = useState('EVERY')

  const fetchSearchCompManage = async () => {
    await searchCompEventManage({}).then(res => {
      setContent(res.data.content)
      setStatus(res.data.content.compEventStatus)
      // setStartDate(new Date(res.data.content.compEventStartAt))
      const now = new Date()
      const tomorrow = now.setDate(now.getDate() + 1)
      setStartDate(res.data.content.compEventStartAt ? new Date(res.data.content.compEventStartAt) : new Date())
      setEndDate(res.data.content.compEventEndAt ? new Date(res.data.content.compEventEndAt) : new Date(tomorrow))
      // setEndDate(new Date(res.data.content.compEventEndAt))
    })
  }

  useEffect(() => {
    fetchSearchCompManage()
  }, [])

  const [eventManageData, setEventManageData] = useState(content)
  useEffect(() => {
    setEventManageData(content)
  }, [content])

  const onSubmit = e => {
    if (writeLoading) return
    if (status === 'SELECTED_DAY' && (startDate === null || endDate === null)) {
      alert('콤프 이벤트를 기간선정으로 할 시, 날짜를 지정해주세요.')
      return
    }

    if (status === 'SELECTED_DAY' && endDate <= startDate) {
      alert('콤프 이벤트를 기간선정으로 할 시, \n 시작일이 종료일보다 클 수 없습니다.')
      return
    }

    if (window.confirm('콤프 이벤트 설정을 변경 하시겠습니까? ')) {
      const body = {
        compEventStatus: status,
        compEventStartAt: startDate,
        compEventEndAt: endDate,
      }
      setWriteLoading(true)
      updateCompEventManage(body)
        .then(res => {
          setWriteLoading(false)
          alert('정산일 설정이 변경됐습니다.')
          fetchSearchCompManage()
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          if (errorCode === 'EVENT-1004') {
            alert(`${message}`)
          } else {
            alert('정산일 설정 변경을 실패하였습니다. 다시 시도해주세요.')
          }
          setWriteLoading(false)
        })
    }
  }
  return (
    <>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '50%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <HeaderBox>
          콤프 정산일
          <span style={{ fontSize: '13px', color: 'red' }}>
            &nbsp; (모든 레벨의 공통사항이며, 기간선정일 경우 종료일이 될 경우 정산이 이루어집니다.)
          </span>
        </HeaderBox>
        <Form
          onSubmit={onSubmit}
          // validate={miniGamelevelValidate}
          initialValues={{
            compEventStatus: status,
            compEventStartAt: startDate,
            compEventEndAt: endDate,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              {status === 'SELECTED_DAY' && (
                <FormGroup>
                  <FormGroupLabel>현재 상태</FormGroupLabel>
                  <FormGroupField>
                    <Field name="isSettlementComp">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={
                                content.isSettlementComp
                                  ? '정산 처리중입니다. ( 현재 수정이 불가능합니다. )'
                                  : '이미 정산이 완료되었거나, 처리 예정인 상태입니다.'
                              }
                            />
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
              )}

              <FormGroup>
                <FormGroupLabel style={{ marginRight: '5px' }}>정산방식 설정</FormGroupLabel>
                <FormGroupField>
                  {FriendCompEnumsRaidoOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        onCustomChange={e => {
                          setStatus(e)
                        }}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroupField>
              </FormGroup>
              {status === 'SELECTED_DAY' && (
                <>
                  <FormGroup>
                    <FormGroupLabel>정산 시작일</FormGroupLabel>
                    <FormGroupField>
                      <Field name="compEventStartAt">
                        {({ input, meta }) => {
                          return (
                            <>
                              <FormInputWrap>
                                <DatePicker
                                  selected={startDate}
                                  onChange={date => setStartDate(date)}
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  timeFormat="HH:mm"
                                  showTimeSelect
                                  dropDownMode="select"
                                  popperPlacement="bottom-start"
                                  placeholderText="시작일을 선택하세요."
                                  isClearable
                                  locale={ko}
                                  disabled={status === 'EVERY'}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            </>
                          )
                        }}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>정산 종료일</FormGroupLabel>
                    <FormGroupField>
                      <Field name="compEventEndAt">
                        {({ input, meta }) => {
                          return (
                            <>
                              <FormInputWrap>
                                <DatePicker
                                  selected={endDate}
                                  onChange={date => setEndDate(date)}
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  timeFormat="HH:mm"
                                  showTimeSelect
                                  dropDownMode="select"
                                  popperPlacement="bottom-start"
                                  placeholderText="시작일을 선택하세요."
                                  isClearable
                                  locale={ko}
                                  disabled={status === 'EVERY'}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            </>
                          )
                        }}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                </>
              )}

              {(authority === 'SUPER' || authority === 'DB_DOWN') && (
                <FormButtonToolbar className="registrationFooter">
                  <Button variant="primary" type="submit">
                    변경하기
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </div>
    </>
  )
}

export default FriendLevelDateForm

FriendLevelDateForm.propTypes = {}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 20%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: lightgray;

  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const CustomFormGroupField2 = styled.div`
  width: 30% !important;
  display: flex;
  align-items: center;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`
