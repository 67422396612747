import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import styled from 'styled-components'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { GameStatusEnumsSelectOptions, GameTypeEnumsSelectOptions } from '../../../enums/SystemManagement/gameEnums'
import { useSearchVendors, updateAllVendor } from '../../../utils/api/systemManage/systemManageApi'
import SystemManagementTabs from '../SystemManagementTabs'
import GameListData from './GameListData'
import GameModifyModal from './GameModifyModal'
import CasinoManagementTabs from '../../CasinoManagement/CasinoManagementTabs'
import { CasinoVendorEnumsEnumsSelectOptions } from '../../../enums/SystemManagement/casinoEnums'
import Loading from '../../../shared/components/Loading'

const GameList = () => {
  // 게임사 처리 모달
  const [isGameModifyModalShow, setGameModifyModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  /**
   게임사관리 조회
   */
  const [gameListparams, setGameListParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setGameListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchVendors } = useSearchVendors(gameListparams)

  const [gameListData, setGameListData] = useState(GameListData(content))
  useEffect(() => {
    setGameListData(GameListData(content))
  }, [content])

  const [rows, setData] = useState(gameListData.tableRowsData)
  useEffect(() => {
    setData(gameListData.tableRowsData)
  }, [gameListData])

  const [vendorInfo, setVendorInfo] = useState({})
  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setVendorInfo(rowValue.original)
    setGameModifyModalShow(true)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const [searchAgencyName, setSearchAgencyName] = useState('')
  const [searchAgencyNameOptionValue, setSearchAgencyNameOptionValue] = useState(null)
  const searchAgencyNameOption = CasinoVendorEnumsEnumsSelectOptions()

  const onSelectAgencyNameOptionHandler = option => {
    if (option == null) {
      setSearchAgencyNameOptionValue(null)
      setSearchAgencyName('')
      return
    }
    setSearchAgencyNameOptionValue(option)
    setSearchAgencyName(option.value)
  }

  // 게임사
  const [searchVendorName, setSearchVendorName] = useState('')

  const onSearchVendorNameHandler = e => {
    setSearchVendorName(e.target.value)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = GameStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    if (option == null) {
      setSearchStatusOptionValue(null)
      setSearchStatus('')
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 타입
  const [searchVendorType, setSearchVendorType] = useState('')
  const [searchVendorTypeOptionValue, setSearchVendorTypeOptionValue] = useState(null)
  const searchVendorTypeOption = GameTypeEnumsSelectOptions()

  const onSelectVendorTypeOptionHandler = option => {
    if (option == null) {
      setSearchVendorTypeOptionValue(null)
      setSearchVendorType('')
      return
    }
    setSearchVendorTypeOptionValue(option)
    setSearchVendorType(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setGameListParams(prev => ({
      ...prev,
      page: 0,
      agencyName: !searchAgencyName ? null : searchAgencyName.trim(),
      vendorName: !searchVendorName ? null : searchVendorName.trim(),
      status: !searchStatus ? null : searchStatus.trim(),
      vendorType: !searchVendorType ? null : searchVendorType,
    }))
  }
  // tab 키
  const [activeKey, setActiveKey] = useState('2')
  const [writeLoading, setWriteLoading] = useState(false)
  const onChangeVendorStatus = (info, agencyName, vendorStatus, vendorType) => {
    if (writeLoading) return
    if (window.confirm(`${info}하시겠습니까?`)) {
      const body = {
        agencyName,
        vendorStatus,
        vendorType,
      }

      setWriteLoading(true)
      updateAllVendor(body)
        .then(res => {
          setWriteLoading(false)
          fetchSearchVendors()
          alert('설정이 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          fetchSearchVendors()
          alert('설정 변경이 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <CasinoManagementTabs activeKey={activeKey} />
        {writeLoading && <Loading />}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 게임사를 수정할 수 있습니다.
            </p>
            <p>
              * <span style={{ color: 'red' }}>순서</span>는 번호가 낮을수록 먼저 보이게 됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>

        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>업체</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchAgencyName"
                onChange={option => {
                  onSelectAgencyNameOptionHandler(option)
                }}
                value={searchAgencyNameOptionValue}
                placeholder="업체"
                options={searchAgencyNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임사명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  type="text"
                  placeholder="입력해주세요"
                  value={searchVendorName}
                  onChange={e => onSearchVendorNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorName"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                placeholder="상태"
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>타입</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorType"
                onChange={option => {
                  onSelectVendorTypeOptionHandler(option)
                }}
                value={searchVendorTypeOptionValue}
                placeholder="타입"
                options={searchVendorTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SearchStatusWrap>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 LIVE 전체숨김"
            agencyName="HONOR_LINK"
            vendorType="LIVE"
            vendorStatus="HIDE"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 LIVE 전체숨김
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 LIVE 전체노출"
            agencyName="HONOR_LINK"
            vendorType="LIVE"
            vendorStatus="SHOW"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 LIVE 전체노출
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 LIVE 전체점검"
            agencyName="HONOR_LINK"
            vendorType="LIVE"
            vendorStatus="INSPECTION"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 LIVE 전체점검
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 LIVE 전체정상"
            agencyName="HONOR_LINK"
            vendorType="LIVE"
            vendorStatus="NORMAL"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 LIVE 전체정상
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 SLOT 전체숨김"
            agencyName="HONOR_LINK"
            vendorType="SLOT"
            vendorStatus="HIDE"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 SLOT 전체숨김
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 SLOT 전체노출"
            agencyName="HONOR_LINK"
            vendorType="SLOT"
            vendorStatus="SHOW"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 SLOT 전체노출
          </Button>

          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 SLOT 전체점검"
            agencyName="HONOR_LINK"
            vendorType="SLOT"
            vendorStatus="INSPECTION"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 SLOT 전체점검
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="아너링크 SLOT 전체정상"
            agencyName="HONOR_LINK"
            vendorType="SLOT"
            vendorStatus="NORMAL"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            아너링크 SLOT 전체정상
          </Button>

          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 LIVE 전체숨김"
            agencyName="SLOT_CITY"
            vendorType="LIVE"
            vendorStatus="HIDE"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 LIVE 전체숨김
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 LIVE 전체노출"
            agencyName="SLOT_CITY"
            vendorType="LIVE"
            vendorStatus="SHOW"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 LIVE 전체노출
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 LIVE 전체점검"
            agencyName="SLOT_CITY"
            vendorType="LIVE"
            vendorStatus="INSPECTION"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 LIVE 전체점검
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 LIVE 전체정상"
            agencyName="SLOT_CITY"
            vendorType="LIVE"
            vendorStatus="NORMAL"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 LIVE 전체정상
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 SLOT 전체숨김"
            agencyName="SLOT_CITY"
            vendorType="SLOT"
            vendorStatus="HIDE"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 SLOT 전체숨김
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 SLOT 전체노출"
            agencyName="SLOT_CITY"
            vendorType="SLOT"
            vendorStatus="SHOW"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 SLOT 전체노출
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 SLOT 전체점검"
            agencyName="SLOT_CITY"
            vendorType="SLOT"
            vendorStatus="INSPECTION"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 SLOT 전체점검
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="슬롯시티 SLOT 전체정상"
            agencyName="SLOT_CITY"
            vendorType="SLOT"
            vendorStatus="NORMAL"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            슬롯시티 SLOT 전체정상
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="골드링크 SLOT 전체숨김"
            agencyName="GOLD_LINK_SLOT"
            vendorType="SLOT"
            vendorStatus="HIDE"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            골드링크 SLOT 전체숨김
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="골드링크 SLOT 전체노출"
            agencyName="GOLD_LINK_SLOT"
            vendorType="SLOT"
            vendorStatus="SHOW"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            골드링크 SLOT 전체노출
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="골드링크 SLOT 전체점검"
            agencyName="GOLD_LINK_SLOT"
            vendorType="SLOT"
            vendorStatus="INSPECTION"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            골드링크 SLOT 전체점검
          </Button>
          <Button
            size="sm"
            active
            variant="outline-secondary"
            info="골드링크 SLOT 전체정상"
            agencyName="GOLD_LINK_SLOT"
            vendorType="SLOT"
            vendorStatus="NORMAL"
            onClick={e => {
              onChangeVendorStatus(
                e.target.getAttribute('info'),
                e.target.getAttribute('agencyName'),
                e.target.getAttribute('vendorStatus'),
                e.target.getAttribute('vendorType'),
              )
            }}
          >
            골드링크 SLOT 전체정상
          </Button>
        </SearchStatusWrap>
        <ReactTableBase columns={gameListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>

      {/* 게임사 수정 모달 */}
      {isGameModifyModalShow && (
        <GameModifyModal
          vendorKey={vendorInfo?.vendorKey}
          vendorNameKo={vendorInfo?.vendorNameKo}
          agencyName={vendorInfo?.agencyName}
          vendorName={vendorInfo?.vendorName}
          status={vendorInfo?.status}
          vendorImg={vendorInfo?.vendorImg}
          vendorOrderNum={vendorInfo?.vendorOrderNum?.toString()}
          memo={vendorInfo?.memo}
          vendorIsInspection={vendorInfo?.vendorIsInspection?.toString()}
          fetchSearchVendors={fetchSearchVendors}
          isGameModifyModalShow={isGameModifyModalShow}
          setGameModifyModalShow={setGameModifyModalShow}
        />
      )}
    </Card>
  )
}

export default GameList

const SearchStatusWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  margin-top: -10px;

  button {
    min-width: 170px;
    height: 40px;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;
  }
`
