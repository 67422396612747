import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import Loading from '../../../shared/components/Loading'
import LosingSearchButton from '../../../shared/components/LosingSearchButton'
import {
  downloadLosingPointDepositList,
  searchPartnerLosingPointDeposit,
} from '../../../utils/api/memberHistory/memberHistoryApi'
import { convertToKst } from '../../../utils/dateTime'
import StatisticsTabs from '../StatisticsTabs'
import PartnerLosingPointDepositListData from './PartnerLosingPointDepositListData'

const PartnerLosingPointDepositList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  /**
    포인트 입금내역 조회
   */
  const endDate = new Date()
  const startDate = new Date()
  // endDate 설정
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)

  // startDate 설정
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [pointDepositType, setPointDepositType] = useState(null)
  const [params, setParams] = useState({
    memberId: null,
    pointDepositType: null,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
    edgeName: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      pointDepositType: !pointDepositType ? null : pointDepositType,
    }))
  }, [pointDepositType])

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointDeposit = async () => {
    if (loading) return
    setLoading(true)
    await searchPartnerLosingPointDeposit(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointDeposit()
  }, [params])

  const [pointDepositListData, setPointDepositListData] = useState(PartnerLosingPointDepositListData(content))

  useEffect(() => {
    setPointDepositListData(PartnerLosingPointDepositListData(content))
  }, [content])

  const [rows, setData] = useState(pointDepositListData.tableRowsData)
  useEffect(() => {
    setData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(startDate)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(endDate)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    authority: '권한',
    losingSettlementType: '루징적립 방식',
    memberId: '유저ID',
    memberName: '유저명',
    executeUserId: '루징발생 유저ID',
    losingPer: '계산 루징%',
    rollingBettingType: '루징 타입',
    receiverType: '구분',
    beforePoint: '변경전 P',
    changePoint: '롤링 입금액',
    afterPoint: '변경후 P',
    executeDate: '처리일',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '루징적립 DB를 csv파일로 다운 받으시겠습니까? \n 유저ID 및 날짜를 입력하지 않고 다운받을 경우 느릴 수 있습니다.',
      )
    ) {
      setDownloadLoading(true)
      downloadLosingPointDepositList({
        memberId: !searchMemberId ? null : searchMemberId,
        pointDepositType: !pointDepositType ? null : pointDepositType,
        startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
        endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '루징적립 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const [activeKey, setActiveKey] = useState('7')
  return (
    <Card>
      <CardBody>
        {loading && <Loading />}
        <StatisticsTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '900' }}>파트너의 루징정산</span> 내역을
              확인하실 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <LosingSearchButton
          pointDepositType={pointDepositType}
          onClickHandler={e => {
            setPointDepositType(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              루징적립 DB 다운
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        {downloadLoading && <Loading />}
        <ReactTableBase columns={pointDepositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default PartnerLosingPointDepositList
