import { Button } from '@/shared/components/Button'
import Switch from 'react-switch' // Import the Switch component
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { FormButtonToolbar } from '../../../shared/components/form/FormElements'
import Loading from '../../../shared/components/Loading'
import { searchSportsGameLevel, updateSportsGameLevel } from '../../../utils/api/systemManage/levelManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { sportsGamelevelValidate } from '../../../utils/validate/systemManagement/levelManage'
import { colorAdditional, colorFieldsBorder } from '../../../utils/palette'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const SpecialSportsGameLevel = ({ specialSportsGameLevel, fetchSportsGameLevel, selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const [gameLevel, setGameLevel] = useState(specialSportsGameLevel)
  const handleChange = (name, value) => {
    setGameLevel(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (!specialSportsGameLevel) return
    setGameLevel(specialSportsGameLevel)
  }, [specialSportsGameLevel])

  const onSubmit = e => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (window.confirm(`스페셜 스포츠게임 ${selectedLevel}레벨 설정을 변경 하시겠습니까?`)) {
      const body = {
        allChange: false,
        updateSportsGameLevel: gameLevel,
      }

      setWriteLoading(true)

      updateSportsGameLevel(body)
        .then(res => {
          setWriteLoading(false)
          alert('스포츠게임 레벨 설정이 변경됐습니다.')
          fetchSportsGameLevel()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('스포츠게임 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  const onSubmit2 = e => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (window.confirm(`스페셜 스포츠게임 모든 레벨 설정을 일괄 변경 하시겠습니까?`)) {
      const body = {
        allChange: true,
        updateSportsGameLevel: gameLevel,
      }

      setWriteLoading(true)

      updateSportsGameLevel(body)
        .then(res => {
          setWriteLoading(false)
          alert('스포츠게임 레벨 설정이 변경됐습니다.')
          fetchSportsGameLevel()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('스포츠게임 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }
  // console.log('gameLevvel', gameLevel)
  return (
    <div style={{ display: 'flex' }}>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '100%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <HeaderBox>스페셜</HeaderBox>
        <Form
          onSubmit={onSubmit}
          validate={sportsGamelevelValidate}
          // initialValues={{
          //   sportsGameLevelName,
          //   sportsGameLossPer,
          //   sportsGameCompPer,
          // }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>본인 낙첨비율 (%)</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsGameLossPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsGameLossPer}
                            onChange={e => handleChange('sportsGameLossPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>낙첨 포인트 적용 최소배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsLossPointMinBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsLossPointMinBettingPer}
                            onChange={e => handleChange('sportsLossPointMinBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>본인 콤프비율 (%)</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsGameCompPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsGameCompPer}
                            onChange={e => handleChange('sportsGameCompPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>콤프 포인트 적용 최소배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsCompPointMinBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsCompPointMinBettingPer}
                            onChange={e => handleChange('sportsCompPointMinBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>배팅 딜레이 시간 설정(초)</FormGroupLabel>
                <FormGroupField>
                  <Field name="bettingDelayTime">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bettingDelayTime}
                            onChange={e => handleChange('bettingDelayTime', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>배팅 대기시간 설정(초)</FormGroupLabel>
                <FormGroupField>
                  <Field name="liveBetWaitingTime">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.liveBetWaitingTime}
                            onChange={e => handleChange('liveBetWaitingTime', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>배팅 마감시간 설정(초)</FormGroupLabel>
                <FormGroupField>
                  <Field name="bettingOkTimeCriteria">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bettingOkTimeCriteria}
                            onChange={e => handleChange('bettingOkTimeCriteria', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  배팅 취소시간 설정(초) <br />
                  (베팅 후)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="bettingCancelTimeCriteriaAfterBetting">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bettingCancelTimeCriteriaAfterBetting}
                            onChange={e => handleChange('bettingCancelTimeCriteriaAfterBetting', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  배팅 취소시간 설정(초) <br />
                  (경기시작전)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="bettingCancelTimeCriteriaBeforeStart">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bettingCancelTimeCriteriaBeforeStart}
                            onChange={e => handleChange('bettingCancelTimeCriteriaBeforeStart', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>배팅 취소 가능횟수</FormGroupLabel>
                <FormGroupField>
                  <Field name="bettingCancelCountCriteria">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bettingCancelCountCriteria}
                            onChange={e => handleChange('bettingCancelCountCriteria', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>베팅 최소 폴더수</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsMinFolder">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsMinFolder}
                            onChange={e => handleChange('sportsMinFolder', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>베팅 최대 폴더수</FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsMaxFolder">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.sportsMaxFolder}
                            onChange={e => handleChange('sportsMaxFolder', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              {/* <FormGroup>
                <FormGroupLabel>단폴더 허용여부</FormGroupLabel>
                <FormGroupField>
                  <Field name="allowedSingleSportsBetting">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap
                          style={{
                            flexDirection: 'column',
                            alignItems: 'start',
                            position: 'relative',
                            border: 'solid 1px #000',
                          }}
                        >
                          <CustomSwitch
                            onChange={checked => {
                              handleChange('allowedSingleSportsBetting', checked) // Assuming handleChange updates the state appropriately
                            }}
                            checked={gameLevel?.allowedSingleSportsBetting}
                          />
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup> */}

              <FormGroup>
                <FormGroupLabel>단폴더 최소배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="singleFolderSportsMinBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.singleFolderSportsMinBettingPer}
                            onChange={e => handleChange('singleFolderSportsMinBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>단폴더 최대배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="singleFolderSportsMaxBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.singleFolderSportsMaxBettingPer}
                            onChange={e => handleChange('singleFolderSportsMaxBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>단폴더 최소 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="singleFolderSportsMinBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.singleFolderSportsMinBettingAmount}
                            onChange={e => handleChange('singleFolderSportsMinBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>단폴더 최대 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="singleFolderSportsMaxBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.singleFolderSportsMaxBettingAmount}
                            onChange={e => handleChange('singleFolderSportsMaxBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>단폴더 최대 당첨액</FormGroupLabel>
                <FormGroupField>
                  <Field name="singleFolderSportsMaxWinningAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.singleFolderSportsMaxWinningAmount}
                            onChange={e => handleChange('singleFolderSportsMaxWinningAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>단폴더 배팅시 증감(±)</FormGroupLabel>
                <FormGroupField>
                  <Field name="reduceBettingPerForSingleFolder">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.reduceBettingPerForSingleFolder}
                            onChange={e => handleChange('reduceBettingPerForSingleFolder', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>2폴더 최소배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="twoFolderSportsMinBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.twoFolderSportsMinBettingPer}
                            onChange={e => handleChange('twoFolderSportsMinBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>2폴더 최대배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="twoFolderSportsMaxBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.twoFolderSportsMaxBettingPer}
                            onChange={e => handleChange('twoFolderSportsMaxBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>2폴더 최소 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="twoFolderSportsMinBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.twoFolderSportsMinBettingAmount}
                            onChange={e => handleChange('twoFolderSportsMinBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>2폴더 최대 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="twoFolderSportsMaxBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.twoFolderSportsMaxBettingAmount}
                            onChange={e => handleChange('twoFolderSportsMaxBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>2폴더 최대 당첨액</FormGroupLabel>
                <FormGroupField>
                  <Field name="twoFolderSportsMaxWinningAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.twoFolderSportsMaxWinningAmount}
                            onChange={e => handleChange('twoFolderSportsMaxWinningAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>다폴더 최소배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="multiFolderSportsMinBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.multiFolderSportsMinBettingPer}
                            onChange={e => handleChange('multiFolderSportsMinBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>다폴더 최대배당</FormGroupLabel>
                <FormGroupField>
                  <Field name="multiFolderSportsMaxBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.multiFolderSportsMaxBettingPer}
                            onChange={e => handleChange('multiFolderSportsMaxBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>다폴더 최소 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="multiFolderSportsMinBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.multiFolderSportsMinBettingAmount}
                            onChange={e => handleChange('multiFolderSportsMinBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>다폴더 최대 배팅액</FormGroupLabel>
                <FormGroupField>
                  <Field name="multiFolderSportsMaxBettingAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.multiFolderSportsMaxBettingAmount}
                            onChange={e => handleChange('multiFolderSportsMaxBettingAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>다폴더 최대 당첨액</FormGroupLabel>
                <FormGroupField>
                  <Field name="multiFolderSportsMaxWinningAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.multiFolderSportsMaxWinningAmount}
                            onChange={e => handleChange('multiFolderSportsMaxWinningAmount', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                  <TooltipWrapper
                    text="다폴 구성 시 배당 X이상의 폴더가 반드시 1개 이상 일때, 시스템에서 배팅이 성립"
                    place="bottom"
                  >
                    <span>다폴더 베팅허용기준</span>
                  </TooltipWrapper>
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="criteriaAllowedMultiFolderBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.criteriaAllowedMultiFolderBettingPer}
                            onChange={e => handleChange('criteriaAllowedMultiFolderBettingPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>보너스폴더 자동 선택여부</FormGroupLabel>
                <FormGroupField>
                  <Field name="bonusFolderAutoSelect">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap
                          style={{
                            flexDirection: 'column',
                            alignItems: 'start',
                            position: 'relative',
                            border: 'solid 1px #000',
                          }}
                        >
                          <CustomSwitch
                            onChange={checked => {
                              handleChange('bonusFolderAutoSelect', checked) // Assuming handleChange updates the state appropriately
                            }}
                            checked={gameLevel?.bonusFolderAutoSelect}
                          />{' '}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                  <TooltipWrapper text="보너스 폴더수 구성시 X이상 배당만 폴더수에 포함 " place="bottom">
                    <span>보너스폴더 허용기준 배당</span>
                  </TooltipWrapper>
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="bonusFolderPerCriteria">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap
                          style={{
                            flexDirection: 'column',
                            alignItems: 'start',
                            position: 'relative',
                          }}
                        >
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.bonusFolderPerCriteria}
                            onChange={e => handleChange('bonusFolderPerCriteria', e.target.value)}
                          />
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ width: '60%' }}>1번째 보너스 기준(폴더수,배당)</FormGroupLabel>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="firstBonusFolderNum">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.firstBonusFolderNum}
                            onChange={e => handleChange('firstBonusFolderNum', e.target.value)}
                          />
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="firstBonusFolderPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.firstBonusFolderPer}
                            onChange={e => handleChange('firstBonusFolderPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel style={{ width: '60%' }}>2번째 보너스 기준(폴더수,배당)</FormGroupLabel>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="secondBonusFolderNum">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.secondBonusFolderNum}
                            onChange={e => handleChange('secondBonusFolderNum', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="secondBonusFolderPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.secondBonusFolderPer}
                            onChange={e => handleChange('secondBonusFolderPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel style={{ width: '60%' }}>3번째 보너스 기준(폴더수,배당)</FormGroupLabel>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="thirdBonusFolderNum">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.thirdBonusFolderNum}
                            onChange={e => handleChange('thirdBonusFolderNum', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
                <FormGroupField style={{ width: '20%' }}>
                  <Field name="thirdBonusFolderPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', position: 'relative' }}>
                          <input
                            {...input}
                            type="text"
                            value={gameLevel?.thirdBonusFolderPer}
                            onChange={e => handleChange('thirdBonusFolderPer', e.target.value)}
                          />
                          {meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              {(authority === 'SUPER' || authority === 'DB_DOWN') && (
                <FormButtonToolbar className="registrationFooter">
                  <Button variant="primary" type="submit">
                    변경하기
                  </Button>
                  <Button variant="danger" onClick={onSubmit2}>
                    모든레벨 변경하기
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </div>
    </div>
  )
}

export default SpecialSportsGameLevel

SpecialSportsGameLevel.propTypes = {
  specialSportsGameLevel: PropTypes.shape({}),
  fetchSportsGameLevel: PropTypes.func.isRequired,
  selectedLevel: PropTypes.string.isRequired,
}

SpecialSportsGameLevel.defaultProps = {
  specialSportsGameLevel: null,
}

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 45%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #dedede;
  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const HeaderBox = styled.div`
  background: #b8ffa6;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`
