import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { BettingResultEnumsSelectOptions, bettingGameTypeEnumsSelectOptions } from '../../../enums/Log/bettingEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import { searchBetting } from '../../../utils/api/logManage/logApi'
import { deleteCasinoBettingLog } from '../../../utils/api/memberManage/deleteHistoryApi'
import { downloadMemberCasinoList } from '../../../utils/api/memberManage/memberManageApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import BettingManagementTabs from '../BettingManagementTabs'
import BTIBettingLogListData from './BTIBettingLogListData'

const BTIBettingLogList = () => {
  const history = useHistory()

  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   배팅로그 조회
   */
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  // endDate 설정
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)

  // startDate 설정
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)

  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    edgeName: null,
    authority: null,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
    gameType: 'LIVE_SPORT',
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [loading, setLoading] = useState(false)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchBetting = async () => {
    if (loading) return
    setLoading(true)
    await searchBetting(bettingLogparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchBetting()
  }, [bettingLogparams])

  const [checkRadio, setCheckRadio] = useState([])
  const [bettingLogListData, setBettingLogListData] = useState(
    BTIBettingLogListData(content, checkRadio, setCheckRadio),
  )
  useEffect(() => {
    setBettingLogListData(BTIBettingLogListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 제목
  const [searchTitle, setSearchTitle] = useState('')

  const onSearchTitleHandler = e => {
    setSearchTitle(e.target.value)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = BettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 게임 라운드 검색
  const [searchGameRound, setSearchGameRound] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId.trim(),
      gameResult: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      gameRound: !searchGameRound ? null : searchGameRound,
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      page: 0,
    }))
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerName: '상위파트너명',
    authority: '권한',
    agencyName: '영상사',
    bettingTime: '배팅시간',
    memberId: '유저ID',
    memberName: '유저명',
    processAmount: '배팅액/당첨액',
    bettingResult: '배팅결과',
    bettingBeforeAmount: '배팅전잔액',
    bettingAfterAmount: '배팅후잔액',
    bettingId: '배팅ID',
    vendorName: '벤더사',
    gameType: '슬롯/라이브',
    gameName: '게임명',
    gameRound: '게임라운드',
    bettingNum: '배팅번호',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '카지노 배팅내역 DB를 csv파일로 다운 받으시겠습니까?\n 기간설정은 필수 사항이며, 유저ID/명 검색은 선택사항입니다.',
      )
    ) {
      if (searchStartDate === null || searchEndDate === null) {
        alert('기간 설정은 필수 입니다.')
        return
      }
      if (searchStartDate > searchEndDate) {
        alert('기간을 다시 확인해주세요.')
        return
      }
      // if (searchEndDate - searchStartDate > 604800000) {
      //   alert('두 날짜 간의 차이는 1주일 이하여야 합니다.')
      //   return
      // }

      setDownloadLoading(true)
      downloadMemberCasinoList({
        memberId: !searchMemberId ? null : searchMemberId.trim(),
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId.trim(),
        startDate: convertToKst(searchStartDate),
        endDate: convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '카지노 배팅 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const [writeLoading, setWriteLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const onDeleteHandler = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('비노출 할 내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`카지노 배팅내역을 비노출 하시겠습니까?\n비노출 시 유저에게 해당 배팅내역은 보이지 않습니다.`)) {
      setWriteLoading(true)
      const body = {
        casinoHistoryKeyList: checkRadio,
        allDelete: false,
      }
      deleteCasinoBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
          fetchSearchBetting()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchBetting()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onDeleteHandlerAll = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return

    if (
      window.confirm(`카지노 배팅내역을 전체 비노출 하시겠습니까?\n비노출 시 유저에게 해당 배팅내역은 보이지 않습니다.`)
    ) {
      setWriteLoading(true)
      const body = {
        casinoHistoryKeyList: [],
        allDelete: true,
      }
      deleteCasinoBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
          fetchSearchBetting()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchBetting()
          setWriteLoading(false)
        })
    } else {
      setWriteLoading(false)
    }
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('3')
  return (
    <Card>
      <CardBody>
        {loading && <Loading />}
        <BettingManagementTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 콜 적용한 이력에 대해서 여러개가 보일 수 있으며, 첫번째를 제외한 나머지 배팅액은 0원으로 표기됩니다.
            </p>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>상위파트너로 검색</span>시, 해당 파트너 포함,
              직영/하부 회원&파트너들의 내역을 가져옵니다.
            </p>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>최대 당첨금 제한</span>일때, 차액만큼 관리자
              보유알로 회수가 됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>

        <MiniGameTypeSelectWrapper>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              history.push('/management/log/betting')
            }}
          >
            전체
          </Button>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              history.push('/management/log/betting/casino')
            }}
          >
            카지노 내역
          </Button>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              history.push('/management/log/betting/slot')
            }}
          >
            슬롯 내역
          </Button>

          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            active
            onClick={() => {
              history.push('/management/log/betting/bti')
            }}
          >
            BTI 스포츠 내역
          </Button>
        </MiniGameTypeSelectWrapper>

        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTopPartnerId}
                  onChange={e => onSearchTopPartnerIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임명/라운드</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchGameRound"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchGameRound}
                  onChange={e => setSearchGameRound(e.target.value)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="게임결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchStartAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchStartAmount}
                  onChange={e => onSearchStartAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchEndAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchEndAmount}
                  onChange={e => onSearchEndAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              배팅내역 DB다운
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'orange', color: 'white' }}
              onClick={() => {
                setCheckRadio(content.map(value => value.casinoBettingLogKey))
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onDeleteHandler()
              }}
            >
              삭제 (비노출)
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                onDeleteHandlerAll()
              }}
            >
              전체삭제
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default BTIBettingLogList

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  // margin-bottom: 5px;

  display: flex;
  gap: 5px;
`
