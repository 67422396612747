import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { searchMemberDisableGame, updateMemberDisableGame } from '@/utils/api/memberManage/memberManageApi'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../../shared/components/Loading'
import { decodeAccessToken } from '../../../../../utils/token'
// import {
//   memberBackRollingInfoValidate,
//   memberRollingInfoValidate,
// } from '../../../../utils/validate/memberManage/memberInfoValidate'
// import { decodeAccessToken } from '../../../../utils/token'

function DisableGameModifyForm({ memberId, memberName, partnerLine, status, onModalShowHandler, fetchSearchMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  // 매장, 총판, 부본사, 본사 변수
  // const [partnerLine, setPartnerLine] = useState('')
  const [userKey, setUserKey] = useState(false)
  const [userAuthority, setUserAuthority] = useState('MEMBER')
  const [disableDomesticSports, setDisableDomesticSports] = useState(false)
  const [disableOverseasSports, setDisableOverseasSports] = useState(false)
  const [disableSpecialSports, setDisableSpecialSports] = useState(false)
  const [disableLiveSports, setDisableLiveSports] = useState(false)
  const [disableLiveCasino, setDisableLiveCasino] = useState(false)
  const [disableSlotCasino, setDisableSlotCasino] = useState(false)
  const [disableNtryMiniGame, setDisableNtryMiniGame] = useState(false)
  const [disableBoscoreMiniGame, setDisableBoscoreMiniGame] = useState(false)
  const [disableNamedMiniGame, setDisableNamedMiniGame] = useState(false)
  const [disableSuremanMiniGame, setDisableSuremanMiniGame] = useState(false)
  const [disableGoPickMiniGame, setDisableGoPickMiniGame] = useState(false)
  const [disableTokenGameMiniGame, setDisableTokenGameMiniGame] = useState(false)
  const [disableCrownMiniGame, setDisableCrownMiniGame] = useState(false)
  const [disableMGMMiniGame, setDisableMGMMiniGame] = useState(false)
  const [disableLotusMiniGame, setDisableLotusMiniGame] = useState(false)
  const [disableDonghangMiniGame, setDisableDonghangMiniGame] = useState(false)
  const [disableBePickMiniGame, setDisableBePickMiniGame] = useState(false)
  const [disableNextMiniGame, setDisableNextMiniGame] = useState(false)
  const [disableHoldem, setDisableHoldem] = useState(false)
  const [disableVirtualSoccerGame, setDisableVirtualSoccerGame] = useState(false)
  const [disableVirtualBasketballGame, setDisableVirtualBasketballGame] = useState(false)
  const [disableVirtualBaseballGame, setDisableVirtualBaseballGame] = useState(false)
  const [disableVirtualGreyHoundsGame, setDisableVirtualGreyHoundsGame] = useState(false)
  const [disableVirtualHorseGame, setDisableVirtualHorseGame] = useState(false)

  const getMemberInfo = async () => {
    await searchMemberDisableGame({
      userId: memberId,
    }).then(res => {
      console.log('res.data.userKey', res.data.userKey)

      setUserAuthority(res.data.userAuthority)
      setUserKey(res.data.userKey)
      setDisableDomesticSports(res.data.disableDomesticSports)
      setDisableOverseasSports(res.data.disableOverseasSports)
      setDisableSpecialSports(res.data.disableSpecialSports)
      setDisableLiveSports(res.data.disableLiveSports)
      setDisableLiveCasino(res.data.disableLiveCasino)
      setDisableSlotCasino(res.data.disableSlotCasino)
      setDisableNtryMiniGame(res.data.disableNtryMiniGame)
      setDisableBoscoreMiniGame(res.data.disableBoscoreMiniGame)
      setDisableNamedMiniGame(res.data.disableNamedMiniGame)
      setDisableSuremanMiniGame(res.data.disableSuremanMiniGame)
      setDisableGoPickMiniGame(res.data.disableGoPickMiniGame)
      setDisableTokenGameMiniGame(res.data.disableTokenGameMiniGame)
      setDisableCrownMiniGame(res.data.disableCrownMiniGame)
      setDisableMGMMiniGame(res.data.disableMGMMiniGame)
      setDisableLotusMiniGame(res.data.disableLotusMiniGame)
      setDisableDonghangMiniGame(res.data.disableDonghangMiniGame)
      setDisableBePickMiniGame(res.data.disableBePickMiniGame)
      setDisableNextMiniGame(res.data.disableNextMiniGame)
      setDisableHoldem(res.data.disableHoldem)
      setDisableVirtualSoccerGame(res.data.disableVirtualSoccerGame)
      setDisableVirtualBasketballGame(res.data.disableVirtualBasketballGame)
      setDisableVirtualBaseballGame(res.data.disableVirtualBaseballGame)
      setDisableVirtualGreyHoundsGame(res.data.disableVirtualGreyHoundsGame)
      setDisableVirtualHorseGame(res.data.disableVirtualHorseGame)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = ({ allUser }) => {
    if (infoLoading) return

    if (status === 'CANCEL' || status === 'WAITING' || status === 'APPLY') {
      alert('회원의 상태가 신청,대기,취소 인 경우에는 회원정보를 변경할 수 없습니다.')
      return
    }

    const confirmationMessage = allUser
      ? `[${memberId}]를 포함한 하부 유저까지 금지게임 설정을 변경하시겠습니까?`
      : `[${memberId}]의 금지게임 설정을 변경하시겠습니까?`

    if (window.confirm(confirmationMessage)) {
      const body = {
        allUser,
        userKey,
        userId: memberId,
        disableDomesticSports,
        disableOverseasSports,
        disableSpecialSports,
        disableLiveSports,
        disableLiveCasino,
        disableSlotCasino,
        disableNtryMiniGame,
        disableBoscoreMiniGame,
        disableNamedMiniGame,
        disableSuremanMiniGame,
        disableGoPickMiniGame,
        disableTokenGameMiniGame,
        disableCrownMiniGame,
        disableMGMMiniGame,
        disableLotusMiniGame,
        disableDonghangMiniGame,
        disableBePickMiniGame,
        disableNextMiniGame,
        disableHoldem,
        disableVirtualSoccerGame,
        disableVirtualBasketballGame,
        disableVirtualBaseballGame,
        disableVirtualGreyHoundsGame,
        disableVirtualHorseGame,
      }
      setInfoLoading(true)
      console.log('body', body)
      updateMemberDisableGame(body)
        .then(res => {
          setInfoLoading(false)
          alert('금지게임 설정 정보가 수정되었습니다.')
          fetchSearchMember()
        })
        .catch(error => {
          setInfoLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'MEMBER-1007') {
            alert(`${message}`)
          } else if (errorCode === 'MEMBER-1008') {
            alert(`${message}`)
          } else if (errorCode === 'MEMBER-1009') {
            alert(`${message}`)
          } else if (errorCode === 'MEMBER-1010') {
            alert(`${message}`)
          } else {
            alert('금지게임 설정 정보가 정보 수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
          }
        })
    }
  }

  return (
    <>
      {infoLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            onSubmit={onSubmit}
            // validate={memberBackRollingInfoValidate}
            initialValues={{
              memberId: `${memberId}(${memberName})`,
              memberName,
              partnerLine,
              disableDomesticSports,
              disableOverseasSports,
              disableSpecialSports,
              disableLiveSports,
              disableLiveCasino,
              disableSlotCasino,
              disableNtryMiniGame,
              disableBoscoreMiniGame,
              disableNamedMiniGame,
              disableSuremanMiniGame,
              disableGoPickMiniGame,
              disableTokenGameMiniGame,
              disableCrownMiniGame,
              disableMGMMiniGame,
              disableLotusMiniGame,
              disableDonghangMiniGame,
              disableBePickMiniGame,
              disableNextMiniGame,
              disableHoldem,
              disableVirtualSoccerGame,
              disableVirtualBasketballGame,
              disableVirtualBaseballGame,
              disableVirtualGreyHoundsGame,
              disableVirtualHorseGame,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>회원ID(명)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="memberId">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            disabled
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>파트너 라인</FormGroupLabel>
                  <FormGroupField>
                    <Field name="partnerLine">
                      {({ input, meta }) => (
                        <FormInputWrap
                          style={{ border: 'solid 1px #f2f4f7', paddingLeft: '5px', backgroundColor: '#F2F4F7' }}
                        >
                          <div>{partnerLine} </div>
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <SearchCardTitleWrap style={{ width: '100%', marginTop: '-15px' }}>
                  <p style={{ color: 'red', fontWeight: '500' }}>체크가 되어있으면, 해당게임은 이용이 제한됩니다.</p>
                </SearchCardTitleWrap>
                <FormGroup>
                  <FormGroupLabel>라이브/슬롯/홀덤</FormGroupLabel>
                  <FormGroupField>
                    <CheckBoxWrapper>
                      <label htmlFor="disableLiveCasino">
                        <input
                          type="checkbox"
                          id="disableLiveCasino"
                          checked={disableLiveCasino}
                          onChange={() => {
                            setDisableLiveCasino(prev => !prev)
                          }}
                        />
                        <span>라이브 카지노</span>
                      </label>
                      <label htmlFor="disableSlotCasino">
                        <input
                          type="checkbox"
                          id="disableSlotCasino"
                          checked={disableSlotCasino}
                          onChange={() => {
                            setDisableSlotCasino(prev => !prev)
                          }}
                        />
                        <span>슬롯</span>
                      </label>
                      <label htmlFor="disableHoldem">
                        <input
                          type="checkbox"
                          id="disableHoldem"
                          checked={disableHoldem}
                          onChange={() => {
                            setDisableHoldem(prev => !prev)
                          }}
                        />
                        <span>홀덤</span>
                      </label>
                    </CheckBoxWrapper>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>스포츠</FormGroupLabel>
                  <FormGroupField>
                    <CheckBoxWrapper>
                      <label htmlFor="disableDomesticSports">
                        <input
                          type="checkbox"
                          id="disableDomesticSports"
                          checked={disableDomesticSports}
                          onChange={() => {
                            setDisableDomesticSports(prev => !prev)
                          }}
                        />
                        <span>국내형</span>
                      </label>
                      <label htmlFor="disableOverseasSports">
                        <input
                          type="checkbox"
                          id="disableOverseasSports"
                          checked={disableOverseasSports}
                          onChange={() => {
                            setDisableOverseasSports(prev => !prev)
                          }}
                        />
                        <span>해외형</span>
                      </label>
                      <label htmlFor="disableSpecialSports">
                        <input
                          type="checkbox"
                          id="disableSpecialSports"
                          checked={disableSpecialSports}
                          onChange={() => {
                            setDisableSpecialSports(prev => !prev)
                          }}
                        />
                        <span>스페셜</span>
                      </label>
                      <label htmlFor="disableLiveSports">
                        <input
                          type="checkbox"
                          id="disableLiveSports"
                          checked={disableLiveSports}
                          onChange={() => {
                            setDisableLiveSports(prev => !prev)
                          }}
                        />
                        <span>인플레이</span>
                      </label>
                    </CheckBoxWrapper>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>미니게임</FormGroupLabel>
                  <div style={{ width: 'calc(100% - 120px)' }}>
                    <CheckBoxWrapper>
                      <label htmlFor="disableNtryMiniGame">
                        <input
                          type="checkbox"
                          id="disableNtryMiniGame"
                          checked={disableNtryMiniGame}
                          onChange={() => {
                            setDisableNtryMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>엔트리</span>{' '}
                      </label>
                      <label htmlFor="disableBoscoreMiniGame">
                        <input
                          type="checkbox"
                          id="disableBoscoreMiniGame"
                          checked={disableBoscoreMiniGame}
                          onChange={() => {
                            setDisableBoscoreMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>보스코어</span>{' '}
                      </label>
                      <label htmlFor="disableNamedMiniGame">
                        <input
                          type="checkbox"
                          id="disableNamedMiniGame"
                          checked={disableNamedMiniGame}
                          onChange={() => {
                            setDisableNamedMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>네임드</span>{' '}
                      </label>
                      <label htmlFor="disableSuremanMiniGame">
                        <input
                          type="checkbox"
                          id="disableSuremanMiniGame"
                          checked={disableSuremanMiniGame}
                          onChange={() => {
                            setDisableSuremanMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>슈어맨</span>{' '}
                      </label>
                      <label htmlFor="disableGoPickMiniGame">
                        <input
                          type="checkbox"
                          id="disableGoPickMiniGame"
                          checked={disableGoPickMiniGame}
                          onChange={() => {
                            setDisableGoPickMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>고픽</span>{' '}
                      </label>
                      <label htmlFor="disableTokenGameMiniGame">
                        <input
                          type="checkbox"
                          id="disableTokenGameMiniGame"
                          checked={disableTokenGameMiniGame}
                          onChange={() => {
                            setDisableTokenGameMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>토큰</span>{' '}
                      </label>
                      <label htmlFor="disableCrownMiniGame">
                        <input
                          type="checkbox"
                          id="disableCrownMiniGame"
                          checked={disableCrownMiniGame}
                          onChange={() => {
                            setDisableCrownMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>크라운</span>{' '}
                      </label>
                      <label htmlFor="disableMGMMiniGame">
                        <input
                          type="checkbox"
                          id="disableMGMMiniGame"
                          checked={disableMGMMiniGame}
                          onChange={() => {
                            setDisableMGMMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>MGM</span>{' '}
                      </label>
                      <label htmlFor="disableLotusMiniGame">
                        <input
                          type="checkbox"
                          id="disableLotusMiniGame"
                          checked={disableLotusMiniGame}
                          onChange={() => {
                            setDisableLotusMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>로투스</span>{' '}
                      </label>
                      <label htmlFor="disableDonghangMiniGame">
                        <input
                          type="checkbox"
                          id="disableDonghangMiniGame"
                          checked={disableDonghangMiniGame}
                          onChange={() => {
                            setDisableDonghangMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>동행</span>{' '}
                      </label>
                      <label htmlFor="disableBePickMiniGame">
                        <input
                          type="checkbox"
                          id="disableBePickMiniGame"
                          checked={disableBePickMiniGame}
                          onChange={() => {
                            setDisableBePickMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>베픽</span>{' '}
                      </label>
                      <label htmlFor="disableNextMiniGame">
                        <input
                          type="checkbox"
                          id="disableNextMiniGame"
                          checked={disableNextMiniGame}
                          onChange={() => {
                            setDisableNextMiniGame(prev => !prev)
                          }}
                        />{' '}
                        <span>넥스트</span>{' '}
                      </label>
                    </CheckBoxWrapper>
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>가상게임</FormGroupLabel>
                  <FormGroupField>
                    <CheckBoxWrapper>
                      <label htmlFor="disableVirtualSoccerGame">
                        <input
                          type="checkbox"
                          id="disableVirtualSoccerGame"
                          checked={disableVirtualSoccerGame}
                          onChange={() => {
                            setDisableVirtualSoccerGame(prev => !prev)
                          }}
                        />{' '}
                        <span>축구</span>{' '}
                      </label>
                      <label htmlFor="disableVirtualBasketballGame">
                        <input
                          type="checkbox"
                          id="disableVirtualBasketballGame"
                          checked={disableVirtualBasketballGame}
                          onChange={() => {
                            setDisableVirtualBasketballGame(prev => !prev)
                          }}
                        />{' '}
                        <span>농구</span>{' '}
                      </label>
                      <label htmlFor="disableVirtualBaseballGame">
                        <input
                          type="checkbox"
                          id="disableVirtualBaseballGame"
                          checked={disableVirtualBaseballGame}
                          onChange={() => {
                            setDisableVirtualBaseballGame(prev => !prev)
                          }}
                        />{' '}
                        <span>야구</span>{' '}
                      </label>
                      <label htmlFor="disableVirtualGreyHoundsGame">
                        <input
                          type="checkbox"
                          id="disableVirtualGreyHoundsGame"
                          checked={disableVirtualGreyHoundsGame}
                          onChange={() => {
                            setDisableVirtualGreyHoundsGame(prev => !prev)
                          }}
                        />{' '}
                        <span>개경주</span>{' '}
                      </label>
                      <label htmlFor="disableVirtualHorseGame">
                        <input
                          type="checkbox"
                          id="disableVirtualHorseGame"
                          checked={disableVirtualHorseGame}
                          onChange={() => {
                            setDisableVirtualHorseGame(prev => !prev)
                          }}
                        />{' '}
                        <span>경마</span>{' '}
                      </label>
                    </CheckBoxWrapper>
                  </FormGroupField>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                        <CustomButton
                          variant="primary"
                          type="button"
                          onClick={() => {
                            onSubmit({ allUser: false })
                          }}
                        >
                          변경하기
                        </CustomButton>
                        {userAuthority === 'PARTNER' && (
                          <CustomButton
                            variant="primary"
                            type="button"
                            onClick={() => {
                              onSubmit({ allUser: true })
                            }}
                          >
                            하부 일괄변경
                          </CustomButton>
                        )}
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default DisableGameModifyForm

DisableGameModifyForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
  memberName: PropTypes.string.isRequired,
  partnerLine: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`

const CheckBoxWrapper = styled.div`
  padding: 3px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  gap: 10px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 100%;

    cursor: pointer;
    padding: 3px;

    margin-bottom: 0;

    input {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 16px;
      margin-left: 5px;
    }

    &:hover {
      color: blue;
    }
  }
`
