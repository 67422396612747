import React, { useMemo, useState } from 'react'
import { convertToKstDateTime, convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { MoneyIncDecAuthorityEnums } from '../../../enums/Log/moneyIncDecEnums'
import { couponStatusEnums } from '../../../enums/Operation/couponEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const CouponPayLogListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰번호',
      accessor: 'couponKey',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponKey?.substring(0, 8)
      },
    },
    {
      Header: '쿠폰명',
      accessor: 'couponName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '관리자 ID',
      accessor: 'adminId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저 ID(명)',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {MoneyIncDecAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '지급액',
      accessor: 'couponMoney',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ fontWeight: '500' }}>{original.couponMoney?.toString().replace(commonReg2, ',')}</span>
      },
    },
    {
      Header: '지급일',
      accessor: 'paymentDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.paymentDate)
      },
    },
    {
      Header: '사용일',
      accessor: 'usedDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.usedDate)
      },
    },
    {
      Header: '만료일',
      accessor: 'expiredDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.expiredDate)
      },
    },
    {
      Header: '상태',
      accessor: 'couponUseStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return couponStatusEnums[original.couponUseStatus]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CouponPayLogListData
