import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { MacroTypeEnumsSelectOptions } from '../../../enums/SystemManagement/macroEnums'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import { searchMacroManage } from '../../../utils/api/systemManage/systemManageApi'
import SystemManagementTabs from '../SystemManagementTabs'
import MacroListData from './MacroListData'
import MacroRegistrationModal from './MacroRegistrationModal'
import OperationTabs from '../../Operation/OperationTabs'
import Loading from '../../../shared/components/Loading'

const MacroList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  /**
   매크로관리 조회
   */
  const [macroParams, setMacroParams] = useState({
    page,
    size,
    edgeName: null,
    searchType: null,
  })

  useEffect(() => {
    setMacroParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSearchMacroManage = async () => {
    if (loading) return
    setLoading(true)
    await searchMacroManage(macroParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMacroManage()
  }, [macroParams])

  const [macroListData, setMacroListData] = useState(MacroListData(content, fetchSearchMacroManage))
  useEffect(() => {
    setMacroListData(MacroListData(content, fetchSearchMacroManage))
  }, [content])

  const [rows, setData] = useState(macroListData.tableRowsData)
  useEffect(() => {
    setData(macroListData.tableRowsData)
  }, [macroListData])

  const [isMacroRegistrationModalShow, setMacroRegistrationModalShow] = useState(false)

  const onClickHandler = () => {
    setMacroRegistrationModalShow(prevState => !prevState)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 분류(쪽지, 1:1문의)
  const [searchType, setSearchType] = useState('')
  const [searchTypeOptionValue, setSearchTypeOptionValue] = useState(null)
  const searchTypeOption = MacroTypeEnumsSelectOptions()

  const onSelectTypeOptionHandler = option => {
    if (option === null) {
      setSearchTypeOptionValue(null)
      setSearchType(null)
      return
    }
    setSearchTypeOptionValue(option)
    setSearchType(option.value)
  }

  // 태그
  const [searchMacroTag, setSearchMacroTag] = useState('')

  const onSearchMacroTagHandler = e => {
    setSearchMacroTag(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMacroParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      searchType: !searchType ? null : searchType,
    }))
  }

  // tab 키
  const [activeKey, setActiveKey] = useState('5')
  return (
    <Card>
      <CardBody>
        {loading && <Loading />}
        <OperationTabs activeKey={activeKey} />
        <SearchContainer horizontal style={{ marginTop: '20px' }}>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>분류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchMacro"
                onChange={option => {
                  onSelectTypeOptionHandler(option)
                }}
                value={searchTypeOptionValue}
                options={searchTypeOption}
                placeholder="분류"
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ background: 'green', color: '#fff' }}
              variant="outline-secondary"
              onClick={() => {
                onClickHandler()
              }}
            >
              매크로 등록
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={macroListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
      <MacroRegistrationModal
        isMacroRegistrationModalShow={isMacroRegistrationModalShow}
        onClickHandler={onClickHandler}
        fetchSearchMacroManage={fetchSearchMacroManage}
      />
    </Card>
  )
}

export default MacroList
