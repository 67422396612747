import { commonReg7 } from '../commonValidate'

export const UserAssetIncDecValidate = values => {
  const errors = {}

  // if (!values.moneyIncDecAmount || commonReg7.test(values.moneyIncDecAmount)) {
  //   errors.moneyIncDecAmount = '숫자만 입력 가능합니다.'
  // }

  // if (!values.moneyIncDecMemo || values.moneyIncDecMemo === '') {
  //   errors.moneyIncDecMemo = '필수 사항입니다.(10글자 이내)'
  // }

  // if (values.moneyIncDecType === '' || !values.moneyIncDecType) {
  //   errors.moneyIncDecType = '지급 및 감소를 선택하세요.'
  // }

  if (values.assetType === '' || !values.assetType) {
    errors.assetType = '머니 / 포인트를 선택하세요.'
  }

  return errors
}
