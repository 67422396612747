import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  MemberCasinoLevelEnum,
  MemberDepositLevelEnum,
  MemberFriendCompLevelEnum,
  MemberHoldemLevelEnum,
  MemberMiniGameLevelEnum,
  MemberSportsLevelEnum,
  MemberStatusEnums,
  MemberVirtualGameLevelEnum,
} from '../../../enums/MemberManage/MemberInfoEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { PartnerLosingType } from '../../../enums/Statistics/partnerLosingStatistics'
import {
  fetchSearchPartner,
  handleToggle,
  onClickEdgeSelectHandler,
  onClickSearchHandler,
  resetPartnerList,
} from '../../../redux/PartnerInfoSlice'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { scrollbarStyles } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../MemberList/MemberCoupon/MemberCoupon'
import MemberLosingInfoPage from '../MemberList/MemberInfo/MemberLosingInfo/MemberLosingInfoPage'
import MemberHistoryMoneyDec from '../MemberList/MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyDec'
import MemberHistoryMoneyIncDecV2 from '../MemberList/MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyIncDecV2'
import MemberRollingInfoPage from '../MemberList/MemberInfo/MemberRollingInfo/MemberRollingInfoPage'
import MemberLogOut from '../MemberList/MemberLogOut/MemberLogOut'
import MemberNote from '../MemberList/MemberNote/MemberNote'
import MemberRefresh from '../MemberList/MemberRefresh/MemberRefresh'
import RegistrationDownUser from '../MemberList/RegistrationDownUser/RegistrationDownUser'
import MemberManageMentTabs from '../MemberManageMentTabs'
import PartnerNoteForm from '../PartnerNote/PartnerNoteForm'

const getUserAuthority = userAuthority => {
  return (
    <CustomAuthorityButton authority={userAuthority} disabled>
      {NoteAuthorityEnums[userAuthority]}
    </CustomAuthorityButton>
  )
}

const getMoneyAmount = moneyAmount => {
  if (!moneyAmount) return <span>0</span>
  if (moneyAmount > 1000000) {
    return <span style={{ color: 'red', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
  }
  return <span style={{ color: 'black', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
}

const getSettlementType = settlementType => {
  switch (settlementType) {
    case 'LOSING_SETTLEMENT_TYPE1':
      return <span style={{ color: 'orange', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE2':
      return <span style={{ color: 'purple', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE3':
      return <span style={{ color: '#B92EFA', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE4':
      return <span style={{ color: '#D79209', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE5':
      return <span style={{ color: 'brown', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE6':
      return <span style={{ color: '#A8D420', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE7':
      return <span style={{ color: '#634BC6', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE8':
      return <span style={{ color: '#f28c0f', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    default:
      return <span style={{ color: '#black', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
  }
}

const getUserStatus = status => {
  switch (status) {
    case 'NORMAL':
      return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'WAITING':
      return <span style={{ color: 'orange', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'CANCEL':
      return <span style={{ color: 'red', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'BLOCK':
      return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'APPLY':
      return <span style={{ color: 'green', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    default:
      return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
  }
}

const DisplayUserComponent = ({
  user = {},
  selectedUserId,
  depth = 0,
  toggleIsOpened,
  isActiveDefaultInfo,
  isActiveRollingInfo,
  isActiveLosingInfo,
  setModalShow,
  setWhatModalShow,
  setModalUserId,
}) => {
  const userRef = useRef(null) // 사용자 요소에 대한 참조
  const handleIconClick = key => {
    toggleIsOpened(key)
  }
  useEffect(() => {
    // 사용자 요소가 마운트된 후에만 실행되도록 설정
    if (user.userId === selectedUserId && user.isOpened && userRef.current) {
      // 사용자 요소가 화면에 표시되도록 스크롤 이동
      userRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [user.isOpened])

  const getSquareIcon = (existSubUser, isOpend) => {
    switch (true) {
      case !existSubUser:
        return <PlusSquare style={{ color: 'grey' }} />
      case isOpend:
        return <MinusSquare />
      case !isOpend:
        return <PlusSquare />
      default:
        return <PlusSquare style={{ color: 'grey' }} />
    }
  }

  return (
    <>
      <CustomTr ref={userRef} style={{ background: depth === 0 ? '#fbf7ea' : 'inherit' }} depth={depth}>
        <td
          style={{
            paddingLeft: `${depth * 20 + 20}px`,
            textAlign: 'left',
            borderLeft: 'solid 1px black',
            fontSize: '13px',
          }}
        >
          {depth > 0 && `\u2514 `}
          {user?.userAuthority === 'PARTNER' && (
            <span
              onClick={() => {
                if (user?.subUsersCount <= 0) {
                  return
                }

                handleIconClick(user.userId)
              }}
              style={{ cursor: user?.subUsersCount > 0 ? 'pointer' : 'inherit' }}
            >
              {getSquareIcon(user?.subUsersCount > 0, user.isOpened)}
            </span>
          )}
          <span
            onClick={() => {
              window.open(`/user-info/${user.userId}`, '_blank')
            }}
            style={{
              color: '#000',
              textDecoration: 'underline',
              cursor: 'pointer',
              padding: '5px 2px',
              fontWeight: '500',
              fontSize: '15px',
              backgroundColor: `${(user.partnerLevel === 1 && user.userColor) || 'transparnet'}`,
            }}
          >
            {user.userId} ({user.userName} / {user.nickName}){' '}
            {user.userAuthority === 'PARTNER' && `- [${user.partnerLevel}LV]`}
          </span>
          <span>
            <MemberDepositInfo memberId={user.userId} />
          </span>
          <span>
            <MemberCoupon memberId={user.userId} />
          </span>
          <span>
            <MemberNote memberId={user.userId} />
          </span>
          {user.userAuthority === 'PARTNER' && (
            <span>
              <RegistrationDownUser userInfo={user} />
            </span>
          )}
        </td>
        <td>{user.edgeName}</td>
        <td>{getUserAuthority(user.userAuthority)}</td>
        <td>{user.userAuthority === 'PARTNER' ? getSettlementType(user.losingSettlementType) : '-'}</td>
        {isActiveDefaultInfo && (
          <>
            <td>
              <CustomButton
                style={{ background: 'green', width: '50px', height: '30px', fontSize: '14px' }}
                onClick={() => {
                  setModalShow(true)
                  setWhatModalShow('ASSET')
                  setModalUserId(user.userId)
                }}
              >
                지급
              </CustomButton>
            </td>
            <td>
              <CustomButton
                style={{ background: 'orange', width: '50px', height: '30px', fontSize: '14px' }}
                onClick={() => {
                  setModalShow(true)
                  setWhatModalShow('ASSET_DEC')
                  setModalUserId(user.userId)
                }}
              >
                회수
              </CustomButton>
            </td>
            <td>{getMoneyAmount(user.moneyAmount)}</td>
            <td>{getMoneyAmount(user.casinoMoneyAmount)}</td>
            <td>{getMoneyAmount(user.holdemMoneyAmount)}</td>
            <td>{getMoneyAmount(user.rollingPointAmount)}</td>
            <td>{getMoneyAmount(user.losingPointAmount)}</td>
            <td>{MemberDepositLevelEnum[user.depositLevelName]}</td>
            <td>{MemberCasinoLevelEnum[user.casinoSlotLevelName]}</td>
            <td>{MemberCasinoLevelEnum[user.casinoLiveLevelName]}</td>
            <td>{MemberMiniGameLevelEnum[user.miniGameLevelName]}</td>
            <td>{MemberSportsLevelEnum[user.sportsGameLevelName]}</td>
            <td>{MemberVirtualGameLevelEnum[user.virtualGameLevelName]}</td>
            <td>{MemberHoldemLevelEnum[user.holdemLevelName]}</td>
            <td>{MemberFriendCompLevelEnum[user.friendCompLevelName]}</td>
            <td>{user.loginNow && <MemberLogOut memberId={user.userId} fetchSearchMember={fetchSearchPartner} />}</td>
            <td>{user.loginNow && <MemberRefresh userId={user.userId} fetchSearchMember={fetchSearchPartner} />}</td>
            <td>{getUserStatus(user.userStatus)}</td>
          </>
        )}
        {isActiveRollingInfo && (
          <>
            <td>
              <CustomButton
                style={{ background: 'orange' }}
                onClick={() => {
                  setModalShow(true)
                  setWhatModalShow('ROLLING')
                  setModalUserId(user.userId)
                }}
              >
                설정
              </CustomButton>
            </td>
            <td>{user.casinoSlotRollingPer}</td>
            <td>{user.casinoLiveRollingPer}</td>

            <td>{user.miniGameSingleRollingPer}</td>
            <td>{user.miniGameCombinationRollingPer}</td>
            <td>{user.sportsOneFolderRollingPer}</td>
            <td>{user.sportsTwoFolderRollingPer}</td>
            <td>{user.sportsThreeFolderRollingPer}</td>
            <td>{user.sportsFourFolderRollingPer}</td>
            <td>{user.sportsFiveFolderRollingPer}</td>
            <td>{user.sportsMultiFolderRollingPer}</td>
            <td>{user.virtualSoccerGameRollingPer}</td>
            <td>{user.virtualBaseballGameRollingPer}</td>
            <td>{user.virtualBasketballGameRollingPer}</td>
            <td>{user.virtualHorseGameRollingPer}</td>
            <td>{user.virtualGreyhoundsGameRollingPer}</td>
            <td>{user.holdemRollingPer}</td>
          </>
        )}
        {isActiveLosingInfo && (
          <>
            <td>
              {user.userAuthority !== 'MEMBER' && (
                <CustomButton
                  style={{ background: '#7d91f5' }}
                  onClick={() => {
                    setModalShow(true)
                    setWhatModalShow('LOSING')
                    setModalUserId(user.userId)
                  }}
                >
                  설정
                </CustomButton>
              )}
            </td>
            <td>{user.depositMinusWithdrawalLosingPer}</td>
            <td>{user.casinoSlotBettingMinusWinningLosingPer}</td>
            <td>{user.casinoLiveBettingMinusWinningLosingPer}</td>
            <td>{user.miniGameSingleBettingMinusWinningLosingPer}</td>
            <td>{user.miniGameCombinationBettingMinusWinningLosingPer}</td>
            <td>{user.sportsOneFolderBettingMinusWinningLosingPer}</td>
            <td>{user.sportsTwoFolderBettingMinusWinningLosingPer}</td>
            <td>{user.sportsThreeFolderBettingMinusWinningLosingPer}</td>
            <td>{user.sportsFourFolderBettingMinusWinningLosingPer}</td>
            <td>{user.sportsFiveFolderBettingMinusWinningLosingPer}</td>
            <td>{user.sportsMultiFolderBettingMinusWinningLosingPer}</td>
            <td>{user.virtualSoccerGameBettingMinusWinningLosingPer}</td>
            <td>{user.virtualBaseballGameBettingMinusWinningLosingPer}</td>
            <td>{user.virtualBasketballGameBettingMinusWinningLosingPer}</td>
            <td>{user.virtualHorseGameBettingMinusWinningLosingPer}</td>
            <td>{user.virtualGreyhoundsGameBettingMinusWinningLosingPer}</td>
          </>
        )}
      </CustomTr>
      {user.isOpened && user.subUsersCount > 0 ? (
        <>
          {user.subUserInfos?.map((subUser, idx) => (
            <DisplayUserComponent
              key={subUser.userKey}
              selectedUserId={selectedUserId}
              user={subUser}
              depth={depth + 1}
              toggleIsOpened={toggleIsOpened}
              isActiveDefaultInfo={isActiveDefaultInfo}
              isActiveRollingInfo={isActiveRollingInfo}
              isActiveLosingInfo={isActiveLosingInfo}
              setModalShow={setModalShow}
              setModalUserId={setModalUserId}
              setWhatModalShow={setWhatModalShow}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

DisplayUserComponent.propTypes = {
  user: PropTypes.shape(),
  selectedUserId: PropTypes.string,
  depth: PropTypes.number,
  toggleIsOpened: PropTypes.func,
  isActiveDefaultInfo: PropTypes.bool,
  isActiveRollingInfo: PropTypes.bool,
  isActiveLosingInfo: PropTypes.bool,
  setModalShow: PropTypes.func,
  setModalUserId: PropTypes.func,
  setWhatModalShow: PropTypes.func,
}

DisplayUserComponent.defaultProps = {
  user: {},
  selectedUserId: '',
  depth: 0,
  toggleIsOpened: () => {},
  setModalShow: () => {},
  setModalUserId: () => {},
  setWhatModalShow: () => {},
  isActiveDefaultInfo: true,
  isActiveRollingInfo: false,
  isActiveLosingInfo: false,
}

const PartnerList = () => {
  const dispatch = useDispatch()

  const { searchParams, partnerManageList, apiLoading, selectedUserId } = useSelector(state => {
    const { partnerInfo } = state

    return {
      searchParams: partnerInfo.searchParams,
      partnerManageList: partnerInfo.partnerManageList,
      apiLoading: partnerInfo.apiLoading,
      selectedUserId: partnerInfo.selectedUserId,
    }
  })

  const [selectedEdgeName, setSelectedEdgeName] = useState(null)
  const onClickSelectedEdgeNameHandler = edgeName => {
    setSelectedEdgeName(edgeName)
    dispatch(onClickEdgeSelectHandler(edgeName))
  }

  const handleToggleIsOpened = userId => {
    dispatch(handleToggle({ userId }))
  }

  useEffect(() => {
    dispatch(fetchSearchPartner(searchParams))
  }, [dispatch, searchParams])

  // 아이디, 권한, 상태, 등록일
  // 아이디
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }
  // 검색하기 버튼
  const onSearchClickHandler = () => {
    dispatch(onClickSearchHandler(!searchUserId ? null : searchUserId.trim()))
  }

  const [isTotalNoteModalShow, setTotalNoteModalShow] = useState(false)
  const [isPartnerAll, setPartnerAll] = useState(false)
  const onTotalNoteClickHandler = () => {
    setPartnerAll(false)
    setTotalNoteModalShow(prev => !prev)
  }

  const onTotalPartnerNoteClickHandler = () => {
    setPartnerAll(true)
    setTotalNoteModalShow(prev => !prev)
  }

  // 기본정보
  const [isActiveDefaultInfo, setIsActiveDefaultInfo] = useState(true)
  // 롤링 요율
  const [isActiveRollingInfo, setIsActiveRollingInfo] = useState(true)
  // 루징 요율
  const [isActiveLosingInfo, setIsActiveLosingInfo] = useState(true)

  const [isModalUserId, setModalUserId] = useState('')
  const [isModalShow, setModalShow] = useState(false)

  const onModalClickHandler = () => {
    setModalShow(prev => !prev)

    dispatch(onClickSearchHandler(!searchUserId ? null : searchUserId.trim()))
  }

  const [whatModalShow, setWhatModalShow] = useState(false)
  const onWhatModalClickHandler = what => {
    setWhatModalShow(what)
  }

  return (
    <>
      {isTotalNoteModalShow && (
        <NoteCustomStyledModal show={isTotalNoteModalShow} onHide={onTotalNoteClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onTotalNoteClickHandler}
            />
            {isPartnerAll ? (
              <>
                {/* 파트너 전체한테 쪽지보내기 */}
                <CustomModalTitle>파트너 전체 쪽지 보내기</CustomModalTitle>
              </>
            ) : (
              <>
                {/* 파트너별 쪽지보내기 */}
                <CustomModalTitle>라인별 쪽지 보내기</CustomModalTitle>
              </>
            )}
          </CustomModalHeader>
          <CustomModalBody>
            {isPartnerAll ? (
              <>
                {/* 파트너 전체한테 쪽지보내기 */}
                <PartnerNoteForm onClickHandler={onTotalNoteClickHandler} />
              </>
            ) : (
              <>
                {/* 파트너별 쪽지보내기 */}
                <PartnerNoteForm isTotal onClickHandler={onTotalPartnerNoteClickHandler} />
              </>
            )}
          </CustomModalBody>
        </NoteCustomStyledModal>
      )}
      <Card>
        <CardBody style={{ paddingBottom: '0px' }}>
          <MemberManageMentTabs activeKey="3" />
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
              <p>
                * <span style={{ color: 'red' }}>1LV</span>의 파트너 기준으로 먼저 보이게 되며, <br />{' '}
                <span style={{ color: 'blue' }}> &nbsp; +</span> 버튼을 클릭 후 하위 유저를 확인 하실 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <EdgeSearchButton
              edgeName={selectedEdgeName}
              onClickHandler={e => {
                onClickSelectedEdgeNameHandler(e.target.id || null)
              }}
            />
            <TableColumnSearchButtonBox>
              <Button
                size="sm"
                active={isActiveDefaultInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveDefaultInfo(prev => !prev)
                }}
              >
                기본정보
              </Button>
              <Button
                id="MEMBER"
                size="sm"
                active={isActiveRollingInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveRollingInfo(prev => !prev)
                }}
              >
                롤링정보
              </Button>
              <Button
                id="PARTNER"
                size="sm"
                active={isActiveLosingInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveLosingInfo(prev => !prev)
                }}
              >
                루징정보
              </Button>
            </TableColumnSearchButtonBox>
            <SearchGroup style={{ marginLeft: '30px' }}>
              <SearchGroupLabel>유저 ID/명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                  <input style={{ display: 'none' }} type="text" />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          {apiLoading && <Loading />}

          <TableWrap>
            <table>
              <thead>
                <tr>
                  <th style={{ borderLeft: 'solid 1px black' }} scope="col" rowSpan={3}>
                    유저ID(이름/닉네임)
                  </th>
                  {/* <th scope="col" rowSpan={3}>
                      회원수 [ 직영/하부 ]{' '}
                    </th> */}
                  <th scope="col" rowSpan={3}>
                    사이트명{' '}
                  </th>
                  <th scope="col" rowSpan={3}>
                    권한
                  </th>
                  <th scope="col" rowSpan={3}>
                    정산방식
                  </th>
                  {isActiveDefaultInfo && (
                    <th style={{ borderBottom: 'none' }} scope="col" colSpan={18}>
                      기본 정보
                    </th>
                  )}

                  {isActiveRollingInfo && (
                    <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={17}>
                      롤링 요율(%)
                    </RollingTh>
                  )}

                  {isActiveLosingInfo && (
                    <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={17}>
                      루징 요율(%)
                    </LosingTh>
                  )}
                </tr>
                <tr>
                  {isActiveDefaultInfo && (
                    <>
                      <th scope="col" rowSpan={2}>
                        지급
                      </th>
                      <th scope="col" rowSpan={2}>
                        회수
                      </th>
                      <th scope="col" rowSpan={2}>
                        지갑 머니
                      </th>
                      <th scope="col" rowSpan={2}>
                        카지노 머니
                      </th>
                      <th scope="col" rowSpan={2}>
                        홀덤 머니
                      </th>
                      <th scope="col" rowSpan={2}>
                        포인트
                      </th>
                      <th scope="col" rowSpan={2}>
                        루징 포인트
                      </th>
                      <th style={{ borderBottom: 'none' }} scope="col" colSpan={8}>
                        레벨
                      </th>
                      <th scope="col" rowSpan={2}>
                        로그아웃
                      </th>
                      <th scope="col" rowSpan={2}>
                        새로고침
                      </th>
                      <th scope="col" rowSpan={2}>
                        상태
                      </th>
                    </>
                  )}
                  {isActiveRollingInfo && (
                    <>
                      <RollingTh style={{ borderBottom: 'solid 1px black' }} scope="col" rowSpan={2}>
                        롤링 설정
                      </RollingTh>
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={2}>
                        카지노
                      </RollingTh>
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={2}>
                        미니게임
                      </RollingTh>
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={6}>
                        스포츠
                      </RollingTh>
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={5}>
                        가상게임
                      </RollingTh>
                      <RollingTh style={{ borderBottom: 'solid 1px black' }} scope="col" rowSpan={2}>
                        홀덤
                      </RollingTh>
                    </>
                  )}
                  {isActiveLosingInfo && (
                    <>
                      <LosingTh scope="col" rowSpan={2}>
                        루징 설정
                      </LosingTh>
                      <LosingTh scope="col" rowSpan={2}>
                        입금-출금
                      </LosingTh>
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={2}>
                        카지노
                      </LosingTh>
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={2}>
                        미니게임
                      </LosingTh>
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={6}>
                        스포츠
                      </LosingTh>
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={5}>
                        가상게임
                      </LosingTh>
                    </>
                  )}
                </tr>
                <tr>
                  {isActiveDefaultInfo && (
                    <>
                      <th scope="col">입금</th>
                      <th scope="col">슬롯</th>
                      <th scope="col">라이브</th>
                      <th scope="col">미니</th>
                      <th scope="col">스포츠</th>
                      <th scope="col">가상게임</th>
                      <th scope="col">홀덤</th>
                      <th scope="col">추천인</th>
                    </>
                  )}
                  {isActiveRollingInfo && (
                    <>
                      <RollingTh scope="col">슬롯</RollingTh>
                      <RollingTh scope="col">라이브</RollingTh>
                      <RollingTh scope="col">일반</RollingTh>
                      <RollingTh scope="col">조합</RollingTh>
                      <RollingTh scope="col">단폴</RollingTh>
                      <RollingTh scope="col">2폴</RollingTh>
                      <RollingTh scope="col">3폴</RollingTh>
                      <RollingTh scope="col">4폴</RollingTh>
                      <RollingTh scope="col">5폴</RollingTh>
                      <RollingTh scope="col">다폴</RollingTh>
                      <RollingTh scope="col">축구</RollingTh>
                      <RollingTh scope="col">야구</RollingTh>
                      <RollingTh scope="col">농구</RollingTh>
                      <RollingTh scope="col">경마</RollingTh>
                      <RollingTh scope="col">개경주</RollingTh>
                    </>
                  )}
                  {isActiveLosingInfo && (
                    <>
                      <LosingTh scope="col">슬롯</LosingTh>
                      <LosingTh scope="col">라이브</LosingTh>
                      <LosingTh scope="col">일반</LosingTh>
                      <LosingTh scope="col">조합</LosingTh>
                      <LosingTh scope="col">단폴</LosingTh>
                      <LosingTh scope="col">2폴</LosingTh>
                      <LosingTh scope="col">3폴</LosingTh>
                      <LosingTh scope="col">4폴</LosingTh>
                      <LosingTh scope="col">5폴</LosingTh>
                      <LosingTh scope="col">다폴</LosingTh>
                      <LosingTh scope="col">축구</LosingTh>
                      <LosingTh scope="col">야구</LosingTh>
                      <LosingTh scope="col">농구</LosingTh>
                      <LosingTh scope="col">경마</LosingTh>
                      <LosingTh scope="col">개경주</LosingTh>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {partnerManageList?.map((user, index) => (
                  <DisplayUserComponent
                    key={user.userKey}
                    selectedUserId={selectedUserId}
                    user={user}
                    toggleIsOpened={handleToggleIsOpened}
                    isActiveDefaultInfo={isActiveDefaultInfo}
                    isActiveRollingInfo={isActiveRollingInfo}
                    isActiveLosingInfo={isActiveLosingInfo}
                    setModalShow={setModalShow}
                    setModalUserId={setModalUserId}
                    setWhatModalShow={setWhatModalShow}
                  />
                ))}
              </tbody>
            </table>

            {partnerManageList.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
          </TableWrap>
        </CardBody>
        <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onModalClickHandler}
            />
            {
              {
                INFO: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저정보</CustomModalTitle>
                  </>
                ),
                ASSET: (
                  <>
                    <CustomModalTitle>
                      {isModalUserId} <span style={{ color: 'red', fontWeight: '900' }}>자산 지급</span>
                    </CustomModalTitle>
                  </>
                ),
                ASSET_DEC: (
                  <>
                    <CustomModalTitle>
                      {isModalUserId} <span style={{ color: 'blue', fontWeight: '900' }}>자산 회수 </span>{' '}
                    </CustomModalTitle>
                  </>
                ),
                ROLLING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 롤링정보 수정</CustomModalTitle>
                  </>
                ),
                LOSING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 루징정보 수정</CustomModalTitle>
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalHeader>
          <CustomModalBody>
            {
              {
                ASSET: (
                  <>
                    <MemberHistoryMoneyIncDecV2 memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                ASSET_DEC: (
                  <>
                    <MemberHistoryMoneyDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                ROLLING: (
                  <>
                    <MemberRollingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                LOSING: (
                  <>
                    <MemberLosingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalBody>
        </CustomModal>
      </Card>
    </>
  )
}

export default PartnerList

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`

const CustomButton = styled.button`
  cursor: pointer;
  border: none;
  color: #fff;
`

const CustomTr = styled.tr`
  &:hover {
    background: ${props => (props.depth === 0 ? '#fff2d3' : '#ebebeb')} !important;
  }
`

const RollingTh = styled.th`
  background: #fcb65b !important;
`

const LosingTh = styled.th`
  background: #aab6f5 !important;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  ${scrollbarStyles};
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const PlusSquare = styled(AiOutlinePlusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  margin: 0 2px;
`

const MinusSquare = styled(AiOutlineMinusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  margin: 0 2px;
`
const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`

const TableColumnSearchButtonBox = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  margin-left: 20px;
  button {
    height: 30px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
