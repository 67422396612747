import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import { colorAdditional, colorFieldsBorder } from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { FormButtonToolbar } from '../../../shared/components/form/FormElements'
import { searchDepositLevel, updateDepositLevel } from '../../../utils/api/systemManage/levelManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { depositlevelValidate, depositlevelValidateV2 } from '../../../utils/validate/systemManagement/levelManage'

const DepositLevelFormV2 = ({ selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const [depositLevelName, setDepositLevelName] = useState('')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState('0')
  const [bonusNewFirstPer, setBonusNewFirstPer] = useState('0')
  const [bonusSportsFirstPer, setBonusSportsFirstPer] = useState('0')
  const [bonusSportsEveryPer, setBonusSportsEveryPer] = useState('0')
  const [bonusCasinoNewFirstPer, setBonusCasinoNewFirstPer] = useState('0')
  const [bonusFirstPer, setBonusFirstPer] = useState('0')
  const [bonusEveryPer, setBonusEveryPer] = useState('0')
  const [bonusSlotCasinoNewFirstPer, setBonusSlotCasinoNewFirstPer] = useState('0')
  const [bonusSlotFirstPer, setBonusSlotFirstPer] = useState('0')
  const [bonusSlotEveryPer, setBonusSlotEveryPer] = useState('0')
  const [bonusSportsMaxAmount, setBonusSportsMaxAmount] = useState('0')
  const [bonusSportsLimitAmount, setBonusSportsLimitAmount] = useState('0')
  const [bonusLiveCasinoMaxAmount, setBonusLiveCasinoMaxAmount] = useState('0')
  const [bonusLiveCasinoLimitAmount, setBonusLiveCasinoLimitAmount] = useState('0')
  const [bonusSlotCasinoMaxAmount, setBonusSlotCasinoMaxAmount] = useState('0')
  const [bonusSlotCasinoLimitAmount, setBonusSlotCasinoLimitAmount] = useState('0')
  const [bonusMiniGameNewFirstPer, setBonusMiniGameNewFirstPer] = useState('0')
  const [bonusMiniGameFirstPer, setBonusMiniGameFirstPer] = useState('0')
  const [bonusMiniGameEveryPer, setBonusMiniGameEveryPer] = useState('0')
  const [bonusMiniGameMaxAmount, setBonusMiniGameMaxAmount] = useState('0')
  const [bonusMiniGameLimitAmount, setBonusMiniGameLimitAmount] = useState('0')
  const [bonusVirtualGameNewFirstPer, setBonusVirtualGameNewFirstPer] = useState('0')
  const [bonusVirtualGameFirstPer, setBonusVirtualGameFirstPer] = useState('0')
  const [bonusVirtualGameEveryPer, setBonusVirtualGameEveryPer] = useState('0')
  const [bonusVirtualGameMaxAmount, setBonusVirtualGameMaxAmount] = useState('0')
  const [bonusVirtualGameLimitAmount, setBonusVirtualGameLimitAmount] = useState('0')
  const [payBackEventMaxAmount, setPayBackEventMaxAmount] = useState('0')
  const [payBackPer, setPayBackPer] = useState('0')
  const [bonusHoldemNewFirstPer, setBonusHoldemNewFirstPer] = useState('0')
  const [bonusHoldemFirstPer, setBonusHoldemFirstPer] = useState('0')
  const [bonusHoldemEveryPer, setBonusHoldemEveryPer] = useState('0')
  const [bonusHoldemMaxAmount, setBonusHoldemMaxAmount] = useState('0')
  const [bonusHoldemLimitAmount, setBonusHoldemLimitAmount] = useState('0')

  const fetchDepositLevel = () => {
    if (loading) return

    setLoading(true)

    searchDepositLevel({
      depositLevel: selectedLevel,
    })
      .then(res => {
        const {
          depositLevelName,
          depositMaxAmount,
          withdrawalMaxAmount,
          bonusNewFirstPer,
          bonusSportsFirstPer,
          bonusSportsEveryPer,
          bonusCasinoNewFirstPer,
          bonusFirstPer,
          bonusEveryPer,
          bonusSlotCasinoNewFirstPer,
          bonusSlotFirstPer,
          bonusSlotEveryPer,
          bonusSportsMaxAmount,
          bonusSportsLimitAmount,
          bonusLiveCasinoMaxAmount,
          bonusLiveCasinoLimitAmount,
          bonusSlotCasinoMaxAmount,
          bonusSlotCasinoLimitAmount,
          bonusMiniGameNewFirstPer,
          bonusMiniGameFirstPer,
          bonusMiniGameEveryPer,
          bonusMiniGameMaxAmount,
          bonusMiniGameLimitAmount,
          bonusVirtualGameNewFirstPer,
          bonusVirtualGameFirstPer,
          bonusVirtualGameEveryPer,
          bonusVirtualGameMaxAmount,
          bonusVirtualGameLimitAmount,
          payBackEventMaxAmount,
          payBackPer,
          bonusHoldemNewFirstPer,
          bonusHoldemFirstPer,
          bonusHoldemEveryPer,
          bonusHoldemMaxAmount,
          bonusHoldemLimitAmount,
        } = res.data

        setDepositLevelName(depositLevelName)
        setDepositMaxAmount(depositMaxAmount)
        setWithdrawalMaxAmount(withdrawalMaxAmount)
        setBonusNewFirstPer(bonusNewFirstPer)
        setBonusSportsFirstPer(bonusSportsFirstPer)
        setBonusSportsEveryPer(bonusSportsEveryPer)
        setBonusCasinoNewFirstPer(bonusCasinoNewFirstPer)
        setBonusFirstPer(bonusFirstPer)
        setBonusEveryPer(bonusEveryPer)
        setBonusSlotCasinoNewFirstPer(bonusSlotCasinoNewFirstPer)
        setBonusSlotFirstPer(bonusSlotFirstPer)
        setBonusSlotEveryPer(bonusSlotEveryPer)
        setBonusSportsMaxAmount(bonusSportsMaxAmount)
        setBonusSportsLimitAmount(bonusSportsLimitAmount)
        setBonusLiveCasinoMaxAmount(bonusLiveCasinoMaxAmount)
        setBonusLiveCasinoLimitAmount(bonusLiveCasinoLimitAmount)
        setBonusSlotCasinoMaxAmount(bonusSlotCasinoMaxAmount)
        setBonusSlotCasinoLimitAmount(bonusSlotCasinoLimitAmount)
        setBonusMiniGameNewFirstPer(bonusMiniGameNewFirstPer)
        setBonusMiniGameFirstPer(bonusMiniGameFirstPer)
        setBonusMiniGameEveryPer(bonusMiniGameEveryPer)
        setBonusMiniGameMaxAmount(bonusMiniGameMaxAmount)
        setBonusMiniGameLimitAmount(bonusMiniGameLimitAmount)
        setBonusVirtualGameNewFirstPer(bonusVirtualGameNewFirstPer)
        setBonusVirtualGameFirstPer(bonusVirtualGameFirstPer)
        setBonusVirtualGameEveryPer(bonusVirtualGameEveryPer)
        setBonusVirtualGameMaxAmount(bonusVirtualGameMaxAmount)
        setBonusVirtualGameLimitAmount(bonusVirtualGameLimitAmount)
        setPayBackEventMaxAmount(payBackEventMaxAmount)
        setPayBackPer(payBackPer)
        setBonusHoldemNewFirstPer(bonusHoldemNewFirstPer)
        setBonusHoldemFirstPer(bonusHoldemFirstPer)
        setBonusHoldemEveryPer(bonusHoldemEveryPer)
        setBonusHoldemMaxAmount(bonusHoldemMaxAmount)
        setBonusHoldemLimitAmount(bonusHoldemLimitAmount)
        setLoading(false)
      })
      .catch(error => {
        setDepositLevelName('')
        setDepositMaxAmount('0')
        setWithdrawalMaxAmount('0')
        setBonusNewFirstPer('0')
        setBonusSportsFirstPer('0')
        setBonusSportsEveryPer('0')
        setBonusCasinoNewFirstPer('0')
        setBonusFirstPer('0')
        setBonusEveryPer('0')
        setBonusSlotCasinoNewFirstPer('0')
        setBonusSlotFirstPer('0')
        setBonusSlotEveryPer('0')
        setBonusSportsMaxAmount('0')
        setBonusSportsLimitAmount('0')
        setBonusLiveCasinoMaxAmount('0')
        setBonusLiveCasinoLimitAmount('0')
        setBonusSlotCasinoMaxAmount('0')
        setBonusSlotCasinoLimitAmount('0')
        setPayBackEventMaxAmount('0')
        setBonusMiniGameNewFirstPer('0')
        setBonusMiniGameFirstPer('0')
        setBonusMiniGameEveryPer('0')
        setBonusMiniGameMaxAmount('0')
        setBonusMiniGameLimitAmount('0')
        setBonusVirtualGameNewFirstPer('0')
        setBonusVirtualGameFirstPer('0')
        setBonusVirtualGameEveryPer('0')
        setBonusVirtualGameMaxAmount('0')
        setBonusVirtualGameLimitAmount('0')
        setPayBackPer('0')
        setBonusHoldemNewFirstPer('0')
        setBonusHoldemFirstPer('0')
        setBonusHoldemEveryPer('0')
        setBonusHoldemMaxAmount('0')
        setBonusHoldemLimitAmount('0')
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchDepositLevel()
  }, [selectedLevel])

  const onSubmit = e => {
    if (writeLoading) return

    if (window.confirm('입금 레벨 설정을 변경 하시겠습니까? ')) {
      const body = {
        depositLevelName,
        depositMaxAmount,
        withdrawalMaxAmount,
        bonusNewFirstPer,
        bonusSportsFirstPer,
        bonusSportsEveryPer,
        bonusCasinoNewFirstPer,
        bonusFirstPer,
        bonusEveryPer,
        bonusSlotCasinoNewFirstPer,
        bonusSlotFirstPer,
        bonusSlotEveryPer,
        bonusSportsMaxAmount,
        bonusSportsLimitAmount,
        bonusLiveCasinoMaxAmount,
        bonusLiveCasinoLimitAmount,
        bonusSlotCasinoMaxAmount,
        bonusSlotCasinoLimitAmount,
        bonusMiniGameNewFirstPer,
        bonusMiniGameFirstPer,
        bonusMiniGameEveryPer,
        bonusMiniGameMaxAmount,
        bonusMiniGameLimitAmount,
        bonusVirtualGameNewFirstPer,
        bonusVirtualGameFirstPer,
        bonusVirtualGameEveryPer,
        bonusVirtualGameMaxAmount,
        bonusVirtualGameLimitAmount,
        payBackEventMaxAmount,
        payBackPer,
        bonusHoldemNewFirstPer,
        bonusHoldemFirstPer,
        bonusHoldemEveryPer,
        bonusHoldemMaxAmount,
        bonusHoldemLimitAmount,
      }

      setWriteLoading(true)

      updateDepositLevel(body)
        .then(res => {
          setWriteLoading(false)
          alert('입금 레벨 설정이 변경됐습니다.')
          fetchDepositLevel()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('입금 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  return (
    <>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '100%', padding: '10px 10px' }}>
        <Form
          onSubmit={onSubmit}
          validate={depositlevelValidateV2}
          initialValues={{
            depositLevelName,
            depositMaxAmount,
            withdrawalMaxAmount,
            payBackEventMaxAmount,
            payBackPer,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel style={{ color: '#996b45' }}>1회 입금최대액(원)</FormGroupLabel>
                <FormGroupField>
                  <Field name="depositMaxAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column' }}>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              setDepositMaxAmount(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>원</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ color: '#996b45' }}>1회 출금최대액(원)</FormGroupLabel>
                <FormGroupField>
                  <Field name="withdrawalMaxAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column' }}>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              setWithdrawalMaxAmount(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>원</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ color: 'orange' }}>페이백 (%)</FormGroupLabel>
                <FormGroupField>
                  <Field name="payBackPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column' }}>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              setPayBackPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel style={{ color: 'orange' }}>페이백 최대액</FormGroupLabel>
                <FormGroupField>
                  <Field name="payBackEventMaxAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column' }}>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              setPayBackEventMaxAmount(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>원</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              {(authority === 'SUPER' || authority === 'DB_DOWN') && (
                <FormButtonToolbar className="registrationFooter">
                  <Button variant="primary" type="submit">
                    변경하기
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </div>
    </>
  )
}

export default DepositLevelFormV2

DepositLevelFormV2.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 30%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
