import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import {
  deleteMoneyChangeLog,
  searchMemberMoneyChangeLog,
  useSearchMemberMoneyChangeLog,
} from '../../../utils/api/moneyChangeLog/moneyChangeLogApi'
import { decodeAccessToken } from '../../../utils/token'
import LogPageTabs from '../LogPageTabs'
import MoneyChangeLogListData from './MoneyChangeLogListData'
import MoneySortSearchButton from '../../../shared/components/MoneySortSearchButton'
import { MoneyChangeLogSortEnumsSelectOptions } from '../../../enums/Log/MoneyChangeLogEnums'

const MoneyChangeLogList = () => {
  const authority = decodeAccessToken()?.adminAuthority

  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const [moneySort, setMoneySort] = useState(null)

  /**
   * 회원 머니이동내역 조회
   */
  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
    authority: null,
    memberId: null,
    executeStartDate: null,
    executeEndDate: null,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      moneyChangeLogAssetType: !moneySort ? null : moneySort,
    }))
  }, [moneySort])

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyChangeLog = async () => {
    if (loading) return
    setLoading(true)
    await searchMemberMoneyChangeLog(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyChangeLog()
  }, [params])

  const [checkRadio, setCheckRadio] = useState([])

  const [moneyExchangeListData, setMoneyChangeLogListData] = useState(
    MoneyChangeLogListData(content, checkRadio, setCheckRadio),
  )

  useEffect(() => {
    setMoneyChangeLogListData(MoneyChangeLogListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(moneyExchangeListData.tableRowsData)

  useEffect(() => {
    setData(moneyExchangeListData.tableRowsData)
  }, [moneyExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }
  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = MoneyChangeLogSortEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchChangeContent, setSearchChangeContent] = useState('')

  const onSearchChangeContentHandler = e => {
    setSearchChangeContent(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      executeStartDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      executeEndDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
      moneyChangeLogSort: !searchGameType ? null : searchGameType,
      changeContent: !searchChangeContent ? null : searchChangeContent,
      page: 0,
    }))
  }

  const fetchSearchMoneyChangeLogList = async () => {
    if (loading) return

    setLoading(true)
    await searchMemberMoneyChangeLog(params)
      .then(res => {
        content(res.data.content)
        totalElement(res.data.totalElement)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [activeKey, setActiveKey] = useState('3')
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMoneyChangeLog({
        moneyChangeLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyChangeLog()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyChangeLog()
        })
    }
  }

  const deleteAllHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return

    if (window.confirm('머니이동내역 전체를 정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMoneyChangeLog({
        moneyChangeLogKeyList: [],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyChangeLog()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyChangeLog()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <LogPageTabs activeKey={activeKey} />
        {(deleteLoading || loading) && (
          <>
            <Loading />
          </>
        )}
        <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <MoneySortSearchButton
            moneySort={moneySort}
            onClickHandler={e => {
              setMoneySort(e.target.id)
            }}
          />
        </div>
        <SearchContainer horizontal>
          {/* <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup> */}
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>변경내용</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchChangeContent}
                  onChange={e => onSearchChangeContentHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ backgroundColor: 'lightGreen' }}
              variant="outline-secondary"
              onClick={() => {
                setCheckRadio(content.map(value => value.moneyExchangeKey))
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              style={{ backgroundColor: '#ff4f87', color: '#fff' }}
              variant="outline-secondary"
              onClick={() => {
                onDeleteHandler()
              }}
            >
              삭제하기
            </Button>
            <Button
              size="sm"
              style={{ backgroundColor: 'black', fontWeight: '600', color: 'red', marginLeft: '120px' }}
              variant="outline-secondary"
              onClick={() => {
                deleteAllHandler()
              }}
            >
              전체삭제
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase
          key="common"
          columns={moneyExchangeListData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
        />
      </CardBody>
    </Card>
  )
}

export default MoneyChangeLogList
