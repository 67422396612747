import { Button } from '@/shared/components/Button'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import {
  CustomCheckboxCustom,
  CustomCheckboxInput,
  CustomCheckboxLabel,
  CustomCheckboxWrap,
} from '@/shared/components/CustomCheckBox'
import { MemberResterBankSelect, PartnerRegisterSelect } from '@/shared/components/CustomSelect'
import Error from '@/shared/components/form/Error'
import {
  CustomFormButtonToolbar,
  FormContainer,
  FormFieldButton,
  FormGroupIcon,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
  PartnerRegisterFormGroupIcon,
} from '@/shared/components/form/FormElements'
import CheckIcon from 'mdi-react/CheckIcon'
import { useHistory } from 'react-router-dom'
import EyeIcon from 'mdi-react/EyeIcon'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import ko from 'date-fns/locale/ko'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../../../utils/palette'
import { convertToKst } from '../../../utils/dateTime'
import Loading from '../../../shared/components/Loading'
import { searchBankList } from '../../../utils/api/memberManage/memberManageApi'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'
import { Card, CardBody, SearchCardTitleWrap } from '../../../shared/components/Card'
import { UserAuthoritySelectOptions } from '../../../enums/authority'
import { registerPartner, searchPartners } from '../../../utils/api/partnerManage/partnerRegisterApi'
import {
  MemberCasinoLevelEnumSelectOptions,
  MemberDepositLevelEnumSelectOptions,
  MemberFriendCompLevelEnumSelectOptions,
  MemberHoldemLevelEnumSelectOptions,
  MemberMiniGameLevelEnumSelectOptions,
  MemberSportsLevelEnumSelectOptions,
  MemberVirtualGameLevelEnumSelectOptions,
} from '../../../enums/MemberManage/MemberInfoEnums'
import { downPartnerRegisterValidate } from '../../../utils/validate/partnerManage/partnerRegisterValidate'
import {
  UserExchangeDateEnumsRadioOptions,
  UserRangeAutoStatusEnumsRadioOptions,
  UserSettingAutoStatusEnumsRadioOptions,
  UserSettingAutoStatusReverseEnumsRadioOptions,
} from '../../../enums/SystemManagement/DefaultSettingEnums'
import { searchDefaultBettingManage } from '../../../utils/api/systemManage/systemManageApi'

const UserRegistrationPage = () => {
  const history = useHistory()
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const authority = decodeAccessToken()?.adminAuthority
  const [passwordShown, setPasswordShown] = useState(false)
  const [telecomOptionValue, setTelecomOptionValue] = useState('')
  const [telecomOptions, setTelecomOptions] = useState([])
  const onChangeTelecomHandler = event => {
    const selectedOption = telecomOptions.find(option => option.value === event.target.value)
    setTelecomOptionValue(selectedOption)
  }
  const [registrationLoading, setRegistrationLoading] = useState(false)
  const [newRecommendedCode, setNewRecommendedCode] = useState(null)
  const [partnerId, setPartnerId] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [partnerPassword, setPartnerPassword] = useState('')
  const [nickName, setNickName] = useState('')
  const [userBirthDayFirst, setUserBirthDayFirst] = useState('')
  const [userBirthDaySecond, setUserBirthDaySecond] = useState('')
  const [topPartnerOptionValue, setTopPartnerOptionValue] = useState(null)
  const [topPartnerOption, setTopPartnerOption] = useState([])
  const onChangeTopPartnerHandler = option => {
    if (option == null) {
      setTopPartnerOptionValue(null)
      return
    }
    setTopPartnerOptionValue(option)
  }

  const [phoneNum, setPhoneNum] = useState('')

  // 예금주
  const [accountHolder, setAccountHolder] = useState('')

  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  const [friendCompLevel, setFriendCompLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [friendCompLevelOption, setFriendCompLevelOption] = useState(MemberFriendCompLevelEnumSelectOptions())
  const onChangeFriendCompLevelHandler = option => {
    if (option == null) {
      setFriendCompLevel(null)
      return
    }

    setFriendCompLevel(option)
  }

  const [depositLevel, setDepositLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [depositLevelOption, setDepositLevelOption] = useState(MemberDepositLevelEnumSelectOptions())
  const onChangeDepositLevelHandler = option => {
    if (option == null) {
      setDepositLevel(null)
      return
    }

    setDepositLevel(option)
  }

  // 레벨
  const [casinoLiveLevel, setCasinoLiveLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [casinoLiveLevelOption, setCasinoLiveLevelOption] = useState(MemberCasinoLevelEnumSelectOptions())
  const onChangeCasinoLiveLevelHandler = option => {
    if (option == null) {
      setCasinoLiveLevel(null)
      return
    }

    setCasinoLiveLevel(option)
  }

  const [casinoSlotLevel, setCasinoSlotLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [casinoSlotLevelOption, setCasinoSlotLevelOption] = useState(MemberCasinoLevelEnumSelectOptions())
  const onChangeCasinoSlotLevelHandler = option => {
    if (option == null) {
      setCasinoSlotLevel(null)
      return
    }

    setCasinoSlotLevel(option)
  }

  const [miniGameLevel, setMiniGameLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [miniGameLevelOption, setMiniGameLevelOption] = useState(MemberMiniGameLevelEnumSelectOptions())
  const onChangeMiniGameLevelHandler = option => {
    if (option == null) {
      setMiniGameLevel(null)
      return
    }

    setMiniGameLevel(option)
  }
  const [sportsLevel, setSportsLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [sportsLevelOption, setSportsLevelOption] = useState(MemberSportsLevelEnumSelectOptions())
  const onChangeSportsLevelHandler = option => {
    if (option == null) {
      setSportsLevel(null)
      return
    }

    setSportsLevel(option)
  }

  const [virtualGameLevel, setVirtualGameLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [virtualGameLevelOption, setVirtualGameLevelOption] = useState(MemberVirtualGameLevelEnumSelectOptions())
  const onChangeVirtualGameLevelHandler = option => {
    if (option == null) {
      setVirtualGameLevel(null)
      return
    }

    setVirtualGameLevel(option)
  }

  const [holdemLevel, setHoldemLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [holdemLevelOption, setHoldemLevelOption] = useState(MemberHoldemLevelEnumSelectOptions())
  const onChangeHoldemLevelHandler = option => {
    if (option == null) {
      setHoldemLevel(null)
      return
    }

    setHoldemLevel(option)
  }

  // 유저 타입
  const [userAuthority, setUserAuthority] = useState(null)
  const [userAuthorityOption, setUserAuthorityOption] = useState(UserAuthoritySelectOptions())
  const onChangeUserAuthorityHandler = option => {
    if (option == null) {
      setUserAuthority(null)
      return
    }

    setUserAuthority(option)
  }

  // 사이트명
  const [edgeName, setEdgeName] = useState(EdgeNameSelect.length > 0 ? EdgeNameSelect[0] : null)

  const onChangeEdgeNameOptionHandler = option => {
    if (option === null) {
      setEdgeName(null)
      return
    }
    setEdgeName(option)
  }

  useEffect(() => {
    setEdgeName(EdgeNameSelect.length > 0 ? EdgeNameSelect[0] : null)
  }, [EdgeNameSelect])

  const [recommendCodeStatus, setRecommendCodeStatus] = useState(false)

  // 테스트 유저 설정
  const [tester, setTester] = useState(false)
  const [content, setContent] = useState([])

  const fetchSearchDefaultBettingManage = async () => {
    await searchDefaultBettingManage({ edgeName: edgeName?.label }).then(res => {
      setContent(res.data.content)
    })
  }

  // 상위 본사는 무조건 여기서
  useEffect(() => {
    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })

    setTelecomOptions([
      { value: 'LG', label: 'LG' },
      { value: 'SKT', label: 'SKT' },
      { value: 'KT', label: 'KT' },
      { value: 'LG_PLUS', label: 'LG 알뜰폰' },
      { value: 'SKT_PLUS', label: 'SKT 알뜰폰' },
      { value: 'KT_PLUS', label: 'KT 알뜰폰' },
    ])
  }, [])

  useEffect(() => {
    if (!edgeName) return
    searchPartners({ edgeName: edgeName.label }).then(res => {
      const partners = res.data.partners.map(item => ({
        value: item.userId,
        label: `${item.userId} (${item.userName}) [ ${item.partnerLevel}LV ]`,
      }))

      setTopPartnerOption(partners)
    })
    fetchSearchDefaultBettingManage()
  }, [edgeName])

  const [defaultManageData, setDefaultManageData] = useState(content)

  const [userSkinNum, setUserSkinNum] = useState(null)
  useEffect(() => {
    setUserSkinNum(content.userSkinNum)
    setDefaultManageData(content)
  }, [content])

  const onSubmit = e => {
    if (registrationLoading) return
    if (!userSkinNum) {
      alert('스킨번호를 입력해주세요.')
      return
    }
    const body = {
      edgeName: e.edgeName.label,
      userAuthority: e.userAuthority.value,
      topPartnerId: !e.topPartner ? null : e.topPartner.value,
      userId: e.partnerId,
      nickName: e.nickName,
      userBirthDayFirst,
      userBirthDaySecond,
      userPassword: e.partnerPassword,
      userPhoneNum: phoneNum || '',
      accountHolder: e.accountHolder,
      accountNum: accountNum || '',
      bankName: bankNameOptionValue?.label || '',
      depositLevel: e.depositLevel.value,
      casinoLiveLevel: e.casinoLiveLevel.value,
      casinoSlotLevel: e.casinoSlotLevel.value,
      miniGameLevel: e.miniGameLevel.value,
      sportsLevel: e.sportsLevel.value,
      virtualGameLevel: e.virtualGameLevel.value,
      holdemLevel: e.holdemLevel.value,
      friendCompLevel: e.friendCompLevel.value,
      recommendCodeStatus: e.recommendCodeStatus,
      recommendedCode: !e.newRecommendedCode ? null : e.newRecommendedCode.trim(),
      tester,
      userLosingPointExchangeAutoStatus: e.userLosingPointExchangeAutoStatus,
      depositBonusStatus: e.depositBonusStatus,
      userExchangeDateStatus: e.userExchangeDateStatus,
      settlementExchangeStatus: e.settlementExchangeStatus,
      moneyIncExchangeStatus: e.moneyIncExchangeStatus,
      moneyDecExchangeStatus: e.moneyDecExchangeStatus,
      duplicatedSignInStatus: e.duplicatedSignInStatus,
      userSkinNum,
    }

    if (window.confirm(`${e.accountHolder} 유저를 가입 하시겠습니까?`)) {
      setRegistrationLoading(true)
      registerPartner(body)
        .then(res => {
          setRegistrationLoading(false)
          alert(`${e.accountHolder} 유저 등록이 성공했습니다.`)
          window.location.reload()
        })
        .catch(error => {
          setRegistrationLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SIGN-1002') {
            alert(`${message}`)
          } else {
            alert(`${e.accountHolder} 유저 등록이 실패했습니다.`)
          }
        })
    }
  }

  return (
    <>
      {registrationLoading ? (
        <Loading />
      ) : (
        <>
          <Card>
            <CardBody>
              <MemberManageMentTabs activeKey="4" />
              <MiniGameTypeSelectWrapper>
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active
                  onClick={() => {
                    history.push('/user/management/registration')
                  }}
                >
                  유저 등록(개별)
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    history.push('/user/management/registration-excel')
                  }}
                >
                  유저 등록(엑셀)
                </Button>
              </MiniGameTypeSelectWrapper>
              <Col md={12} lg={12} xl={12}>
                <SearchCardTitleWrap>
                  <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <p>
                      * 파트너 생성시 <span style={{ color: 'blue' }}>상위 파트너</span>를 선택 안하면, 1LV (루트)
                      파트너로 등록됩니다.
                    </p>
                    <p>
                      * 관리자가 <span style={{ fontWeight: 'bolder' }}>유저 등록시</span> 바로 승인처리 됩니다.
                    </p>
                  </div>
                </SearchCardTitleWrap>
                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    topPartner: topPartnerOptionValue,
                    partnerId,
                    partnerPassword,
                    userAuthority,
                    depositLevel,
                    casinoLiveLevel,
                    casinoSlotLevel,
                    miniGameLevel,
                    sportsLevel,
                    virtualGameLevel,
                    holdemLevel,
                    friendCompLevel,
                    edgeName,
                    newRecommendedCode,
                    nickName,
                    accountHolder,
                    userBirthDayFirst,
                    userBirthDaySecond,
                    tester,
                    userSkinNum: defaultManageData?.userSkinNum,
                    userLosingPointExchangeAutoStatus: defaultManageData?.userLosingPointExchangeAutoStatus,
                    depositBonusStatus: defaultManageData?.depositBonusStatus,
                    recommendCodeStatus: defaultManageData?.recommendCodeStatus,
                    userExchangeDateStatus: defaultManageData?.userExchangeDateStatus,
                    settlementExchangeStatus: defaultManageData?.settlementExchangeStatus,
                    moneyIncExchangeStatus: defaultManageData?.moneyIncExchangeStatus,
                    moneyDecExchangeStatus: defaultManageData?.moneyDecExchangeStatus,
                    duplicatedSignInStatus: defaultManageData?.duplicatedSignInStatus,
                  }}
                  validate={downPartnerRegisterValidate}
                >
                  {({ handleSubmit, form: { reset } }) => (
                    <FormContainer horizontal onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>사이트</FormGroupLabel>
                        <FormGroupField>
                          <Field name="edgeName">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={edgeName}
                                  onChange={option => {
                                    onChangeEdgeNameOptionHandler(option)
                                  }}
                                  options={EdgeNameSelect}
                                  placeholder="사이트를 선택하세요"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>유저 타입</FormGroupLabel>
                        <FormGroupField>
                          <Field name="userAuthority">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={userAuthority}
                                  onChange={option => {
                                    onChangeUserAuthorityHandler(option)
                                  }}
                                  options={userAuthorityOption}
                                  placeholder="유저 타입을 선택하세요"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>상위 파트너</FormGroupLabel>
                        <FormGroupField>
                          <Field name="topPartner">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <MemberResterBankSelect
                                  {...input}
                                  value={topPartnerOptionValue}
                                  onChange={option => {
                                    onChangeTopPartnerHandler(option)
                                  }}
                                  options={topPartnerOption}
                                  placeholder="상위 파트너 선택"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                        <CustomFormGroupDescription>
                          1LV (루트) 파트너 등록 시에는 선택하지 마세요.
                        </CustomFormGroupDescription>
                      </FormGroup>

                      <FormGroup>
                        <FormGroupLabel>유저 아이디</FormGroupLabel>
                        <FormGroupField>
                          <Field name="partnerId">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  style={{ border: 'solid 1px black' }}
                                  value={partnerId}
                                  autoComplete="new-password"
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                                    if (onlyEnglish.length > 15) {
                                      return
                                    }
                                    setPartnerId(onlyEnglish)
                                  }}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                        <CustomFormGroupDescription>
                          15글자 미만 영문 및 숫자의 조합만 가능합니다.
                        </CustomFormGroupDescription>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>비밀번호</FormGroupLabel>
                        <FormGroupField>
                          <Field name="partnerPassword">
                            {({ input, meta }) => (
                              <>
                                <FormInputWrap>
                                  <input
                                    {...input}
                                    type="text"
                                    style={{ border: 'solid 1px black' }}
                                    inputMode="latin"
                                    value={partnerPassword}
                                    autoComplete="new-password"
                                    onChange={e => {
                                      setPartnerPassword(e.target.value)
                                    }}
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </FormInputWrap>
                              </>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>닉네임</FormGroupLabel>
                        <FormGroupField>
                          <Field name="nickName">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={nickName}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    setNickName(e.target.value)
                                  }}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>생년월일</FormGroupLabel>
                        <FormGroupField style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <div
                            style={{
                              width: '30%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Field name="userBirthDayFirst">
                              {({ input, meta }) => (
                                <div
                                  style={{
                                    width: '70%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                  }}
                                >
                                  <FormInputWrap>
                                    <input
                                      {...input}
                                      type="text"
                                      value={userBirthDayFirst}
                                      autoComplete="off"
                                      style={{ border: 'solid 1px black' }}
                                      onChange={e => {
                                        const { value } = e.target
                                        const onlyNumber = value.replace(/[^0-9]/g, '')
                                        if (onlyNumber.length > 6) {
                                          return
                                        }
                                        setUserBirthDayFirst(onlyNumber)
                                      }}
                                      placeholder="주민번호 앞 6자리"
                                    />
                                  </FormInputWrap>
                                  {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                                </div>
                              )}
                            </Field>
                            <span style={{ display: 'block', margin: '0 10px', fontSize: '20px' }}> - </span>
                            <Field name="userBirthDaySecond">
                              {({ input, meta }) => (
                                <div style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
                                  <FormInputWrap>
                                    <input
                                      {...input}
                                      type="text"
                                      value={userBirthDaySecond}
                                      autoComplete="off"
                                      style={{ border: 'solid 1px black' }}
                                      onChange={e => {
                                        const { value } = e.target
                                        const onlyNumber = value.replace(/[^0-9]/g, '')
                                        if (onlyNumber.length > 1) {
                                          return
                                        }
                                        setUserBirthDaySecond(onlyNumber)
                                      }}
                                    />
                                  </FormInputWrap>
                                </div>
                              )}
                            </Field>
                          </div>
                          <FormGroupDescription style={{ marginLeft: '0' }}>
                            * 주민번호 앞 6자리, 뒤 첫번째 숫자만 입력하세요
                          </FormGroupDescription>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>레벨</FormGroupLabel>
                        <FormGroupField style={{ gap: '15px' }}>
                          <Field name="depositLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={depositLevel}
                                  onChange={option => {
                                    onChangeDepositLevelHandler(option)
                                  }}
                                  options={depositLevelOption}
                                  placeholder="입금 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="casinoLiveLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={casinoLiveLevel}
                                  onChange={option => {
                                    onChangeCasinoLiveLevelHandler(option)
                                  }}
                                  options={casinoLiveLevelOption}
                                  placeholder="라이브 카지노 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="casinoSlotLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={casinoSlotLevel}
                                  onChange={option => {
                                    onChangeCasinoSlotLevelHandler(option)
                                  }}
                                  options={casinoSlotLevelOption}
                                  placeholder="슬롯 카지노 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="miniGameLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={miniGameLevel}
                                  onChange={option => {
                                    onChangeMiniGameLevelHandler(option)
                                  }}
                                  options={miniGameLevelOption}
                                  placeholder="미니게임 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="sportsLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={sportsLevel}
                                  onChange={option => {
                                    onChangeSportsLevelHandler(option)
                                  }}
                                  options={sportsLevelOption}
                                  placeholder="스포츠 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="virtualGameLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={virtualGameLevel}
                                  onChange={option => {
                                    onChangeVirtualGameLevelHandler(option)
                                  }}
                                  options={virtualGameLevelOption}
                                  placeholder="가상게임 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="holdemLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={holdemLevel}
                                  onChange={option => {
                                    onChangeHoldemLevelHandler(option)
                                  }}
                                  options={holdemLevelOption}
                                  placeholder="홀덤 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="friendCompLevel">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={friendCompLevel}
                                  onChange={option => {
                                    onChangeFriendCompLevelHandler(option)
                                  }}
                                  options={friendCompLevelOption}
                                  placeholder="추천인 레벨 "
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                        <CustomFormGroupDescription>
                          (입금 / 라이브 / 슬롯 / 미니게임 / 스포츠 / 가상게임 / 홀덤 / 추천인)
                        </CustomFormGroupDescription>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>연락처</FormGroupLabel>
                        <FormGroupField>
                          <Field name="phoneNum">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={phoneNum}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setPhoneNum(onlyNumber)
                                  }}
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>예금주(이름)</FormGroupLabel>
                        <FormGroupField>
                          <Field name="accountHolder">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={accountHolder}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    setAccountHolder(e.target.value)
                                  }}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>은행명</FormGroupLabel>
                        <FormGroupField>
                          <Field name="bankName">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <MemberResterBankSelect
                                  {...input}
                                  value={bankNameOptionValue}
                                  onChange={option => {
                                    // input.onChange(option)
                                    onChangeBankNameHandler(option)
                                  }}
                                  options={bankNameOption}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  placeholder="은행"
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>계좌번호</FormGroupLabel>
                        <FormGroupField>
                          <Field name="accountNum">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  style={{ border: 'solid 1px black' }}
                                  value={accountNum}
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setAccountNum(onlyNumber)
                                  }}
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>추천코드</FormGroupLabel>
                        <FormGroupField>
                          <Field name="newRecommendedCode">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  style={{ border: 'solid 1px black' }}
                                  value={input.value}
                                  onChange={e => {
                                    setNewRecommendedCode(e.target.value)
                                  }}
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                        <FormGroupDescription style={{ marginTop: '10px' }}>
                          * 유저 권한이 파트너일 경우,{' '}
                          <span style={{ color: 'blue', fontWeight: '600' }}>추천코드</span>로 가입한 회원은 해당 유저의
                          피추천인이 아니라, 직영회원이 됩니다. <br />* 유저 권한이 회원일 경우,{' '}
                          <span style={{ color: 'blue', fontWeight: '600' }}>추천코드</span>로 가입한 회원은 해당 유저의
                          피추천인이 됩니다.
                        </FormGroupDescription>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>기본값(1)</FormGroupLabel>
                        <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                          <CustomDiv>
                            <span>⦁ 스킨번호</span>
                            <div style={{ display: 'flex', border: 'solid 1px #000', gap: '5px' }}>
                              <Field name="userSkinNum">
                                {({ input, meta }) => (
                                  <>
                                    <FormInputWrap style={{ width: '60px' }}>
                                      <input
                                        {...input}
                                        type="text"
                                        value={userSkinNum}
                                        onChange={e => {
                                          const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                          setUserSkinNum(onlyNumber)
                                        }}
                                      />
                                      {meta.error && <Error error={meta.error} top />}
                                    </FormInputWrap>
                                    <FormGroupIcon style={{ width: '50px' }}>번</FormGroupIcon>
                                  </>
                                )}
                              </Field>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span>⦁ 입금보너스 허용</span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="depositBonusStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span>⦁ 추천코드 허용</span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="recommendCodeStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span>⦁ 중복로그인 허용 여부</span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="duplicatedSignInStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>기본값(2)</FormGroupLabel>
                        <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                          <CustomDiv>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                              ⦁ 하부 정산비율 수정
                              <br />
                              (파트너용)
                            </span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="settlementExchangeStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                              ⦁ 하부 머니증가 여부
                              <br />
                              (파트너용)
                            </span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="moneyIncExchangeStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                              ⦁ 하부 머니회수 여부
                              <br />
                              (파트너용)
                            </span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="moneyDecExchangeStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>기본값(3)</FormGroupLabel>
                        <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                          <CustomDiv>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                              ⦁ 정산금 전환여부
                              <br />
                              (파트너용)
                            </span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="userLosingPointExchangeAutoStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                          <CustomDiv>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                              ⦁ 정산일 여부
                              <br />
                              (파트너용)
                            </span>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                                {UserExchangeDateEnumsRadioOptions().map(item => (
                                  <FormGroupField
                                    key={`index_${item.label}`}
                                    style={{
                                      display: 'inline-block',
                                      width: 'max-content',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Field
                                      name="userExchangeDateStatus"
                                      component={renderRadioButtonField}
                                      label={item.label}
                                      radioValue={item.radioValue}
                                      initialValue={item.initialValue}
                                    />
                                  </FormGroupField>
                                ))}
                              </div>
                            </div>
                          </CustomDiv>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>테스트 유저 설정</FormGroupLabel>
                        <FormGroupField>
                          <Field name="tester" type="checkbox">
                            {({ input, meta }) => (
                              <CustomCheckboxWrap htmlFor="tester">
                                <CustomCheckboxInput
                                  {...input}
                                  type="checkbox"
                                  id="tester"
                                  checked={tester}
                                  onChange={e => {
                                    setTester(e.target.checked)
                                  }}
                                />
                                <CustomCheckboxCustom>
                                  <CheckIcon />
                                </CustomCheckboxCustom>
                                <CustomCheckboxLabel>* 테스트 유저 설정시 체크해주세요.</CustomCheckboxLabel>
                              </CustomCheckboxWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>

                      <CustomFormButtonToolbar>
                        {(authority === 'SUPER' || authority === 'DB_DOWN') && (
                          <Button variant="primary" type="submit">
                            등록하기
                          </Button>
                        )}
                      </CustomFormButtonToolbar>
                    </FormContainer>
                  )}
                </Form>
              </Col>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default UserRegistrationPage

const CustomDiv = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  & > span {
    width: max-content !important;
  }
  & > div {
    // border: solid 1px #000;
  }
`

const CustomFormGroupDescription = styled(FormGroupDescription)`
  font-size: 12px;
`
const DatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid #4a4a4a;
  z-index: 101;

  input {
    width: 100%;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: rgb(211, 211, 211);
    border: transparent;
    background: transparent;
    outline: none;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: lightgrey;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: black !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__month-dropdown-container {
    .react-datepicker__month-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__month-dropdown {
    border: 2px solid lightgrey;
    color: black;
  }

  .react-datepicker__year-dropdown {
    border: 2px solid lightgrey;
    color: black;

    .react-datepicker__navigation {
      top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
      display: none;
    }

    .react-datepicker__navigation--years-previous {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container {
    .react-datepicker__year-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  margin-bottom: -35px;

  display: flex;
  gap: 5px;
`
