import React from 'react'
import PropTypes from 'prop-types'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import Tooltip from '@/shared/components/Tooltip'
import ApproveDepositRadio from './ApproveDepositRadio'
import {
  DepositBounsTypeEnums,
  MemberBounsTypeEnums,
  MemberDepositStatusEnums,
  MemberDepositTypeEnums,
} from '../../../enums/MemberHistory/MemberDepositEnums'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberDepositInfo from './MemberDepositInfo/MemberDepositInfo'
import MemberBettingLogModal from '../../MemberManagement/MemberList/MemberInfo/MemberBettingLog/MemberBettingLogModal'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import DepositDeleteRadio from './DepositDeleteRadio'
import DepositMemo from './DepositMemo'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const DepositListData = (setCheckDepositApprove, content, checkRadio, setCheckRadio, fetchSearchMemberMoneyDeposit) => {
  const columns = [
    {
      Header: '승인',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <ApproveDepositRadio rowMemberInfo={original} setCheckDepositApprove={setCheckDepositApprove} />
      },
    },
    {
      Header: '삭제',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <DepositDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '직영 파트너ID(명)',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },

    {
      Header: '유저 ID(명/닉네임)',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName} / {original.nickName})
              </span>

              <span>
                <MemberDepositInfo memberId={original.memberId} moneyDepositKey={original.moneyDepositKey} />{' '}
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '휴대폰번호',
      accessor: 'userPhoneNum',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '은행명',
      accessor: 'userBankName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '계좌번호',
      accessor: 'userAccountNum',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '배팅로그',
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MemberBettingLogModal memberId={original.memberId} />
      },
    },
    {
      Header: '신청전 지갑머니',
      accessor: 'beforeMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청전 카지노머니',
      accessor: 'beforeCasinoMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeCasinoMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청전 홀덤머니',
      accessor: 'beforeHoldemMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeHoldemMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청액',
      accessor: 'applyMoneyMember',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.applyMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '첫충/매충',
      accessor: 'depositType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberDepositTypeEnums[original.depositType]
      },
    },
    {
      Header: '보너스타입',
      accessor: 'bonusType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return DepositBounsTypeEnums[original.bonusType]
      },
    },
    {
      Header: '보너스액',
      accessor: 'bonusMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bonusMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최종 지급액',
      accessor: 'paymentMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 75,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.paymentMoney === '0' ? '-' : original.paymentMoney.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청일',
      accessor: 'applyDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.applyDate)
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeDate)
      },
    },
    {
      Header: '노출여부',
      accessor: 'showStatus',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.showStatus) {
          return <span style={{ color: 'blue', fontWeight: '500' }}>노출</span>
        }
        return <span style={{ color: 'red', fontWeight: '500' }}>비노출</span>
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'APPROVE':
            return (
              <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.status]}
              </button>
            )
          case 'WAITING':
            return (
              <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.status]}
              </button>
            )
          case 'CANCEL':
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.status]}
              </button>
            )
          case 'APPLY':
            return (
              <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.status]}
              </button>
            )
          default:
            return (
              <button type="button" style={{ background: 'black', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.status]}
              </button>
            )
        }
      },
    },
    {
      Header: '메모',
      accessor: 'memo',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span>
              <DepositMemo
                memberId={original.memberId}
                moneyDepositKey={original.moneyDepositKey}
                memo={original.memo}
                fetchSearchMemberMoneyDeposit={fetchSearchMemberMoneyDeposit}
              />{' '}
            </span>
          </>
        )
      },
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default DepositListData

DepositListData.propTypes = {
  setCheckDepositApprove: PropTypes.func.isRequired,
  fetchSearchMemberMoneyDeposit: PropTypes.func.isRequired,
  rowMemberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    moneyDepositKey: PropTypes.string,
    memberName: PropTypes.string,
    type: PropTypes.string,
    applyMoney: PropTypes.string,
    applyDate: PropTypes.string,
    executeDate: PropTypes.string,
    bonsa: PropTypes.string,
    chongpan: PropTypes.string,
    maejang: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
