import { Button } from '@/shared/components/Button'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroupIcon,
  FormGroup,
  FormGroupField,
  FormGroupFile,
  FormInputWrap,
  FormGroupLabel,
  FormGroupDescription,
} from '@/shared/components/form/FormElements'
import { MemberResterBankSelect, PartnerRegisterSelect } from '@/shared/components/CustomSelect'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import Error from '@/shared/components/form/Error'
import ko from 'date-fns/locale/ko'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import styled from 'styled-components'
import Loading from '../../../../shared/components/Loading'
import { searchBankList } from '../../../../utils/api/memberManage/memberManageApi'
import { decodeAccessToken } from '../../../../utils/token'
import { memberNoteValidate } from '../../../../utils/validate/memberManage/partnerNoteValidate'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../../../../utils/palette'
import {
  MemberCasinoLevelEnumSelectOptions,
  MemberDepositLevelEnumSelectOptions,
  MemberFriendCompLevelEnumSelectOptions,
  MemberHoldemLevelEnumSelectOptions,
  MemberMiniGameLevelEnumSelectOptions,
  MemberSportsLevelEnumSelectOptions,
  MemberVirtualGameLevelEnumSelectOptions,
} from '../../../../enums/MemberManage/MemberInfoEnums'
import {
  UserExchangeDateEnumsRadioOptions,
  UserRangeAutoStatusEnumsRadioOptions,
  UserSettingAutoStatusEnumsRadioOptions,
  UserSettingAutoStatusReverseEnumsRadioOptions,
} from '../../../../enums/SystemManagement/DefaultSettingEnums'
import { UserAuthoritySelectOptions } from '../../../../enums/authority'
import { registerPartnerAll } from '../../../../utils/api/partnerManage/partnerRegisterApi'
import { convertToKst } from '../../../../utils/dateTime'
import { multiUserRegisterValidate } from '../../../../utils/validate/partnerManage/partnerRegisterValidate'
import { searchDefaultBettingManage } from '../../../../utils/api/systemManage/systemManageApi'

function RegistrationDownUserForm({ userInfo, onClickHandler }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [loading, setLoading] = useState(false)

  const [partnerId, setPartnerId] = useState('')
  const [partnerPassword, setPartnerPassword] = useState('')
  const [nickName, setNickName] = useState('')
  const [userBirthDay, setUserBirthDay] = useState('')
  const [phoneNum, setPhoneNum] = useState('')
  const [userSkinNum, setUserSkinNum] = useState(5)
  // 예금주
  const [accountHolder, setAccountHolder] = useState('')

  // 계좌번호
  const [accountNum, setAccountNum] = useState('')
  const [multiUserChecked, setMultiUserChecked] = useState(false) // 체크박스 상태
  const handleCheckboxClick = () => {
    setMultiUserChecked(prev => !prev) // 체크박스 상태 토글
  }

  const [multiUserRange, setMultiUserRange] = useState(null)
  const [multiUserDigit, setMultiUserDigit] = useState(null)

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  const [friendCompLevel, setFriendCompLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [friendCompLevelOption, setFriendCompLevelOption] = useState(MemberFriendCompLevelEnumSelectOptions())
  const onChangeFriendCompLevelHandler = option => {
    if (option == null) {
      setFriendCompLevel(null)
      return
    }

    setFriendCompLevel(option)
  }

  const [depositLevel, setDepositLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [depositLevelOption, setDepositLevelOption] = useState(MemberDepositLevelEnumSelectOptions())
  const onChangeDepositLevelHandler = option => {
    if (option == null) {
      setDepositLevel(null)
      return
    }

    setDepositLevel(option)
  }

  // 레벨
  const [casinoLiveLevel, setCasinoLiveLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [casinoLiveLevelOption, setCasinoLiveLevelOption] = useState(MemberCasinoLevelEnumSelectOptions())
  const onChangeCasinoLiveLevelHandler = option => {
    if (option == null) {
      setCasinoLiveLevel(null)
      return
    }

    setCasinoLiveLevel(option)
  }

  const [casinoSlotLevel, setCasinoSlotLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [casinoSlotLevelOption, setCasinoSlotLevelOption] = useState(MemberCasinoLevelEnumSelectOptions())
  const onChangeCasinoSlotLevelHandler = option => {
    if (option == null) {
      setCasinoSlotLevel(null)
      return
    }

    setCasinoSlotLevel(option)
  }

  const [miniGameLevel, setMiniGameLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [miniGameLevelOption, setMiniGameLevelOption] = useState(MemberMiniGameLevelEnumSelectOptions())
  const onChangeMiniGameLevelHandler = option => {
    if (option == null) {
      setMiniGameLevel(null)
      return
    }

    setMiniGameLevel(option)
  }
  const [sportsLevel, setSportsLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [sportsLevelOption, setSportsLevelOption] = useState(MemberSportsLevelEnumSelectOptions())
  const onChangeSportsLevelHandler = option => {
    if (option == null) {
      setSportsLevel(null)
      return
    }

    setSportsLevel(option)
  }

  const [virtualGameLevel, setVirtualGameLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [virtualGameLevelOption, setVirtualGameLevelOption] = useState(MemberVirtualGameLevelEnumSelectOptions())
  const onChangeVirtualGameLevelHandler = option => {
    if (option == null) {
      setVirtualGameLevel(null)
      return
    }

    setVirtualGameLevel(option)
  }

  const [holdemLevel, setHoldemLevel] = useState({
    value: 'ONE',
    label: '1LV',
  })
  const [holdemLevelOption, setHoldemLevelOption] = useState(MemberHoldemLevelEnumSelectOptions())
  const onChangeHoldemLevelHandler = option => {
    if (option == null) {
      setHoldemLevel(null)
      return
    }

    setHoldemLevel(option)
  }

  // 유저 타입
  const [userAuthority, setUserAuthority] = useState(null)
  const [userAuthorityOption, setUserAuthorityOption] = useState(UserAuthoritySelectOptions())
  const onChangeUserAuthorityHandler = option => {
    if (option == null) {
      setUserAuthority(null)
      return
    }

    setUserAuthority(option)
  }

  const [content, setContent] = useState([])

  const fetchSearchDefaultBettingManage = async () => {
    await searchDefaultBettingManage({ edgeName: userInfo.edgeName }).then(res => {
      setContent(res.data.content)
    })
  }
  const [defaultManageData, setDefaultManageData] = useState(content)

  useEffect(() => {
    setUserSkinNum(content.userSkinNum)
    setDefaultManageData(content)
  }, [content])

  // 상위 본사는 무조건 여기서
  useEffect(() => {
    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })
    fetchSearchDefaultBettingManage()
  }, [])

  const onSubmit = e => {
    if (loading) return
    if (multiUserChecked && (!multiUserDigit || !multiUserRange)) {
      alert('다중생성 시, 값을 다시 확인해주세요.')
      return
    }

    if (multiUserRange > 50) {
      alert('50아래로 값 세팅이 가능합니다.')
      return
    }

    if (multiUserChecked && (multiUserDigit > 3 || multiUserDigit < 1)) {
      alert('자릿수는 1자리부터 3자리까지 가능합니다.')
      return
    }

    if (!userSkinNum) {
      alert('스킨번호를 입력해주세요.')
      return
    }

    const body = {
      multiUserChecked,
      multiUserDigit: !multiUserDigit ? null : multiUserDigit,
      multiUserRange: !multiUserRange ? null : multiUserRange,
      userAuthority: e.userAuthority.value,
      topPartnerId: userInfo.userId,
      userId: e.partnerId,
      nickName: e.nickName,
      userBirthDay: !userBirthDay ? null : convertToKst(userBirthDay),
      userPassword: e.partnerPassword,
      userPhoneNum: phoneNum || '',
      accountHolder: e.accountHolder,
      accountNum: accountNum || '',
      bankName: bankNameOptionValue?.label || '',
      depositLevel: e.depositLevel.value,
      casinoLiveLevel: e.casinoLiveLevel.value,
      casinoSlotLevel: e.casinoSlotLevel.value,
      miniGameLevel: e.miniGameLevel.value,
      sportsLevel: e.sportsLevel.value,
      virtualGameLevel: e.virtualGameLevel.value,
      holdemLevel: e.holdemLevel.value,
      friendCompLevel: e.friendCompLevel.value,
      userLosingPointExchangeAutoStatus: e.userLosingPointExchangeAutoStatus,
      depositBonusStatus: e.depositBonusStatus,
      recommendCodeStatus: e.recommendCodeStatus,
      userExchangeDateStatus: e.userExchangeDateStatus,
      settlementExchangeStatus: e.settlementExchangeStatus,
      moneyIncExchangeStatus: e.moneyIncExchangeStatus,
      moneyDecExchangeStatus: e.moneyDecExchangeStatus,
      duplicatedSignInStatus: e.duplicatedSignInStatus,
      userSkinNum,
    }

    if (window.confirm(`${userInfo.userId}밑의 하부 유저를 등록 하시겠습니까?`)) {
      setLoading(true)
      registerPartnerAll(body)
        .then(res => {
          setLoading(false)
          alert(`${e.accountHolder} 유저 등록이 성공했습니다.`)
          window.location.reload()
        })
        .catch(error => {
          setLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SIGN-1002') {
            alert(`${message}`)
          } else {
            alert(`${e.accountHolder} 유저 등록이 실패했습니다.`)
          }
        })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              partnerId,
              partnerPassword,
              userAuthority,
              nickName,
              accountHolder,
              multiUserChecked,
              multiUserDigit,
              multiUserRange,
              depositLevel,
              casinoLiveLevel,
              casinoSlotLevel,
              miniGameLevel,
              sportsLevel,
              virtualGameLevel,
              holdemLevel,
              friendCompLevel,
              userBirthDay,
              accountNum,
              userSkinNum: defaultManageData?.userSkinNum,
              userLosingPointExchangeAutoStatus: defaultManageData?.userLosingPointExchangeAutoStatus,
              depositBonusStatus: defaultManageData?.depositBonusStatus,
              recommendCodeStatus: defaultManageData?.recommendCodeStatus,
              userExchangeDateStatus: defaultManageData?.userExchangeDateStatus,
              settlementExchangeStatus: defaultManageData?.settlementExchangeStatus,
              moneyIncExchangeStatus: defaultManageData?.moneyIncExchangeStatus,
              moneyDecExchangeStatus: defaultManageData?.moneyDecExchangeStatus,
              duplicatedSignInStatus: defaultManageData?.duplicatedSignInStatus,
            }}
            validate={multiUserRegisterValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>상위 파트너</FormGroupLabel>
                  <FormGroupField>
                    <Field name="topUser">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={`${userInfo.userId}(${userInfo.userName}) - ${userInfo.partnerLevel}LV`}
                            disabled
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    유저 타입<CustomSpan>*</CustomSpan>
                  </FormGroupLabel>
                  <FormGroupField>
                    <Field name="userAuthority">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={userAuthority}
                            onChange={option => {
                              onChangeUserAuthorityHandler(option)
                            }}
                            options={userAuthorityOption}
                            placeholder="유저 타입을 선택하세요"
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    유저 아이디<CustomSpan>*</CustomSpan>
                  </FormGroupLabel>
                  <FormGroupField>
                    <Field name="partnerId">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            style={{ border: 'solid 1px black' }}
                            value={partnerId}
                            autoComplete="new-password"
                            onChange={e => {
                              const { value } = e.target
                              const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                              if (onlyEnglish.length > 15) {
                                return
                              }
                              setPartnerId(onlyEnglish)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                  <CustomFormGroupDescription>
                    <label htmlFor="checkbox-multiple-generation">다중생성</label>
                    <input
                      id="checkbox-multiple-generation"
                      style={{ width: '15px', height: '15px', marginTop: '-7px' }}
                      type="checkbox"
                      onClick={handleCheckboxClick}
                    />

                    {multiUserChecked && (
                      <div
                        style={{
                          width: '90%',
                          justifyContent: 'left',
                          alignItems: 'center',
                          display: 'flex',
                          gap: '5px',
                          marginLeft: '20px',
                        }}
                      >
                        <input type="text" style={{ border: 'solid 1px black', width: '50px' }} value="1" />
                        ~
                        <input
                          type="text"
                          style={{ border: 'solid 1px black', width: '50px' }}
                          value={multiUserRange}
                          autoComplete="new-password"
                          onChange={e => {
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setMultiUserRange(onlyNumber)
                          }}
                        />
                        까지
                        <input
                          type="text"
                          style={{ border: 'solid 1px black', width: '50px' }}
                          value={multiUserDigit}
                          autoComplete="new-password"
                          onChange={e => {
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setMultiUserDigit(onlyNumber)
                          }}
                        />
                        자리
                      </div>
                    )}
                  </CustomFormGroupDescription>
                  {multiUserChecked && (
                    <CustomFormGroupDescription>
                      ex) 1~30까지 1자리 -&gt; aa1,aa2....aa50&nbsp;&nbsp;&nbsp;1~30까지 2자리 -&gt; aa01,aa02....aa050
                    </CustomFormGroupDescription>
                  )}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    비밀번호<CustomSpan>*</CustomSpan>
                  </FormGroupLabel>
                  <FormGroupField>
                    <Field name="partnerPassword">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              style={{ border: 'solid 1px black' }}
                              inputMode="latin"
                              value={partnerPassword}
                              autoComplete="new-password"
                              onChange={e => {
                                setPartnerPassword(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} top />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    닉네임<CustomSpan>*</CustomSpan>
                  </FormGroupLabel>
                  <FormGroupField>
                    <Field name="nickName">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={nickName}
                            style={{ border: 'solid 1px black' }}
                            onChange={e => {
                              setNickName(e.target.value)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>생년월일</FormGroupLabel>
                  <FormGroupField>
                    <Field name="userBirthDay">
                      {({ input, meta }) => (
                        <>
                          <DatePickerWrap>
                            <DatePicker
                              selected={userBirthDay}
                              onChange={date => setUserBirthDay(date)}
                              dateFormat="yyyy.MM.dd"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              dropDownMode="select"
                              popperPlacement="bottom-start"
                              placeholderText="생년월일"
                              locale={ko}
                            />
                          </DatePickerWrap>
                          {meta.touched && meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>연락처</FormGroupLabel>
                  <FormGroupField>
                    <Field name="phoneNum">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={phoneNum}
                            style={{ border: 'solid 1px black' }}
                            onChange={e => {
                              const { value } = e.target
                              const onlyNumber = value.replace(/[^0-9]/g, '')
                              setPhoneNum(onlyNumber)
                            }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>
                    예금주(이름)<CustomSpan>*</CustomSpan>
                  </FormGroupLabel>
                  <FormGroupField>
                    <Field name="accountHolder">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={accountHolder}
                            style={{ border: 'solid 1px black' }}
                            onChange={e => {
                              setAccountHolder(e.target.value)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                  <CustomFormGroupDescription>다중생성시 같은 원리로 적용.</CustomFormGroupDescription>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>은행명</FormGroupLabel>
                  <FormGroupField>
                    <Field name="bankName">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <MemberResterBankSelect
                            {...input}
                            value={bankNameOptionValue}
                            onChange={option => {
                              // input.onChange(option)
                              onChangeBankNameHandler(option)
                            }}
                            options={bankNameOption}
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="은행"
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>계좌번호</FormGroupLabel>
                  <FormGroupField>
                    <Field name="accountNum">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            style={{ border: 'solid 1px black' }}
                            value={accountNum}
                            onChange={e => {
                              const { value } = e.target
                              const onlyNumber = value.replace(/[^0-9]/g, '')
                              setAccountNum(onlyNumber)
                            }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>레벨</FormGroupLabel>
                  <FormGroupField style={{ gap: '10px' }}>
                    <Field name="depositLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={depositLevel}
                            onChange={option => {
                              onChangeDepositLevelHandler(option)
                            }}
                            options={depositLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="casinoLiveLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={casinoLiveLevel}
                            onChange={option => {
                              onChangeCasinoLiveLevelHandler(option)
                            }}
                            options={casinoLiveLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="casinoSlotLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={casinoSlotLevel}
                            onChange={option => {
                              onChangeCasinoSlotLevelHandler(option)
                            }}
                            options={casinoSlotLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="miniGameLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={miniGameLevel}
                            onChange={option => {
                              onChangeMiniGameLevelHandler(option)
                            }}
                            options={miniGameLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="sportsLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={sportsLevel}
                            onChange={option => {
                              onChangeSportsLevelHandler(option)
                            }}
                            options={sportsLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="virtualGameLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={virtualGameLevel}
                            onChange={option => {
                              onChangeVirtualGameLevelHandler(option)
                            }}
                            options={virtualGameLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="holdemLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={holdemLevel}
                            onChange={option => {
                              onChangeHoldemLevelHandler(option)
                            }}
                            options={holdemLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="friendCompLevel">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <MemberResterBankSelect
                            {...input}
                            value={friendCompLevel}
                            onChange={option => {
                              onChangeFriendCompLevelHandler(option)
                            }}
                            options={friendCompLevelOption}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                  <CustomFormGroupDescription>
                    (입금 / 라이브 / 슬롯 / 미니게임 / 스포츠 / 가상게임 / 홀덤 / 추천인)
                  </CustomFormGroupDescription>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>기본값(1)</FormGroupLabel>
                  <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                    <CustomDiv>
                      <span>⦁ 스킨번호</span>
                      <div style={{ display: 'flex', border: 'solid 1px #000', gap: '5px' }}>
                        <Field name="userSkinNum">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap style={{ width: '60px' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={userSkinNum}
                                  onChange={e => {
                                    const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                    setUserSkinNum(onlyNumber)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon style={{ width: '50px' }}>번</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span>⦁ 입금보너스 허용</span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="depositBonusStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span>⦁ 추천코드 허용</span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="recommendCodeStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span>⦁ 중복로그인 허용 여부</span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="duplicatedSignInStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>기본값(2)</FormGroupLabel>
                  <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                    <CustomDiv>
                      <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        ⦁ 하부 정산비율 수정
                        <br />
                        (파트너용)
                      </span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="settlementExchangeStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        ⦁ 하부 머니증가 여부
                        <br />
                        (파트너용)
                      </span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="moneyIncExchangeStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        ⦁ 하부 머니회수 여부
                        <br />
                        (파트너용)
                      </span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="moneyDecExchangeStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>기본값(3)</FormGroupLabel>
                  <div style={{ display: 'flex', width: '80%', gap: '10px' }}>
                    <CustomDiv>
                      <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        ⦁ 정산금 전환여부
                        <br />
                        (파트너용)
                      </span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserSettingAutoStatusReverseEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="userLosingPointExchangeAutoStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                    <CustomDiv>
                      <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        ⦁ 정산일 여부
                        <br />
                        (파트너용)
                      </span>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <div style={{ marginLeft: '0px', marginTop: '5px', display: 'flex' }}>
                          {UserExchangeDateEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{
                                display: 'inline-block',
                                width: 'max-content',
                                margin: '0px',
                                padding: '0px',
                              }}
                            >
                              <Field
                                name="userExchangeDateStatus"
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={item.initialValue}
                              />
                            </FormGroupField>
                          ))}
                        </div>
                      </div>
                    </CustomDiv>
                  </div>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '0px' }}>
                  {
                    {
                      SUPER: (
                        <>
                          <Button variant="primary" type="submit">
                            하부 유저등록
                          </Button>
                        </>
                      ),
                    }[authority]
                  }
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default RegistrationDownUserForm

RegistrationDownUserForm.propTypes = {
  userInfo: PropTypes.shape({
    userId: PropTypes.string,
    userName: PropTypes.string,
    partnerLevel: PropTypes.string,
    edgeName: PropTypes.string,
  }),
  onClickHandler: PropTypes.func.isRequired,
}

RegistrationDownUserForm.defaultProps = {
  userInfo: null,
}

const CustomDiv = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  & > span {
    width: max-content !important;
  }
  & > div {
    // border: solid 1px #000;
  }
`

const CustomSpan = styled.span`
  color: red !important;
  font-weight: 600;
  margin-left: 5px;
  font-size: 14px;
`
export const CustomFormGroupField = styled(FormGroupField)`
  // border: solid 1px #000;
  height: 270px;
  width: 695px !important;
  padding: 0px !important;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
  margin-left: 20px !important;
  // padding-left: 5px !important;
  padding-top: 5px !important;
`

const CustomFormGroupDescription = styled(FormGroupDescription)`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  font-size: 12px;
`
const DatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid #4a4a4a;
  z-index: 101;

  input {
    width: 100%;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: rgb(211, 211, 211);
    border: transparent;
    background: transparent;
    outline: none;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: lightgrey;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: black !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__month-dropdown-container {
    .react-datepicker__month-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__month-dropdown {
    border: 2px solid lightgrey;
    color: black;
  }

  .react-datepicker__year-dropdown {
    border: 2px solid lightgrey;
    color: black;

    .react-datepicker__navigation {
      top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
      display: none;
    }

    .react-datepicker__navigation--years-previous {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container {
    .react-datepicker__year-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`
