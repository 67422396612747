import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import Error from '@/shared/components/form/Error'
import { FormButtonToolbar, FormContainer, FormInputWrap } from '@/shared/components/form/FormElements'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  SportBettingPerStatusEnums,
  SportIdTypeEnums,
  SportIdTypeEnumsSelectOptions,
  SportsMarketTypeEnums,
  SportsMarketTypeEnumsSelectOptions,
} from '../../../enums/SystemManagement/sportEnums'
import Loading from '../../../shared/components/Loading'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  changeUsingSportMarketCombination,
  createSportMixed,
  deleteSportMixed,
  getMarketNameByMarketId,
  getMarketNameBySportType,
  getSportsMarketList,
  getSportsMixed,
  updateAllSportMarketMinOrMaxBettingPer,
  updateAllSportMarketOddPerByMultiply,
  updateAllSportMarketOddPerByPlus,
  updateSportMarketDetailInfo,
  updateSportMarketOddPerByMultiply,
  updateSportMarketOddPerByPlus,
  updateSportMarketSelectBetLine,
  updateSportMarketSelectMaxLine,
  updateSportsMarketSwitch,
} from '../../../utils/api/sport/sportSettingApi'
import { decodeAccessToken } from '../../../utils/token'
import { memberInfoValidate } from '../../../utils/validate/memberManage/memberInfoValidate'
import { sportSettingValidate } from '../../../utils/validate/sport/sportSettingManage'
import MarketList from './MarketList'
import { SportsMixedSettingSelect } from './SportsMixedSettingSelect'
import MarketCombinationModifyModal from './marketCombinationModifyModal/MarketCombinationModifyModal'
// import MarketCombinationList from './MarketCombinationList'

const SportsMixedManagement = () => {
  /**
   * 마켓리스트쪽 내용
   */
  const [marketLoading, setMarketLoading] = useState(false)
  const [marketContent, setMarketContent] = useState([])
  const [bookmakerOfferSportId, setBookmakerOfferSportId] = useState([])
  const [bookmakerName, setBookmakerName] = useState('1XBet')

  const [minimumBettingPer, setMinimumBettingPer] = useState(0)
  const [maximumBettingPer, setMaximumBettingPer] = useState(0)
  const [miniMumBettingPerStatusValue, setMiniMumBettingPerStatusValue] = useState({
    value: 'NO_SHOW',
    label: SportBettingPerStatusEnums.NO_SHOW,
  })
  const onChangeMiniMumBettingPerStatusHandler = event => {
    const selectedValue = event.target.value
    const selectedLabel = SportBettingPerStatusEnums[selectedValue]
    setMiniMumBettingPerStatusValue({
      value: selectedValue,
      label: selectedLabel,
    })
  }
  const [maxiMumBettingPerStatusValue, setMaxiMumBettingPerStatusValue] = useState({
    value: 'NO_SHOW',
    label: SportBettingPerStatusEnums.NO_SHOW,
  })
  const onChangeMaxiMumBettingPerStatusHandler = event => {
    const selectedValue = event.target.value
    const selectedLabel = SportBettingPerStatusEnums[selectedValue]
    setMaxiMumBettingPerStatusValue({
      value: selectedValue,
      label: selectedLabel,
    })
  }
  const [firstOfferBettingPerByPlus, setFirstOfferBettingPerByPlus] = useState(0)
  const [secondOfferBettingPerByPlus, setSecondOfferBettingPerByPlus] = useState(0)
  const [thirdOfferBettingPerByPlus, setThirdOfferBettingPerByPlus] = useState(0)

  const [firstOfferBettingPerByMultiply, setFirstOfferBettingPerByMultiply] = useState(0)
  const [secondOfferBettingPerByMultiply, setSecondOfferBettingPerByMultiply] = useState(0)
  const [thirdOfferBettingPerByMultiply, setThirdOfferBettingPerByMultiply] = useState(0)
  const [allLoading, setAllLoading] = useState(false)

  /**
   * 변경 모달
   */

  const { bookmakerId, sportId, sportMarketTypeId } = useSelector(state => {
    const { bookMakerInfo, sportInfo, sportMarketTypeInfo } = state

    return {
      bookmakerId: bookMakerInfo.activeMarketBookmakerMenu,
      sportId: sportInfo.activeMarketSportMenuExceptAll,
      sportMarketTypeId: sportMarketTypeInfo.activeSportMarketTypeMenu,
    }
  })

  const [marketParams, setMarketParams] = useState({
    sportId,
    bookmakerId,
    searchMarketType: 'PREMATCH',
    sportMarketType: sportMarketTypeId,
  })

  const [searchMarketType, setSearchMarketType] = useState('PREMATCH')

  const onSelectMarketTypeOptionHandler = option => {
    setSearchMarketType(option)
    setMarketParams(prev => ({
      ...prev,
      searchMarketType,
    }))
  }

  // 회원ID
  const [searchMarketName, setSearchMarketName] = useState(null)

  const onSearchMarketNameHandler = e => {
    setSearchMarketName(e.target.value)
  }

  const onSearchClickHandler2 = () => {
    setMarketParams(prev => ({
      ...prev,
      marketName: searchMarketName,
    }))
  }

  const fetchSportsMarketList = useCallback(async () => {
    if (marketParams.sportId === -1 || marketParams.bookmakerId === -1 || marketParams.sportMarketType === -1) return // 필수 파라미터 확인
    if (marketLoading) return
    setMarketLoading(true)
    await getSportsMarketList(marketParams)
      .then(res => {
        setMarketContent(res.data.marketList)
        setBookmakerOfferSportId(res.data.bookmakerOfferSportId)
        setBookmakerName(res.data.bookmakerName)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        alert('데이터를 불러오는 데 실패했습니다.')
      })

    setMarketLoading(false)
  }, [marketParams])

  useEffect(() => {
    fetchSportsMarketList()
  }, [fetchSportsMarketList])

  useEffect(() => {
    if (sportId === -1) return // 필수 파라미터 확인
    setMarketParams(prev => ({
      ...prev,
      sportId,
    }))
  }, [sportId])

  useEffect(() => {
    if (bookmakerId === -1) return // 필수 파라미터 확인
    setMarketParams(prev => ({
      ...prev,
      bookmakerId,
    }))
  }, [bookmakerId])

  useEffect(() => {
    if (sportMarketTypeId === -1) return // 필수 파라미터 확인
    setMarketParams(prev => ({
      ...prev,
      sportMarketType: sportMarketTypeId,
    }))
  }, [sportMarketTypeId])

  const allUpdateSportMarketMinMaxPer = (
    bookmakerId,
    sportId,
    minOrMax,
    minOrMaxBettingPer,
    miniOrMaxBettingPerStatusValue,
  ) => {
    if (allLoading) return
    const adjustmentType = minOrMax === 'MIN' ? '최소배당' : '최대배당'
    if (
      window.confirm(
        `${bookmakerName}의 ${SportIdTypeEnums[sportId]}종목 ${SportsMarketTypeEnums[sportMarketTypeId]} 전체마켓의 ${adjustmentType}을 조정 하시겠습니까?\n전체마켓 배당조정은 배당이 재 업데이트되는 순간부터 적용됩니다.\n실시간으로 변경을 원하실 경우, 아래 마켓 목록에서 개별로 변경하시면 됩니다. `,
      )
    ) {
      const body = {
        sportMarketType: sportMarketTypeId,
        bookMakerId: bookmakerId,
        sportId,
        minOrMax,
        minOrMaxBettingPer,
        miniOrMaxBettingPerStatus: miniOrMaxBettingPerStatusValue,
      }
      setAllLoading(true)
      updateAllSportMarketMinOrMaxBettingPer(body)
        .then(res => {
          setAllLoading(false)
          alert('해당 북메이커의 마켓 변경이 완료됐습니다.')
          fetchSportsMarketList()
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setAllLoading(false)
        })
    }
  }

  const allUpdateSportMarketOddPerByPlus = (
    sportMarketTypeId,
    bookmakerId,
    sportId,
    firstOfferBettingPerByPlus,
    secondOfferBettingPerByPlus,
    thirdOfferBettingPerByPlus,
  ) => {
    if (allLoading) return
    if (
      window.confirm(
        `${bookmakerName}의 ${SportIdTypeEnums[sportId]}종목 ${SportsMarketTypeEnums[sportMarketTypeId]}  전체마켓의 배당조정을 +,- 를 통해서 하시겠습니까?\n전체마켓 배당조정은 배당이 재업데이트 되는 순간부터 적용됩니다.\n실시간으로 변경을 원하실 경우, 아래 마켓 목록에서 개별로 변경하시면 됩니다. `,
      )
    ) {
      const body = {
        sportMarketType: sportMarketTypeId,
        bookMakerId: bookmakerId,
        sportId,
        firstOfferBettingPerByPlus,
        secondOfferBettingPerByPlus,
        thirdOfferBettingPerByPlus,
      }
      setAllLoading(true)
      updateAllSportMarketOddPerByPlus(body)
        .then(res => {
          setAllLoading(false)
          alert('해당 북메이커의 마켓 변경이 완료됐습니다.')
          fetchSportsMarketList()
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }
          setAllLoading(false)
        })
    }
  }

  const allUpdateSportMarketOddPerByMultiply = (
    sportMarketTypeId,
    bookmakerId,
    sportId,
    firstOfferBettingPerByMultiply,
    secondOfferBettingPerByMultiply,
    thirdOfferBettingPerByMultiply,
  ) => {
    if (allLoading) return
    if (
      window.confirm(
        `${bookmakerName}의 ${SportIdTypeEnums[sportId]}종목 ${SportsMarketTypeEnums[sportMarketTypeId]} 전체마켓의 배당조정을 X를 통해서 하시겠습니까?\n전체마켓 배당조정은 배당이 재업데이트 되는 순간부터 적용됩니다.\n실시간으로 변경을 원하실 경우, 아래 마켓 목록에서 개별로 변경하시면 됩니다.`,
      )
    ) {
      const body = {
        sportMarketType: sportMarketTypeId,
        bookMakerId: bookmakerId,
        sportId,
        firstOfferBettingPerByMultiply,
        secondOfferBettingPerByMultiply,
        thirdOfferBettingPerByMultiply,
      }
      setAllLoading(true)
      updateAllSportMarketOddPerByMultiply(body)
        .then(res => {
          setAllLoading(false)
          alert('해당 북메이커의 마켓 변경이 완료됐습니다.')
          fetchSportsMarketList()
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.\n값을 다시 확인해주세요. 소수점 2자리까지 가능합니다.')
          }

          setAllLoading(false)
        })
    }
  }

  const handleSwitchChange = useCallback(
    (marketKey, changeColumnString) => {
      if (marketLoading) return
      const body = {
        marketKey,
        sportMarketType: sportMarketTypeId,
        changeColumnString,
      }
      setMarketLoading(true)
      updateSportsMarketSwitch(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )

  const onChangeSportMaxLineTypeHandler = useCallback(
    (marketKey, data, sportMarketType) => {
      if (marketLoading) return
      const body = {
        marketKey,
        maxLineType: data.sportMaxLineType,
        sportMarketType,
      }
      setMarketLoading(true)
      updateSportMarketSelectMaxLine(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )

  const onChangeSportsBetLineShowTypeHandler = useCallback(
    (marketKey, data, sportMarketTypeId) => {
      if (marketLoading) return

      const body = {
        marketKey,
        sportsBetLineShowType: data.sportsBetLineShowType,
        sportMarketType: sportMarketTypeId,
      }
      setMarketLoading(true)
      updateSportMarketSelectBetLine(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )

  const onChangeSportsDetailInfoHandler = useCallback(
    (
      sportMarketType,
      marketKey,
      marKetName,
      minimumBettingPer,
      minimumBettingPerStatus,
      maximumBettingPer,
      maximumBettingPerStatus,
      sumBettingPer,
      marketSortNum,
      sumBettingBigType,
      sumBettingSmallType,
    ) => {
      if (marketLoading) return

      const body = {
        sportMarketType,
        marketKey,
        marKetName,
        minimumBettingPer,
        minimumBettingPerStatus,
        maximumBettingPer,
        maximumBettingPerStatus,
        sumBettingPer,
        marketSortNum,
        sumBettingBigType,
        sumBettingSmallType,
      }
      setMarketLoading(true)
      updateSportMarketDetailInfo(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )

  const onChageOddperPlus = useCallback(
    (
      marketKey,
      firstOfferBettingPerByPlus,
      secondOfferBettingPerByPlus,
      thirdOfferBettingPerByPlus,
      sportMarketTypeId,
    ) => {
      if (marketLoading) return
      const body = {
        marketKey,
        firstOfferBettingPerByPlus,
        secondOfferBettingPerByPlus,
        thirdOfferBettingPerByPlus,
        sportMarketType: sportMarketTypeId,
        bookMakerId: bookmakerId,
        sportId,
      }
      setMarketLoading(true)
      updateSportMarketOddPerByPlus(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )
  const onChageOddperMultiply = useCallback(
    (
      marketKey,
      sportId,
      bookMakerId,
      firstOfferBettingPerByMultiply,
      secondOfferBettingPerByMultiply,
      thirdOfferBettingPerByMultiply,
      sportMarketTypeId,
    ) => {
      if (marketLoading) return
      const body = {
        marketKey,
        sportId,
        bookMakerId,
        sportMarketType: sportMarketTypeId,
        firstOfferBettingPerByMultiply,
        secondOfferBettingPerByMultiply,
        thirdOfferBettingPerByMultiply,
      }
      setMarketLoading(true)
      updateSportMarketOddPerByMultiply(body)
        .then(res => {
          setMarketLoading(false)
          const { marketKey } = res.data

          setMarketContent(prev =>
            prev.map(item => {
              return item.marketKey === marketKey ? { ...item, ...res.data } : item
            }),
          )
          alert('변경이 완료됐습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'API-1002') {
            alert('현재 데이터 처리중입니다. 잠시후 다시 시도해주세요.')
          } else {
            alert('변경이 실패했습니다.')
          }

          setMarketLoading(false)
        })
    },
    [marketLoading],
  )

  const handleBettingPerByMultiply = useCallback((e, marketKey, offerType, sportMarketTypeId) => {
    const newValue = e.target.value
    const downLowerCase = sportMarketTypeId.toLowerCase()
    // Update the state with the modified market content
    setMarketContent(prevMarketContent =>
      prevMarketContent.map(item => {
        if (item.marketKey === marketKey) {
          return {
            ...item,
            [`${downLowerCase}${offerType}OfferBettingPerByMultiply`]: newValue,
          }
        }
        return item
      }),
    )
  }, [])

  const handleBettingPerByPlus = useCallback((e, marketKey, offerType, sportMarketTypeId) => {
    const newValue = e.target.value
    const downLowerCase = sportMarketTypeId.toLowerCase()
    // Update the state with the modified market content
    setMarketContent(prevMarketContent =>
      prevMarketContent.map(item => {
        if (item.marketKey === marketKey) {
          return {
            ...item,
            [`${downLowerCase}${offerType}OfferBettingPerByPlus`]: newValue,
          }
        }
        return item
      }),
    )
  }, [])

  /**
   * 조합쪽 내용
   */
  /**
   * 절취선 -------------------------------
   * 절취선 -------------------------------
   * 절취선 -------------------------------
   * 절취선 -------------------------------
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [writeLoading, setWriteLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority

  /**
   조합스트 조회
   */
  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    if (sportId === -1 || bookmakerId === -1 || !sportId || !bookmakerId) return // 필수 파라미터 확인
    setParams(prev => ({
      ...prev,
      sportId,
      bookmakerId,
    }))
  }, [sportId, bookmakerId])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [searchSportIdType, setSearchSportIdType] = useState('')

  const [searchSportIdOptionValue, setSearchSportIdOptionValue] = useState(null)
  const searchSportIdTypeOption = SportIdTypeEnumsSelectOptions()

  const onSelectSportIdTypeOptionHandler = option => {
    if (option === null) {
      setSearchSportIdOptionValue(null)
      setSearchSportIdType('')
      return
    }

    setSearchSportIdOptionValue(option)
    setSearchSportIdType(option.value)
  }

  const [loading, setLoading] = useState(false)
  const fetchSportsMixed = async () => {
    if (sportId === -1 || bookmakerId === -1 || !sportId || !bookmakerId) return // 필수 파라미터 확인
    setLoading(true)
    await getSportsMixed(params)
      .then(res => {
        setContent(res.data.marketCombinationList)
        setTotalElement(res.data.totalElement)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (params?.sportId === -1 || params?.bookmakerId === -1 || !params?.sportId || !params?.bookmakerId) return // 필수 파라미터 확인
    fetchSportsMixed()
  }, [params])

  const [modifyModalShow, setModifyModalShow] = useState(false)
  const [marketCombintationInfo, setMarketCombintationInfo] = useState(null)
  const modifyModalHandler = item => {
    setModifyModalShow(prev => !prev)
    setMarketCombintationInfo(item)
    fetchSportsMixed()
  }

  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      sportId: !searchSportIdType ? sportId : searchSportIdType,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const onDeleteSubmit = sportKey => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (window.confirm('해당 조합을 제거 하시겠습니까?')) {
      setWriteLoading(true)
      deleteSportMixed(sportKey)
        .then(res => {
          alert('조합이 삭제됐습니다.')
          fetchSportsMixed()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('조합이 삭제가 실패했습니다.')
          fetchSportsMixed()
          setWriteLoading(false)
        })
    }
  }

  const onChangeIsUsed = (sportKey, isUsed) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    let confirmationMessage

    if (isUsed) {
      confirmationMessage = '해당 조합을 미사용 처리하시겠습니까?'
    } else {
      confirmationMessage = '해당 조합을 사용 처리하시겠습니까?'
    }
    if (window.confirm(confirmationMessage)) {
      changeUsingSportMarketCombination(sportKey)
        .then(res => {
          alert('처리 됐습니다.')
          fetchSportsMixed()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('처리가 실패했습니다.')
          fetchSportsMixed()
          setWriteLoading(false)
        })
    }
  }

  const [marketCombinationId, setMarketCombinationId] = useState('')
  const [marketCombinationName, setMarketCombinationName] = useState('')
  const [marketCombinationPer, setMarketCombinationPer] = useState('')

  // 종목 ,타입 선택
  const [sportTypeValue, setSportTypeValue] = useState(null)

  const [sportMarketTypeValue, setSportMarketTypeValue] = useState(null)

  const sportTypeOptions = SportIdTypeEnumsSelectOptions()
  const sportsMarketTypeOptions = SportsMarketTypeEnumsSelectOptions()

  useEffect(() => {
    const defaultMarketTypeValue = Object.keys(SportsMarketTypeEnums)[0]
    const defaultOption = sportsMarketTypeOptions.find(option => option.value === defaultMarketTypeValue)
    setSportMarketTypeValue(defaultOption)

    const defaultSportTypeValue = Object.keys(SportIdTypeEnums)[0]
    const defaultSportTypeOption = sportTypeOptions.find(option => option.value === defaultSportTypeValue)
    setSportTypeValue(defaultSportTypeOption)
  }, [])

  const onSportTypeChangeHandler = option => {
    setSportTypeValue(option)
  }

  const onSportMarketTypeChangeHandler = option => {
    setSportMarketTypeValue(option)
  }

  useEffect(() => {
    if (sportTypeValue == null) return
    if (sportMarketTypeValue == null) return
    if (sportId === -1) return
    if (bookmakerId === -1) return
    getMarketNameBySportType({
      bookmakerId,
      sportId: sportTypeValue.value,
      sportMarketType: sportMarketTypeValue.value,
    }).then(res => {
      const option = res.data.sportMarketNameList.map(item => ({
        value: item.marketId,
        label: !item.settlementMarket ? `${item.marketNameKo} (수동처리)` : item.marketNameKo,
      }))
    })
  }, [sportMarketTypeValue])

  useEffect(() => {
    if (!marketCombinationId || !sportTypeValue || !sportMarketTypeValue) {
      setMarketCombinationId('')
      setMarketCombinationName('')
      setMarketCombinationPer('')
      return
    }
    const encodedMarketCombinationId = marketCombinationId.replace(/\+/g, '%2B')

    getMarketNameByMarketId({
      bookmakerId,
      sportId: sportTypeValue.value,
      marketCombinationId: encodedMarketCombinationId,
    }).then(res => {
      setMarketCombinationName(res.data.marketCombinationName)
    })
  }, [marketCombinationId, sportTypeValue, sportMarketTypeValue])

  const onSubmit = e => {
    if (writeLoading) return
    if (marketCombinationPer && !Number(marketCombinationPer)) {
      alert('조합 배당조정 값을 다시 확인해주세요.')
      return
    }
    if (window.confirm(`${sportMarketTypeValue.label} 스포츠 조합을 추가하시겠습니까?`)) {
      const body = {
        bookmakerId,
        sportId: Number(sportTypeValue.value),
        sportMarketType: sportMarketTypeValue.value,
        marketCombinationId,
        marketCombinationPer: !marketCombinationPer ? 0.0 : Number(marketCombinationPer),
      }
      createSportMixed(body)
        .then(res => {
          alert('조합이 등록됐습니다.')
          fetchSportsMixed()
          setWriteLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          if (errorCode === 'SPORTS-1003') {
            alert(`${message}`)
          } else if (errorCode === 'SPORTS-2012') {
            alert(`${message}`)
          } else if (errorCode === 'SPORTS-2014') {
            alert(`${message}`)
          } else if (errorCode === 'SPORTS-2013') {
            alert(`${message}`)
          } else {
            alert('조합이 등록이 실패했습니다.')
            fetchSportsMixed()
          }
          setWriteLoading(false)
        })
    }
  }

  const handleChange = e => {
    const newValue = e.target.value
    // 숫자와 소수점 2자리까지, 음수 포함 허용하는 정규식
    const regex = /^-?\d*\.?\d{0,2}$/

    // 입력 값이 정규식에 맞는지 확인
    if (regex.test(newValue)) {
      setMarketCombinationPer(newValue)
    }
  }

  return (
    <>
      {(marketLoading || writeLoading || loading) && <Loading />}
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>마켓ID/명</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchUserId"
                type="text"
                placeholder="입력해주세요"
                value={searchMarketName}
                onChange={e => onSearchMarketNameHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>

        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            type="button"
            onClick={() => {
              onSearchClickHandler2()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
        <CustomDiv style={{ marginLeft: '10px' }}>
          <span style={{ fontWeight: '600', fontSize: '25px' }}>{bookmakerName} : </span>
          {bookmakerOfferSportId.map(sportId => (
            <CustomDiv key={sportId}>
              <img
                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${sportId}.png`}
                alt={SportIdTypeEnums[sportId]}
              />
              <div style={{ fontWeight: '600', fontSize: '14px' }}>{SportIdTypeEnums[sportId]}</div>
            </CustomDiv>
          ))}
        </CustomDiv>
        <AllChangeDiv>
          <AllChangeDiv2>
            <AllChangeDiv3 style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600, color: 'red', marginRight: '1px' }}>전체 배당X :&nbsp;</span>

              <CustomInput
                type="text"
                value={firstOfferBettingPerByMultiply}
                onChange={e => {
                  setFirstOfferBettingPerByMultiply(e.target.value)
                }}
                style={{ width: '50px' }}
              />

              <CustomInput
                type="text"
                value={secondOfferBettingPerByMultiply}
                onChange={e => {
                  setSecondOfferBettingPerByMultiply(e.target.value)
                }}
                style={{ width: '50px' }}
              />
              <CustomInput
                type="text"
                value={thirdOfferBettingPerByMultiply}
                onChange={e => {
                  setThirdOfferBettingPerByMultiply(e.target.value)
                }}
                style={{ width: '50px' }}
              />

              <CustomButton
                type="button"
                onClick={() => {
                  allUpdateSportMarketOddPerByMultiply(
                    sportMarketTypeId,
                    bookmakerId,
                    sportId,
                    firstOfferBettingPerByMultiply,
                    secondOfferBettingPerByMultiply,
                    thirdOfferBettingPerByMultiply,
                  )
                }}
                style={{ background: 'blue', marginLeft: '2px' }}
              >
                변경
              </CustomButton>
            </AllChangeDiv3>
            <AllChangeDiv3 style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600, color: 'red' }}>
                전체 배당<span style={{ fontSize: '16px' }}>±</span> :&nbsp;
              </span>
              <CustomInput
                type="text"
                value={firstOfferBettingPerByPlus}
                onChange={e => {
                  setFirstOfferBettingPerByPlus(e.target.value)
                }}
                style={{ width: '50px' }}
              />

              <CustomInput
                type="text"
                value={secondOfferBettingPerByPlus}
                onChange={e => {
                  setSecondOfferBettingPerByPlus(e.target.value)
                }}
                style={{ width: '50px' }}
              />
              <CustomInput
                type="text"
                value={thirdOfferBettingPerByPlus}
                onChange={e => {
                  setThirdOfferBettingPerByPlus(e.target.value)
                }}
                style={{ width: '50px' }}
              />
              <CustomButton
                type="button"
                onClick={() => {
                  allUpdateSportMarketOddPerByPlus(
                    sportMarketTypeId,
                    bookmakerId,
                    sportId,
                    firstOfferBettingPerByPlus,
                    secondOfferBettingPerByPlus,
                    thirdOfferBettingPerByPlus,
                  )
                }}
                style={{ background: 'blue', marginLeft: '2px' }}
              >
                변경
              </CustomButton>
            </AllChangeDiv3>
          </AllChangeDiv2>
        </AllChangeDiv>
        <AllChangeDiv>
          <AllChangeDiv2>
            <AllChangeDiv3 style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600, color: 'red' }}>전체 현시 최소배당 :&nbsp;</span>
              <CustomInput
                type="text"
                value={minimumBettingPer}
                onChange={e => {
                  setMinimumBettingPer(e.target.value)
                }}
                style={{ width: '50px' }}
              />

              <select
                onChange={option => {
                  onChangeMiniMumBettingPerStatusHandler(option)
                }}
                value={miniMumBettingPerStatusValue.value} // 수정된 부분
              >
                {Object.entries(SportBettingPerStatusEnums).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>

              <CustomButton
                type="button"
                onClick={() => {
                  allUpdateSportMarketMinMaxPer(
                    bookmakerId,
                    sportId,
                    'MIN',
                    minimumBettingPer,
                    miniMumBettingPerStatusValue.value,
                  )
                }}
                style={{ background: 'blue', marginLeft: '2px' }}
              >
                변경
              </CustomButton>
            </AllChangeDiv3>
            <AllChangeDiv3 style={{ display: 'flex' }}>
              <span style={{ fontWeight: 600, color: 'red' }}>전체 현시 최대배당 :&nbsp;</span>
              <CustomInput
                type="text"
                value={maximumBettingPer}
                onChange={e => {
                  setMaximumBettingPer(e.target.value)
                }}
                style={{ width: '50px' }}
              />
              <select
                onChange={e => {
                  onChangeMaxiMumBettingPerStatusHandler(e)
                }}
                value={maxiMumBettingPerStatusValue.value} // 수정된 부분
              >
                {Object.entries(SportBettingPerStatusEnums).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
              <CustomButton
                type="button"
                onClick={() => {
                  allUpdateSportMarketMinMaxPer(
                    bookmakerId,
                    sportId,
                    'MAX',
                    maximumBettingPer,
                    maxiMumBettingPerStatusValue.value,
                  )
                }}
                style={{ background: 'blue', marginLeft: '2px' }}
              >
                변경
              </CustomButton>
            </AllChangeDiv3>
          </AllChangeDiv2>
        </AllChangeDiv>
      </SearchContainer>

      <Card style={{ padding: '0px', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
        <CardBody style={{ padding: '0px', width: '70%', overflowY: 'auto', height: '650px' }}>
          <MarketList
            sportMarketTypeId={sportMarketTypeId}
            marketContent={marketContent}
            handleBettingPerByMultiply={handleBettingPerByMultiply}
            handleBettingPerByPlus={handleBettingPerByPlus}
            onDeleteSubmit={onDeleteSubmit}
            onChageOddperPlus={onChageOddperPlus}
            onChangeSportsBetLineShowTypeHandler={onChangeSportsBetLineShowTypeHandler}
            handleSwitchChange={handleSwitchChange}
            onChangeSportMaxLineTypeHandler={onChangeSportMaxLineTypeHandler}
            onChageOddperMultiply={onChageOddperMultiply}
            onChangeSportsDetailInfoHandler={onChangeSportsDetailInfoHandler}
            setMarketCombinationName={setMarketCombinationName}
            setMarketCombinationId={setMarketCombinationId}
          />
        </CardBody>
        <CardBody
          style={{ padding: '0px', width: '30%', borderLeft: 'solid 1px #000', overflowY: 'auto', height: '650px' }}
        >
          <BettingSettingWrap>
            <BettingTitleWrap>조합베팅 추가</BettingTitleWrap>
            <BettingSecondWrap>
              <Form
                onSubmit={onSubmit}
                validate={sportSettingValidate}
                initialValues={{
                  sportTypeValue,
                  sportMarketTypeValue,
                  marketCombinationName,
                  marketCombinationPer,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer
                    horizontal
                    onSubmit={handleSubmit}
                    validate={memberInfoValidate}
                    initialValues={{}}
                    style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                  >
                    <BettingSecondFormWrap>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '50%', display: 'flex', gap: '5px' }}>
                          <BettingSecondFormLabel>타입</BettingSecondFormLabel>
                          <BettingFormGroupField>
                            <Field name="sportMarketType">
                              {({ input, meta }) => (
                                <FormInputWrap>
                                  <SportsMixedSettingSelect
                                    {...input}
                                    value={sportMarketTypeValue || 'DOMESTIC'}
                                    onChange={option => {
                                      input.onChange(option)
                                      onSportMarketTypeChangeHandler(option)
                                    }}
                                    options={sportsMarketTypeOptions}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </FormInputWrap>
                              )}
                            </Field>
                          </BettingFormGroupField>
                        </div>
                        <div style={{ width: '50%', display: 'flex', gap: '5px' }}>
                          <BettingSecondFormLabel>종목</BettingSecondFormLabel>
                          <BettingFormGroupField>
                            <Field name="sportType">
                              {({ input, meta }) => (
                                <FormInputWrap>
                                  <SportsMixedSettingSelect
                                    {...input}
                                    value={sportTypeValue || 6046}
                                    onChange={option => {
                                      input.onChange(option)
                                      onSportTypeChangeHandler(option)
                                    }}
                                    options={sportTypeOptions}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </FormInputWrap>
                              )}
                            </Field>
                          </BettingFormGroupField>
                        </div>
                      </div>
                    </BettingSecondFormWrap>
                    <BettingSecondFormWrap>
                      <BettingSecondFormLabel>마켓조합</BettingSecondFormLabel>
                      <BettingFormGroupField style={{ display: 'flex', flexDirection: 'column' }}>
                        <Field name="marketCombinationId">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={marketCombinationId}
                                onChange={e => {
                                  const newValue = e.target.value
                                  // 입력 값이 유효한지 확인
                                  if (/^[0-9+x]*$/.test(newValue)) {
                                    // 유효한 경우에만 값 업데이트
                                    input.onChange(newValue)
                                    setMarketCombinationId(newValue)
                                  }
                                }}
                                placeholder="마켓ID를 수동입력 및 마켓리스트에서 선택하세요."
                                style={{ width: '100%', height: '40px' }}
                              />
                            </FormInputWrap>
                          )}
                        </Field>
                        <div style={{ fontSize: '11px' }}>Hint: 무승배당은 x 로 입력해주세요.</div>
                      </BettingFormGroupField>
                    </BettingSecondFormWrap>
                    <BettingSecondFormWrap>
                      <BettingSecondFormLabel>마켓설명</BettingSecondFormLabel>
                      <BettingFormGroupField>
                        <Field name="marketCombinationName">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={marketCombinationName}
                                disabled
                                placeholder="마켓설명."
                                style={{ width: '100%', height: '40px' }}
                              />
                            </FormInputWrap>
                          )}
                        </Field>
                      </BettingFormGroupField>
                    </BettingSecondFormWrap>
                    <BettingSecondFormWrap>
                      <BettingSecondFormLabel>배당조정(±)</BettingSecondFormLabel>
                      <BettingFormGroupField style={{ display: 'flex', flexDirection: 'column' }}>
                        <Field name="marketCombinationPer">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={marketCombinationPer}
                                onChange={e => {
                                  handleChange(e)
                                  input.onChange(e) // react-final-form의 input.onChange 호출
                                }}
                                placeholder="0"
                                style={{ width: '100%', height: '40px' }}
                              />
                            </FormInputWrap>
                          )}
                        </Field>
                        <div style={{ fontSize: '11px' }}>
                          Hint: 조합배팅인 경우 ±로 배당조정을 하며, 0일경우 배당조정은 없습니다.
                        </div>
                      </BettingFormGroupField>
                    </BettingSecondFormWrap>
                    <FormButtonToolbar style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                      <>
                        {
                          {
                            SUPER: (
                              <Button variant="primary" type="submit">
                                추가하기
                              </Button>
                            ),
                          }[authority]
                        }
                      </>
                    </FormButtonToolbar>
                  </FormContainer>
                )}
              </Form>
            </BettingSecondWrap>
            <BettingTitleWrap2>조합베팅 목록</BettingTitleWrap2>
            <BettingFirstWrap>
              <SearchContainer horizontal>
                <SearchGroup>
                  <SearchGroupLabel>종목</SearchGroupLabel>
                  <SearchGroupField>
                    <SearchSelect
                      name="searchGameResult"
                      placeholder="전체"
                      onChange={option => {
                        onSelectSportIdTypeOptionHandler(option)
                      }}
                      value={searchSportIdOptionValue}
                      options={searchSportIdTypeOption}
                      className="react-select"
                      classNamePrefix="react-select"
                      isClearable
                    />
                  </SearchGroupField>
                </SearchGroup>
                <SearchButtonWrap>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      onSearchClickHandler()
                    }}
                  >
                    검색하기
                  </Button>
                </SearchButtonWrap>
              </SearchContainer>
              <table>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>타입</th>
                    <th>종목</th>
                    <th>배팅조합</th>
                    <th>배당조정</th>
                    <th>사용/제거</th>
                  </tr>
                </thead>
                {modifyModalShow && (
                  <MarketCombinationModifyModal
                    marketCombintationInfo={marketCombintationInfo}
                    modifyModalShow={modifyModalShow}
                    modifyModalHandler={modifyModalHandler}
                    fetchSportsMixed={fetchSportsMixed}
                  />
                )}
                {content.map((item, index) => (
                  <tbody key={item.marketCombinationKey}>
                    <tr>
                      <td>{item.num}</td> {/* 번호 */}
                      <CustomTd sportMarketType={item.sportMarketType}>
                        {SportsMarketTypeEnums[item.sportMarketType]}
                      </CustomTd>
                      <td>
                        <img
                          style={{ width: '20px', height: 'auto' }}
                          src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${item.sportId}.png`}
                          alt={SportIdTypeEnums[item.sportId]}
                        />
                        &nbsp;&nbsp;
                        {item.sportName}
                      </td>{' '}
                      {/* 종목 */}
                      <CustomTd2
                        onClick={() => {
                          modifyModalHandler(item)
                        }}
                      >
                        <TooltipWrapper text={item.marketCombinationNames} place="bottom">
                          <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>
                            {item.marketCombinationIds}
                          </span>
                        </TooltipWrapper>
                      </CustomTd2>
                      <td>
                        <span
                          style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}
                          onClick={() => {
                            modifyModalHandler(item)
                          }}
                        >
                          {item.marketCombinationPer}
                        </span>
                      </td>
                      {/* 배팅조합(marketCombinationIds) */}
                      <td>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                          {
                            {
                              true: (
                                <span style={{ cursor: 'pointer' }}>
                                  <FiEye
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onChangeIsUsed(item.marketCombinationKey, item.isUsed)
                                    }}
                                  />
                                </span>
                              ),
                              false: (
                                <span style={{ cursor: 'pointer' }}>
                                  <FiEyeOff
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onChangeIsUsed(item.marketCombinationKey, item.isUsed)
                                    }}
                                  />
                                </span>
                              ),
                            }[item.isUsed]
                          }
                          <CustomButton
                            onClick={() => onDeleteSubmit(item.marketCombinationKey)}
                            style={{ background: 'red' }}
                          >
                            삭제
                          </CustomButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>

              <PaginationWrap>
                <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              </PaginationWrap>
            </BettingFirstWrap>
          </BettingSettingWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default SportsMixedManagement

const getColor = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#ffffab'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomInput = styled.input`
  width: 50px;
  border: solid 1px rgb(118, 118, 118);
  background-color: ${props => (props.disabled ? '#e0e0e0' : 'white')};
`

const AllChangeDiv = styled.div`
  display: flex;
  width: max-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  margin-left: 10px;
  gap: 10px;
`

const AllChangeDiv2 = styled.div`
  display: flex;
  flex-direction: column;
`

const AllChangeDiv3 = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1px;
`

const CustomDiv = styled.div`
  display: flex;
  width: max-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 25px;
    height: 25px;
  }
`

const CustomTd = styled.td`
  background: ${props => getColor(props.sportMarketType)};
`
const CustomButton = styled.button`
  width: 35px;
  height: 20px;
  color: white;
  border: none;
`

const CustomTd2 = styled.td`
  background: #30e0ff4f;
  span {
    text-decoration: underline !important;
  }

  cursor: pointer;
  color: #2663a3;
`
const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const BettingTitleWrap2 = styled.div`
  width: 100%;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 10px;
  display: flex;
`

const BettingTitleWrap = styled.div`
  width: 100%;
  background: #000;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 10px;
`

const BettingSettingWrap = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px;
`

const BettingFirstWrap = styled.div`
  width: 100%;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 11px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 11px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 11px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const BettingSecondWrap = styled.div`
  width: 100%;
`

const BettingSecondFormWrap = styled.div`
  width: 100%;
  display: flex;
  // border: solid 1px black;
  // padding: 0px;
`

const BettingFormGroupField = styled.div`
  width: 100%;
`

const BettingSecondFormLabel = styled.span`
  width: 100px;
  height: 40px !important;
  background: #eee;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500 !important;
  color: black;
`
