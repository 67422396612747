import React, { useEffect, useState } from 'react'
import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import Error from '@/shared/components/form/Error'
import { borderRight } from '@/utils/directions'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import PropTypes from 'prop-types'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import { decodeAccessToken } from '../../../../../utils/token'
import {
  SportTypeEnums,
  EventStatusEnumsSelectOptions,
  eventStatusEnums,
  SoccerScoreEnums,
  getScoreEnums,
} from '../../../../../enums/SystemManagement/sportEnums'
import { GameSettingSelect } from '../../../GameSettingManagement/GameSettingSelect'
import { searchSportsEventResult } from '../../../../../utils/api/sportsManage/sportsInfo'
import { updateSportsEventResult } from '../../../../../utils/api/sport/sportIInfoApi'

function ResultRegistrationForm({ onClickHandler, resultEventId, sportsType }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [resultFlag, setResultFlag] = useState(false)
  const [sportsGameName, setSportsGameName] = useState(null)
  const [sportsGameTime, setSportsGameTime] = useState(null)
  const [sportsGameStatus, setSportsGameStatus] = useState(null)
  const [homeScoreInfo, setHomeScroeInfo] = useState({})
  const [awayScoreInfo, setAwayScroeInfo] = useState({})

  const [homeName, setHomeName] = useState(null)
  const [awayName, setAwayName] = useState(null)
  const [sportStatusValue, setSportStatusValue] = useState(null)
  const sportStatusOptions = EventStatusEnumsSelectOptions()
  const onSportStatusChangeHandler = option => {
    setSportStatusValue(option)
  }
  const fetchSearchSportsEventResult = async () => {
    searchSportsEventResult({
      eventId: resultEventId,
      sportType: sportsType,
    }).then(res => {
      setResultFlag(res.data.resultFlag)

      const eventTime = new Date(res.data.sportEventTime * 1000) // 초 단위 값을 밀리초로 변환
      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000 // 시간대 오프셋
      const sportsGameTimes = new Date(eventTime.getTime() + timezoneOffset + 9 * 60 * 60 * 1000) // 시간대 오프셋과 9시간 차이를 반영하여 최종 시간 계산
      setHomeName(res.data.homeName)
      setAwayName(res.data.awayName)
      setSportsGameName(res.data.sportEventName)
      setSportsGameTime(sportsGameTimes)
      setSportStatusValue({ value: res.data.sportEventStatus, label: eventStatusEnums[res.data.sportEventStatus] })
      setSportsGameStatus(res.data.sportEventStatus)
      setHomeScroeInfo(res.data.homeScoreInfo)
      setAwayScroeInfo(res.data.awayScoreInfo)
    })
  }

  useEffect(() => {
    fetchSearchSportsEventResult()
  }, [])

  const [writeLoading, setWriteLoading] = useState(false)

  const onChangeHomeScoreHandler = (newScore, scoreKey) => {
    if (scoreKey in homeScoreInfo) {
      setHomeScroeInfo(prev => ({
        ...prev,
        [scoreKey]: newScore,
      }))
    }
  }

  const onChangeAwayScoreHandler = (newScore, scoreKey) => {
    if (scoreKey in awayScoreInfo) {
      setAwayScroeInfo(prev => ({
        ...prev,
        [scoreKey]: newScore,
      }))
    }
  }
  const onSubmit = e => {}
  const onMiddleSubmit = e => {
    if (writeLoading) return
    if (sportStatusValue.value === '경기종료' || sportStatusValue.value === '경기취소') {
      alert('경기상태값이 "경기종료" 또는 "경기취소"인 상태에서 중간등록은 불가합니다.')
      return
    }
    if (
      window.confirm(
        '경기 결과처리를 수동처리 하시겠습니까?\n "경기상태"값을 "경기종료"로 바꾸면 더이상 수정이 불가합니다.',
      )
    ) {
      setWriteLoading(true)
      const body = {
        sportsEventId: resultEventId,
        sportEventStatus: sportStatusValue.value,
        homeScoreInfo,
        awayScoreInfo,
        resultFlag: false,
      }

      updateSportsEventResult(body)
        .then(res => {
          alert('경기결과가 수정됐습니다.')
          onClickHandler()
          setWriteLoading(false)
        })
        .catch(error => {
          setWriteLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SPORTS-1008') {
            alert(`${message}`)
          } else if (errorCode === 'SPORTS-1009') {
            alert(`${message}`)
          } else {
            alert('경기결과가 수정이 실패했습니다.')
          }
          fetchSearchSportsEventResult()
        })
    }
  }

  const onFinalSubmit = e => {
    // 경기상태값이 경기종료일때 return

    if (writeLoading) return

    if (sportStatusValue.value !== '경기종료' && sportStatusValue.value !== '경기취소') {
      alert('결과 최종등록은 "경기상태"값을 "경기종료" 또는 "경기취소"로 선택해주세요.')
      return
    }

    if (
      window.confirm(
        '경기 결과처리를 최종처리 하시겠습니까?\ncf) 최종등록 후 수정은 불가능하며, 해당 결과 기반으로 결과처리가 됩니다.',
      )
    ) {
      setWriteLoading(true)
      const body = {
        sportsEventId: resultEventId,
        sportEventStatus: sportStatusValue.value,
        homeScoreInfo,
        awayScoreInfo,
        resultFlag: true,
      }

      updateSportsEventResult(body)
        .then(res => {
          alert('경기결과가 수정됐습니다.')
          onClickHandler()
          setWriteLoading(false)
        })
        .catch(error => {
          setWriteLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SPORTS-1008') {
            alert(`${message}`)
          } else if (errorCode === 'SPORTS-1009') {
            alert(`${message}`)
          } else {
            alert('경기결과가 수정이 실패했습니다.')
          }
          fetchSearchSportsEventResult()
        })
    }
  }

  const getScoreResultComponent = () => {
    const scoreEnum = getScoreEnums(sportsType)

    return (
      <CustomWrap>
        <CustomTitleWrap>경기 점수</CustomTitleWrap>
        <CustomContentWrap length={Object.keys(scoreEnum).length + 1}>
          <CustomTableTitle>&nbsp;</CustomTableTitle>
          {Object.entries(homeScoreInfo).map(([key, value]) => {
            return <>{scoreEnum[key] && <CustomTableTitle>{scoreEnum[key]}</CustomTableTitle>}</>
          })}
        </CustomContentWrap>
        <CustomContentWrap length={Object.keys(scoreEnum).length + 1}>
          <CustomTableTeam>원정 ({awayName})</CustomTableTeam>
          {Object.entries(awayScoreInfo).map(([key, value]) => {
            return (
              <>
                {scoreEnum[key] && (
                  <CustomTableContent>
                    <Field name="memberId">
                      {({ input, meta }) => (
                        <CustomTitleFormInputWrap>
                          <CustomInput
                            {...input}
                            type="text"
                            value={value}
                            onChange={e => {
                              const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                              onChangeAwayScoreHandler(onlyNumber, key)
                            }}
                            disabled={sportsGameStatus === '경기종료'}
                          />
                        </CustomTitleFormInputWrap>
                      )}
                    </Field>
                  </CustomTableContent>
                )}
              </>
            )
          })}
        </CustomContentWrap>
        <CustomContentWrap length={Object.keys(scoreEnum).length + 1}>
          <CustomTableTeam>홈 ({homeName})</CustomTableTeam>
          {Object.entries(homeScoreInfo).map(([key, value]) => {
            return (
              <>
                {scoreEnum[key] && (
                  <CustomTableContent>
                    <Field name="memberId">
                      {({ input, meta }) => (
                        <CustomTitleFormInputWrap>
                          <CustomInput
                            {...input}
                            type="text"
                            value={value}
                            onChange={e => {
                              const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                              onChangeHomeScoreHandler(onlyNumber, key)
                            }}
                            disabled={sportsGameStatus === '경기종료'}
                          />
                        </CustomTitleFormInputWrap>
                      )}
                    </Field>
                  </CustomTableContent>
                )}
              </>
            )
          })}
        </CustomContentWrap>
      </CustomWrap>
    )
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <div style={{ marginBottom: '30px' }}>
            <p>
              * 경기 상태가{' '}
              <span style={{ color: 'red', fontWeight: '500', fontSize: '14px' }}>경기종료, 경기취소</span>일 경우
              수정은 불가합니다.
            </p>
            <p>
              * <span style={{ color: 'red', fontWeight: '500', fontSize: '14px' }}>결과 최종등록 시</span> 해당 결과
              기반으로 결과처리가 됩니다.
            </p>
          </div>
          <FormGroup>
            <FormGroupLabel>경기시간</FormGroupLabel>
            <FormGroupField>
              <DatePicker
                selected={sportsGameTime}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="클릭하세요"
                locale={ko}
                disabled
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>경기종목</FormGroupLabel>
            <FormGroupField>
              <Field
                name="sportsType"
                component="input"
                type="text"
                defaultValue={SportTypeEnums[sportsType]}
                disabled
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>경기명</FormGroupLabel>
            <FormGroupField>
              <input name="searchUserId" type="text" placeholder="입력해주세요" value={sportsGameName} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>경기상태</FormGroupLabel>
            <FormGroupField>
              <Field name="sportType">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <GameSettingSelect
                      {...input}
                      value={sportStatusValue}
                      onChange={option => {
                        input.onChange(option)
                        onSportStatusChangeHandler(option)
                      }}
                      options={sportStatusOptions}
                      className="react-select"
                      classNamePrefix="react-select"
                      isDisabled={sportsGameStatus === '경기종료'}
                    />
                    {meta.touched && meta.error && <Error error={meta.error} top />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <CustomTableInfo>
            * 수동으로 결과처리 시, 값이 없을 경우 0을 입력하지 마시고,{' '}
            <span style={{ color: 'red', fontSize: '17px' }}>반드시 빈칸</span>으로 놔둬주세요.
          </CustomTableInfo>
          {getScoreResultComponent()}
          <FormButtonToolbar style={{ marginLeft: '-10px' }}>
            <>
              {(authority === 'SUPER' || authority === 'DB_DOWN') &&
                sportsGameStatus !== '경기종료' &&
                sportsGameStatus !== '경기취소' && (
                  <>
                    <Button
                      variant="primary"
                      onClick={() => {
                        onMiddleSubmit()
                      }}
                    >
                      결과 중간등록
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        onFinalSubmit()
                      }}
                    >
                      결과 최종등록
                    </Button>
                  </>
                )}
            </>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default ResultRegistrationForm

ResultRegistrationForm.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  resultEventId: PropTypes.number.isRequired,
  sportsType: PropTypes.string.isRequired,
}

export const CustomInput = styled.input`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  color: black;
`

export const CustomTitleFormInputWrap = styled(FormInputWrap)`
  width: 100%;
`

export const CustomTitleWrap = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 25px;
  font-weight: 900;
  border-bottom: solid 2px #eee;
  margin-bottom: 5px;
`

export const CustomTableTeam = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 900;
  border-right: solid 2px #eee;
  border-top: solid 2px #eee;
  border-bottom: solid 2px #eee;
`

export const CustomTableInfo = styled.div`
  width: 100%;
  color: black;
  font-size: 13px;
  font-weight: 600;
`

export const CustomTableTitle = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  border-right: solid 1px #666869;
  background: #eee;
`

export const CustomTableContent = styled.div`
  width: 100%;
  border-right: solid 2px #eee;
  border-top: solid 2px #eee;
  border-bottom: solid 2px #eee;
`

export const CustomWrap = styled.div`
  width: 100%;
`

export const CustomContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.length && `repeat(${props.length}, minmax(0, 1fr))`};
`

// 1대1 문의 Select
export const OneToOneSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  // editor 때문에 추가함
  z-index: 101;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

// endregion
