import React, { useEffect, useState } from 'react'
import { Card, CardBody } from '@/shared/components/Card'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { Button } from 'react-bootstrap'
import Switch from 'react-switch'
import Loading from '../../shared/components/Loading'
import { searchHoldemInfo, updateHoldemInfo } from '../../utils/api/holdem/holdemApi'
import { decodeAccessToken } from '../../utils/token'

const WildGamesGameInfoPage = () => {
  const authority = decodeAccessToken()?.adminAuthority

  const [loading, setLoading] = useState(false)

  const [holdemInfo, setHoldemInfo] = useState({})

  const fetchsearchHoldemInfo = () => {
    setLoading(true)

    searchHoldemInfo({
      agencyName: 'WILD_GAMES',
    })
      .then(res => {
        setHoldemInfo(res.data)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchsearchHoldemInfo()
  }, [])

  const onClickHoldemInfoUpdateHandler = () => {
    if (loading) return

    if (window.confirm('와일드 게임즈 정보를 변경하시겠습니가?')) {
      const body = {
        holdemInfoKey: holdemInfo.holdemInfoKey,
        title: holdemInfo.title,
        isShow: holdemInfo.isShow,
        isInspection: holdemInfo.isInspection,
      }

      setLoading(true)

      updateHoldemInfo(body)
        .then(res => {
          alert('정보가 변경되었습니다.')
        })
        .catch(error => {
          alert('정보가 변경에 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <InfoBoxWrap>
        <p style={{ background: '#fff79e' }}>
          * <span style={{ color: 'red' }}>노출여부</span> 설정에 따라 유저페이지 현시 여부가 결정이 됩니다.
          <br />* <span style={{ color: 'red' }}>현시 이름</span> 변경 시, 유저페이지에 변경된 이름으로 보여집니다.
        </p>
      </InfoBoxWrap>

      <HoldemManageWrapper>
        <HoldemManageWrapperHeader>와일드 게임즈</HoldemManageWrapperHeader>

        <Form
          onSubmit={onClickHoldemInfoUpdateHandler}
          initialValues={{
            holdemInfo,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <HoldemInfoForm horizontal onSubmit={handleSubmit}>
              <HoldemInfoFormInnerBox>
                <table>
                  <tr>
                    {/* <th scope="row">노출여부</th>
                        <td>
                          <CustomSwitch
                            onChange={() => {
                              setHoldemInfo(prev => ({
                                ...prev,
                                isShow: !holdemInfo?.isShow ?? false,
                              }))
                            }}
                            checked={holdemInfo?.isShow ?? false}
                          />
                        </td> */}
                    <th scope="row">점검여부</th>
                    <td>
                      <CustomSwitch
                        onChange={() => {
                          setHoldemInfo(prev => ({
                            ...prev,
                            isInspection: !holdemInfo?.isInspection ?? false,
                          }))
                        }}
                        checked={holdemInfo?.isInspection ?? false}
                      />
                    </td>
                    <th scope="row">현시 이름</th>
                    <td>
                      <Field name="title">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="text"
                            value={holdemInfo.title}
                            onChange={e => {
                              setHoldemInfo(prev => ({
                                ...prev,
                                title: e.target.value,
                              }))
                            }}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                </table>
              </HoldemInfoFormInnerBox>
              {(authority === 'SUPER' || authority === 'DB_DOWN') && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <Button size="lg" variant="outline-secondary" type="submit">
                    변경하기
                  </Button>
                </div>
              )}
            </HoldemInfoForm>
          )}
        </Form>
      </HoldemManageWrapper>
    </>
  )
}

export default WildGamesGameInfoPage

const InfoBoxWrap = styled.div`
  width: 100%;
  padding: 5px 0;

  p {
    margin-bottom: 10px;
  }
`

const HoldemManageWrapperHeader = styled.div`
  width: 100%;
  font-size: 23px;
  color: blue;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 10px;
`

const HoldemManageWrapper = styled.div`
  width: 100%;
  min-height: 200px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-top: 15px;
  }

  p {
    margin-bottom: 10px;
  }
`

const HoldemInfoForm = styled.form`
  display: flex;
  flex-direction: column;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #000;
    color: #646777;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const HoldemInfoFormInnerBox = styled.div`
  width: 100%;

  table {
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      text-align: right;
      padding-right: 15px;
    }

    td {
      padding: 10px;
    }
  }
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
