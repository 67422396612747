import { Button } from '@/shared/components/Button'
import { Card, CardBody } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { casinoVendorEnums } from '../../../enums/SystemManagement/casinoEnums'
import Loading from '../../../shared/components/Loading'
import {
  searchDefaultCasinoVendorManage,
  updateDefaultCasinoVendorManage,
} from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
// import validate from '../validate'

const DefaultCasinoMangeMent = ({ edgeName }) => {
  /**
   시스템설정 조회
   */
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  const [casinoVendorInfos, setCasinoVendorInfos] = useState({
    liveVendors: [],
    slotVendors: [],
  })

  const fetchUserCasinoVendorInfo = async () => {
    await searchDefaultCasinoVendorManage({ edgeName })
      .then(res => {
        setCasinoVendorInfos({
          liveVendors: res.data.liveVendors,
          slotVendors: res.data.slotVendors,
        })
      })
      .catch(error => {})
  }

  useEffect(() => {
    if (!edgeName) return
    fetchUserCasinoVendorInfo()
  }, [edgeName])

  const onCheckVendorIsUsedHandler = (vendorType, switchingVendorName, agencyName, isChecked) => {
    // vendorType에 따라 배열을 선택합니다.
    const vendorList = vendorType === 'LIVE' ? casinoVendorInfos.liveVendors : casinoVendorInfos.slotVendors

    // 배열을 순회하며 해당 업체를 찾습니다.
    const updatedVendorList = vendorList.map(vendor => {
      if (vendor.switchingVendorName === switchingVendorName) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            [agencyName]: isChecked,
          },
        }
      }
      return vendor
    })

    // 상태를 업데이트합니다.
    if (vendorType === 'LIVE') {
      setCasinoVendorInfos(prevState => ({
        ...prevState,
        liveVendors: updatedVendorList,
      }))
    } else {
      setCasinoVendorInfos(prevState => ({
        ...prevState,
        slotVendors: updatedVendorList,
      }))
    }
  }

  const onSubmit = e => {
    // if (writeLoading) return
    // if (window.confirm('기본값 설정을 변경 하시겠습니까?')) {
    //   const body = {
    //     edgeName,
    //     userSkinNum: e.userSkinNum,
    //     miniGameMaxBetting: e.miniGameMaxBetting?.toString().replaceAll(',', ''),
    //     miniGameMinBetting: e.miniGameMinBetting?.toString().replaceAll(',', ''),
    //     miniGameMaxWinning: e.miniGameMaxWinning?.toString().replaceAll(',', ''),
    //     withdrawlRollingStatus: e.withdrawlRollingStatus,
    //     losingSettlementStatus: e.losingSettlementStatus,
    //     userLosingPointExchangeAutoStatus: e.userLosingPointExchangeAutoStatus,
    //     depositBonusStatus: e.depositBonusStatus,
    //     recommendCodeStatus: e.recommendCodeStatus,
    //     userExchangeDateStatus: e.userExchangeDateStatus,
    //     settlementExchangeStatus: e.settlementExchangeStatus,
    //     moneyIncExchangeStatus: e.moneyIncExchangeStatus,
    //     moneyDecExchangeStatus: e.moneyDecExchangeStatus,
    //     duplicatedSignInStatus: e.duplicatedSignInStatus,
    //   }
    //   setWriteLoading(true)
    //   updateDefaultBettingManage(body)
    //     .then(res => {
    //       setWriteLoading(false)
    //       alert('기본값 일괄설정이 변경됐습니다.')
    //       fetchSearchDefaultBettingManage()
    //     })
    //     .catch(error => {
    //       setWriteLoading(false)
    //       alert('기본값 일괄설정이 변경이 실패했습니다.')
    //     })
    // }
  }

  // HONOR_LINK 전체 선택/해제 함수
  const toggleAllLiveHonorLink = () => {
    const relevantVendors = casinoVendorInfos.liveVendors.filter(vendor => 'HONOR_LINK' in vendor.agencyShow)
    const allSelected = relevantVendors.length > 0 && relevantVendors.every(vendor => vendor.agencyShow.HONOR_LINK)

    const updatedLiveVendors = casinoVendorInfos.liveVendors.map(vendor => {
      if ('HONOR_LINK' in vendor.agencyShow) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            HONOR_LINK: !allSelected,
          },
        }
      }
      return vendor
    })

    setCasinoVendorInfos(prevState => ({
      ...prevState,
      liveVendors: updatedLiveVendors,
    }))
  }

  // SLOT_CITY 전체 선택/해제 함수
  const toggleAllLiveSlotCity = () => {
    const relevantVendors = casinoVendorInfos.liveVendors.filter(vendor => 'SLOT_CITY' in vendor.agencyShow)
    const allSelected = relevantVendors.length > 0 && relevantVendors.every(vendor => vendor.agencyShow.SLOT_CITY)

    const updatedLiveVendors = casinoVendorInfos.liveVendors.map(vendor => {
      if ('SLOT_CITY' in vendor.agencyShow) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            SLOT_CITY: !allSelected,
          },
        }
      }
      return vendor // Keep vendors that don't have SLOT_CITY unchanged
    })

    setCasinoVendorInfos(prevState => ({
      ...prevState,
      liveVendors: updatedLiveVendors,
    }))
  }

  // HONOR_LINK 전체 선택/해제 함수
  const toggleAllSlotHonorLink = () => {
    const relevantVendors = casinoVendorInfos.slotVendors.filter(vendor => 'HONOR_LINK' in vendor.agencyShow)
    const allSelected = relevantVendors.length > 0 && relevantVendors.every(vendor => vendor.agencyShow.HONOR_LINK)

    const updatedSlotVendors = casinoVendorInfos.slotVendors.map(vendor => {
      if ('HONOR_LINK' in vendor.agencyShow) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            HONOR_LINK: !allSelected,
          },
        }
      }
      return vendor // Keep vendors that don't have SLOT_CITY unchanged
    })

    setCasinoVendorInfos(prevState => ({
      ...prevState,
      slotVendors: updatedSlotVendors,
    }))
  }

  // SLOT_CITY 전체 선택/해제 함수
  const toggleAllSlotSlotCity = () => {
    const relevantVendors = casinoVendorInfos.slotVendors.filter(vendor => 'SLOT_CITY' in vendor.agencyShow)
    const allSelected = relevantVendors.length > 0 && relevantVendors.every(vendor => vendor.agencyShow.SLOT_CITY)

    const updatedSlotVendors = casinoVendorInfos.slotVendors.map(vendor => {
      if ('SLOT_CITY' in vendor.agencyShow) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            SLOT_CITY: !allSelected,
          },
        }
      }
      return vendor // Keep vendors that don't have SLOT_CITY unchanged
    })

    setCasinoVendorInfos(prevState => ({
      ...prevState,
      slotVendors: updatedSlotVendors,
    }))
  }

  const toggleAllSlotGoldLink = () => {
    const relevantVendors = casinoVendorInfos.slotVendors.filter(vendor => 'GOLD_LINK_SLOT' in vendor.agencyShow)
    const allSelected = relevantVendors.length > 0 && relevantVendors.every(vendor => vendor.agencyShow.GOLD_LINK_SLOT)

    const updatedSlotVendors = casinoVendorInfos.slotVendors.map(vendor => {
      if ('GOLD_LINK_SLOT' in vendor.agencyShow) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            GOLD_LINK_SLOT: !allSelected,
          },
        }
      }
      return vendor // Keep vendors that don't have SLOT_CITY unchanged
    })

    setCasinoVendorInfos(prevState => ({
      ...prevState,
      slotVendors: updatedSlotVendors,
    }))
  }

  const onClickDefaultCasinoVendorInfoUpdateHandler = ({ allUser }) => {
    if (writeLoading) return

    const confirmationMessage = allUser
      ? `[${edgeName}]업체 현재 모든 유저 + 회원가입 시 카지노 업체 설정을 변경하시겠습니까?\n유저가 많을 수록 시간이 오래 걸릴 수 있습니다.`
      : `[${edgeName}]업체 회원가입 시 카지노 업체 설정을 변경하시겠습니까?`

    if (window.confirm(confirmationMessage)) {
      setWriteLoading(true)
      const body = {
        edgeName,
        allUser,
        casinoVendorInfo: JSON.stringify(casinoVendorInfos),
      }

      updateDefaultCasinoVendorManage(body)
        .then(res => {
          setWriteLoading(false)
          fetchUserCasinoVendorInfo()
          alert('변경되었습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          alert('변경 실패했습니다. 잠시후 다시 시도해주세요.')
        })
    }
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody style={{ padding: '0px' }}>
          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <h3 style={{ margin: '15px 0 0 0', fontWeight: '500' }}>카지노 업체 설정</h3>
              {writeLoading && <Loading />}
              <HaederInfo>* 업체별로 체크 후 변경하기 버튼을 꼭 눌러주세요. </HaederInfo>
              <HaederInfo>* 중복 체크시 유저에게 이중으로 보이니 주의바랍니다. </HaederInfo>

              <UserCasinoVendorInfoUpdateBtn
                variant="primary"
                type="button"
                size="lg"
                onClick={e => {
                  onClickDefaultCasinoVendorInfoUpdateHandler({ allUser: false })
                }}
              >
                변경하기
              </UserCasinoVendorInfoUpdateBtn>
              <UserCasinoVendorInfoUpdateBtn
                variant="primary"
                type="button"
                size="lg"
                onClick={e => {
                  onClickDefaultCasinoVendorInfoUpdateHandler({ allUser: true })
                }}
              >
                변경 + 모든유저 적용
              </UserCasinoVendorInfoUpdateBtn>

              {casinoVendorInfos?.liveVendors?.length > 0 && (
                <>
                  <AgencyHeaderInfo>
                    [ 라이브 ]{' '}
                    <HaederButtonWrap>
                      <Button
                        active
                        size="sm"
                        variant="outline-secondary"
                        style={{ height: '30px', marginTop: '5px' }}
                        onClick={toggleAllLiveHonorLink}
                      >
                        HONOR링크 전체선택
                      </Button>
                      <Button
                        active
                        size="sm"
                        variant="outline-secondary"
                        style={{ height: '30px', marginTop: '5px' }}
                        onClick={toggleAllLiveSlotCity}
                      >
                        슬롯시티 전체선택
                      </Button>
                    </HaederButtonWrap>
                  </AgencyHeaderInfo>
                  <VendorInfoWrap>
                    {casinoVendorInfos.liveVendors.map((vendor, index) => (
                      <VendorInfoBox key={`${vendor.switchingVendorName}`}>
                        <VendorInfoNameBox>
                          <div>{vendor.switchingVendorName}</div>
                          <div>({vendor.switchingVendorNameKr})</div>
                        </VendorInfoNameBox>
                        <VendorInfoCheckBox>
                          {Object.entries(vendor.agencyShow).map(([key, value], idx) => {
                            return (
                              <label htmlFor={`liveVenodr${vendor.switchingVendorName}${idx}`}>
                                <span> {casinoVendorEnums[key]} </span>
                                <input
                                  type="checkbox"
                                  id={`liveVenodr${vendor.switchingVendorName}${idx}`}
                                  checked={value}
                                  onChange={e => {
                                    onCheckVendorIsUsedHandler(
                                      vendor.vendorType,
                                      vendor.switchingVendorName,
                                      key,
                                      e.target.checked,
                                    )
                                  }}
                                />
                              </label>
                            )
                          })}
                        </VendorInfoCheckBox>
                      </VendorInfoBox>
                    ))}
                  </VendorInfoWrap>
                </>
              )}

              {casinoVendorInfos?.slotVendors?.length > 0 && (
                <>
                  <AgencyHeaderInfo>
                    [ 슬롯 ]{' '}
                    <HaederButtonWrap>
                      <Button
                        active
                        size="sm"
                        variant="outline-secondary"
                        style={{ height: '30px', marginTop: '5px' }}
                        onClick={toggleAllSlotHonorLink}
                      >
                        HONOR링크 전체선택
                      </Button>
                      <Button
                        active
                        size="sm"
                        variant="outline-secondary"
                        style={{ height: '30px', marginTop: '5px' }}
                        onClick={toggleAllSlotGoldLink}
                      >
                        GOLD_LINK 전체선택
                      </Button>
                      <Button
                        active
                        size="sm"
                        variant="outline-secondary"
                        style={{ height: '30px', marginTop: '5px' }}
                        onClick={toggleAllSlotSlotCity}
                      >
                        슬롯시티 전체선택
                      </Button>
                    </HaederButtonWrap>
                  </AgencyHeaderInfo>
                  <VendorInfoWrap>
                    {casinoVendorInfos.slotVendors.map((vendor, index) => (
                      <VendorInfoBox key={`${vendor.switchingVendorName}`}>
                        <VendorInfoNameBox>
                          <div>{vendor.switchingVendorName}</div>
                          <div>({vendor.switchingVendorNameKr})</div>
                        </VendorInfoNameBox>
                        <VendorInfoCheckBox>
                          {Object.entries(vendor.agencyShow).map(([key, value], idx) => {
                            return (
                              <label htmlFor={`slotVenodr${vendor.switchingVendorName}${idx}`}>
                                <span> {casinoVendorEnums[key]} </span>
                                <input
                                  type="checkbox"
                                  id={`slotVenodr${vendor.switchingVendorName}${idx}`}
                                  checked={value}
                                  onChange={e => {
                                    onCheckVendorIsUsedHandler(
                                      vendor.vendorType,
                                      vendor.switchingVendorName,
                                      key,
                                      e.target.checked,
                                    )
                                  }}
                                />
                              </label>
                            )
                          })}
                        </VendorInfoCheckBox>
                      </VendorInfoBox>
                    ))}
                  </VendorInfoWrap>
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

DefaultCasinoMangeMent.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

export default DefaultCasinoMangeMent

const HaederButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  button {
    font-size: 12px;
  }
`

const HaederInfo = styled.p`
  font-size: 14px;
`

const AgencyHeaderInfo = styled.div`
  width: 100%;
  color: black;
  font-size: 25px;
  font-weight: 500;
  display: flex;
  gap: 10px;
`

const VendorTypeInfo = styled.div`
  width: 100%;
  color: black;
  font-size: 24px;
  font-weight: 500;

  span {
    font-size: 12px;
    color: red;
  }
`

const VendorInfoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`

const VendorInfoBox = styled.div`
  display: flex;

  // 첫번째 줄 보더
  &:nth-child(n + 1):nth-child(-n + 4) {
    border-top: 1px solid lightgrey;
  }

  // 맨 왼쪽 보더
  &:nth-child(4n + 1) {
    border-left: 1px solid lightgrey;
  }

  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  border-collapse: collapse;
`

const VendorInfoNameBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-right: 1px solid lightgrey;
`

const VendorInfoCheckBox = styled.div`
  min-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  label {
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0 5px;
    align-items: center;
    cursor: pointer;

    input {
      cursor: pointer;
    }

    span {
      display: block;
      width: max-content;
      color: black;
      font-size: 12px;
    }
  }
`
const UserCasinoVendorInfoUpdateBtn = styled(Button)`
  margin: 10px;
  border: none;
`
