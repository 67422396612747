import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { PopupStatusEnumsRadioOptions } from '../../../enums/Operation/popupEnums'
import Loading from '../../../shared/components/Loading'
import { deletePopup, updatePopupList } from '../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import './Popup.css'

function PopupModifyForm({
  fetchSearchPopupList,
  edgeName,
  popupKey,
  onClickHandler,
  title,
  imgName,
  domainUrl,
  target,
  status,
  createAt,
}) {
  const { domainCheckBox } = useSelector(state => {
    const { headerInfo } = state

    return {
      domainCheckBox: headerInfo.domainCheckBox,
    }
  })
  const [selectedDomains, setSelectedDomains] = useState(domainUrl ? domainUrl.split(',') : [])

  const [searchRegistrationDate, setSearchRegistrationDate] = useState(new Date(createAt))
  const onSearchRegistrationDateChangeHandler = date => {
    setSearchRegistrationDate(date)
  }

  useEffect(() => {
    // domainUrl이 변경될 때마다 selectedDomains를 업데이트
    setSelectedDomains(domainUrl ? domainUrl.split(',') : [])
  }, [domainUrl])

  const handleDomainChange = domainName => {
    setSelectedDomains(prev => {
      // 이미 선택된 도메인인지 확인하고, 선택/해제 처리
      return prev.includes(domainName)
        ? prev.filter(domain => domain !== domainName) // 선택 해제
        : [...prev, domainName] // 선택 추가
    })
  }
  const authority = decodeAccessToken()?.adminAuthority
  const onSubmit = e => {
    if (!e.title || !searchRegistrationDate) {
      alert('필수사항을 모두 입력 후 등록해주세요.')
      return
    }

    const body = {
      popupKey,
      edgeName,
      title: e.title.trim(),
      status: e.popupStatus,
      imgName: e.imgName,
      domainUrl: selectedDomains.join(',') || null,
      createAt: convertToKst(searchRegistrationDate),
    }

    updatePopupList(body)
      .then(res => {
        alert('팝업 수정이 완료됐습니다.')
        onClickHandler()
        fetchSearchPopupList()
        return res
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'CUS-1014') {
          alert(`${message}`)
          onClickHandler()
          return
        }
        onClickHandler()
      })
  }

  const [deletePopupApiLoading, setDeletePopupApiLoading] = useState(false)

  const onClickPopupDeleteHandler = () => {
    if (deletePopupApiLoading) return

    setDeletePopupApiLoading(true)

    const body = {
      edgeName,
      popupKey,
    }

    if (window.confirm('정말로 삭제하시겠습니까?')) {
      deletePopup(body)
        .then(res => {
          alert('삭제되었습니다.')
          onClickHandler()
          fetchSearchPopupList()
          setDeletePopupApiLoading(false)
        })
        .catch(error => {
          alert('삭제실패했습니다.')
          setDeletePopupApiLoading(false)
        })
    }
  }

  return (
    <>
      {deletePopupApiLoading && <Loading />}
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer horizontal onSubmit={handleSubmit}>
            <FormGroup>
              <FormGroupLabel>사이트명</FormGroupLabel>
              <FormGroupField>
                <Field name="edgeName" component="input" type="text" disabled defaultValue={edgeName} />
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>제목</FormGroupLabel>
              <FormGroupField>
                <Field name="title" component="input" type="text" defaultValue={title || ''} />
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>도메인 목록</FormGroupLabel>
              <CustomFormGroupField>
                {domainCheckBox.map((item, index) => (
                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      name="domainUrl"
                      value={item.name}
                      checked={selectedDomains.includes(item.name)}
                      onChange={() => handleDomainChange(item.name)}
                    />
                    {item.name}
                  </CheckboxLabel>
                ))}
              </CustomFormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>노출</FormGroupLabel>
              {PopupStatusEnumsRadioOptions().map(item => (
                <FormGroupField
                  key={`index_${item.label}`}
                  style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                >
                  <Field
                    name={`${item.name}`}
                    component={renderRadioButtonField}
                    label={item.label}
                    radioValue={item.radioValue}
                    initialValue={status}
                  />
                </FormGroupField>
              ))}
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>등록일</FormGroupLabel>
              <FormGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchRegistrationDate}
                    onChange={date => onSearchRegistrationDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="클릭하세요"
                    isClearable
                    locale={ko}
                  />
                </SearchDatePickerWrap>
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>이미지 링크</FormGroupLabel>
              <FormGroupField>
                <Field name="imgName" component="input" type="text" defaultValue={imgName || ''} />
              </FormGroupField>
            </FormGroup>
            <div style={{ color: 'red', margin: '10px 0px' }}>팝업이미지 크기는 400px*600px로 만들어야합니다.</div>
            <FormGroup>
              <FormGroupLabel>현재 이미지</FormGroupLabel>
              <FormGroupField>
                <img src={imgName} style={{ width: '200px', height: '200px' }} alt="" />
              </FormGroupField>
            </FormGroup>
            {/* <FormGroup>
            <FormGroupLabel>이미지 첨부</FormGroupLabel>
            <FormGroupField>
              <FormGroupFile>
                <label htmlFor="imageFile">{imageFile ? <span>{imageFile.name}</span> : '사진을 선택하세요'}</label>
                <input type="file" name="imageFile" id="imageFile" onChange={handleChange} accept="image/*" />
              </FormGroupFile>
            </FormGroupField>
          </FormGroup> */}
            {(authority === 'SUPER' || authority === 'DB_DOWN') && (
              <FormButtonToolbar>
                <Button variant="primary" type="submit">
                  수정하기
                </Button>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    onClickPopupDeleteHandler()
                  }}
                >
                  삭제하기
                </Button>
              </FormButtonToolbar>
            )}
          </FormContainer>
        )}
      </Form>
    </>
  )
}

export default PopupModifyForm

PopupModifyForm.propTypes = {
  title: PropTypes.string,
  edgeName: PropTypes.string,
  popupKey: PropTypes.string,
  imgName: PropTypes.string,
  target: PropTypes.string,
  status: PropTypes.string,
  domainUrl: PropTypes.string,
  fetchSearchPopupList: PropTypes.func,
  onClickHandler: PropTypes.func,
  createAt: PropTypes.string.isRequired,
}

PopupModifyForm.defaultProps = {
  title: '',
  edgeName: '',
  popupKey: '',
  imgName: '',
  target: '',
  status: '',
  domainUrl: '',
  fetchSearchPopupList: null,
  onClickHandler: null,
}

const CustomFormGroupField = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
`

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`
