import Tooltip from '@/shared/components/Tooltip'
import Error from '@/shared/components/form/Error'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { BlockPicker, SketchPicker, ChromePicker } from 'react-color'
import { Button, OverlayTrigger } from 'react-bootstrap'
import {
  ExchagneDateEnums,
  ExchagneDateEnumsSelectOptions,
  IsMoneyExchangeRadioOptions,
  IsSettlementExchangeRadioOptions,
  MemberCasinoLevelEnum,
  MemberDepositLevelEnum,
  MemberMiniGameLevelEnum,
  MemberFriendCompLevelEnum,
  MemberModifyStatusEnums,
  MemberModifyStatusEnumsSelectOptions,
  MemberSportsLevelEnum,
  RecommendCodeStatusEnumsRadioOptions,
  UserAuthorityEnums,
  UserAuthorityEnumsSelectOptions,
  UserPointExchangeAutoEnumsRadioOptions,
  MemberHoldemLevelEnum,
  DepositBonusStatusEnumsRadioOptions,
  DuplicatedSignInEnumsRadioOptions,
  MemberVirtualGameLevelEnum,
  MemberTelecomEnumsSelectOptions,
  MemberTelecomEnums,
  IsMoneyDecExchangeRadioOptions,
} from '../../../../enums/MemberManage/MemberInfoEnums'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../../../../utils/palette'
import { PartnerLosingType } from '../../../../enums/Statistics/partnerLosingStatistics'
import { MemberResterBankSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { FormGroupField, FormInputWrap } from '../../../../shared/components/form/FormElements'
import {
  adminSearchBankSelectList,
  adminSearchPartnerSelectList,
  deleteFriendUser,
  deleteUser,
  searchMemberInfo,
  searchUserIgnoreSettlementInfo,
  updateMemberInfo,
  updateMemberRollingStatus,
  updateUserAssetReset,
  updateUserCasinoMoneyReset,
  updateUserHoldemMoneyReset,
  updateUserIgnoreSettlementInfo,
} from '../../../../utils/api/memberManage/memberManageApi'
import { searchLevelList } from '../../../../utils/api/systemManage/levelManageApi'
import { convertToKst, convertToKstDate, convertToKstDateTime } from '../../../../utils/dateTime'
import { commonReg2 } from '../../../../utils/validate/commonValidate'
import { memberInfoValidate } from '../../../../utils/validate/memberManage/memberInfoValidate'
import MemberBackRollingModal from '../MemberBackRollingModal/MemberBackRollingModal'
import MemberRollingModal from '../MemberRollingModal/MemberRollingModal'
import MemberZeroRollingModal from '../MemberZeroRollingModal/MemberZeroRollingModal'
import RecommendModal from './RecommendModal/RecommendModal'
import {
  ColorPickerButton,
  ColorPickerColor,
  ColorPickerColorView,
  ColorPickerPopover,
} from '../../../../shared/components/form/color-pickers/ColorPickerElements'
import { decodeAccessToken } from '../../../../utils/token'
import RollingPerModal from './RollingPerModal/RollingPerModal'
import { DatePickerWrap } from '../../../../shared/components/form/date-pickers/DatePickerElements'
import MemberLogOut from '../MemberLogOut/MemberLogOut'
import MemberRefresh from '../MemberRefresh/MemberRefresh'
import RecommendUserModal from './RecommendModal/RecommendUserModal'
import DisableGameModal from './DisableGameModal/DisableGameModal'

const MemberInfoUpdate = ({ memberId }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  const [userKey, setUserKey] = useState('')

  // 비밀번호
  const [memberPassword, setMemberPassword] = useState('')
  const [memberWithdrawalPassword, setMemberWithdrawalPassword] = useState('')
  // 연락처
  const [hiddenPhoneNum, setHiddenPhoneNum] = useState('')

  const [phoneNum, setPhoneNum] = useState('')
  const [nickName, setNickName] = useState('')

  const [isShowPhoneNum, setIsShowPhoneNum] = useState(false)
  const [memberName, setMemberName] = useState('')

  // 미니게임 ~ 스포츠 최대,최소액
  const [miniGameMinBetting, setMiniGameMinBetting] = useState('0')
  const [miniGameMaxBetting, setMiniGameMaxBetting] = useState('0')
  const [miniGameMaxWinning, setMiniGameMaxWinning] = useState('0')
  const [userBirthDay, setUserBirthDay] = useState(null)
  const [userBirthDayFirst, setUserBirthDayFirst] = useState('')
  const [userBirthDaySecond, setUserBirthDaySecond] = useState('')
  const [totalBettingAmount, setTotalBettingAmount] = useState('0')
  const [totalWinningAmount, setTotalWinningAmount] = useState('0')
  const [totalDepositAmount, setTotalDepositAmount] = useState('0')
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState('0')
  const [totalHoldemDepositAmount, setTotalHoldemDepositAmount] = useState('0')
  const [totalHoldemWithdrawalAmount, setTotalHoldemWithdrawalAmount] = useState('0')

  // 롤링 슬롯, 라이브 비율
  const [userSkinNum, setUserSkinNum] = useState('0')
  const [slotRollingPer, setSlotRollingPer] = useState('')
  const [liveRollingPer, setLiveRollingPer] = useState('')

  const [lineSlotRollingPer, setLineSlotRollingPer] = useState('')
  const [lineLiveRollingPer, setLineLiveRollingPer] = useState('')

  const [bonusFirstPer, setBonusFirstPer] = useState('')
  const [bonusEveryPer, setBonusEveryPer] = useState('')
  const [bonusOneMaxAmount, setBonusOneMaxAmount] = useState('')
  const [bonusOneLimitAmount, setBonusOneLimitAmount] = useState('')

  const [topPartnerRollingPer, setTopPartnerRollingPer] = useState('')

  // 예금주
  const [accountHolder, setAccountHolder] = useState('')

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  // 파트너 select
  const [partnerSelectOptionValue, setPartnerSelectOptionValue] = useState(null)
  const [partnerSelectOption, setPartnerSelectOption] = useState([])
  const onChangePartnerSelectHandler = option => {
    setPartnerSelectOptionValue(option)
  }

  // 회원 입금 레벨
  const [memberDepositLevelValue, setMemberDepositLevelValue] = useState(null)

  const [memberDepositLevelOptions, setMemberDepositLevelOptions] = useState([])
  const onChangeMemberDepositLevelHandler = option => {
    setMemberDepositLevelValue(option)

    setBonusFirstPer(option.bonusFirstPer)
    setBonusEveryPer(option.bonusEveryPer)
    setBonusOneMaxAmount(option.bonusOneMaxAmount)
    setBonusOneLimitAmount(option.bonusOneLimitAmount)
  }

  // 회원 입금 레벨
  const [memberFriendCompLevelValue, setMemberFriendCompLevelValue] = useState(null)
  const [memberFriendCompLevelOptions, setMemberFriendCompLevelOptions] = useState([])
  const onChangeMemberFriendCompLevelHandler = option => {
    setMemberFriendCompLevelValue(option)
  }

  // 회원 라이브 레벨
  const [memberLiveLevelValue, setMemberLiveLevelValue] = useState(null)
  const [memberLiveLevelOptions, setMemberLiveLevelOptions] = useState([])
  const onChangeMemberLiveLevelHandler = option => {
    setMemberLiveLevelValue(option)
    setLiveRollingPer(option.livePer)
  }

  // 회원 슬롯 레벨
  const [memberSlotLevelValue, setMemberSlotLevelValue] = useState(null)
  const [memberSlotLevelOptions, setMemberSlotLevelOptions] = useState([])
  const onChangeMemberSlotLevelHandler = option => {
    setMemberSlotLevelValue(option)
    setSlotRollingPer(option.slotPer)
  }

  // 회원 미니게임 레벨
  const [memberMiniGameLevelValue, setMemberMiniGameLevelValue] = useState(null)
  const [memberMiniGameLevelOptions, setMemberMiniGameLevelOptions] = useState([])
  const onChangeMemberMiniGameLevelHandler = option => {
    setMemberMiniGameLevelValue(option)
  }

  // 회원 스포츠게임 레벨
  const [memberSportsGameLevelValue, setMemberSportsGameLevelValue] = useState(null)
  const [memberSportsGameLevelOptions, setMemberSportsGameLevelOptions] = useState([])
  const onChangeMemberSportsGameLevelHandler = option => {
    setMemberSportsGameLevelValue(option)
  }

  // 회원 스포츠게임 레벨
  const [memberVirtualGameLevelValue, setMemberVirtualGameLevelValue] = useState(null)
  const [memberVirtualGameLevelOptions, setMemberVirtualGameLevelOptions] = useState([])
  const onChangeMemberVirtualGameLevelHandler = option => {
    setMemberVirtualGameLevelValue(option)
  }

  // 회원 홀덤 레벨
  const [memberHoldemLevelValue, setMemberHoldemLevelValue] = useState(null)
  const [memberHoldemLevelOptions, setMemberHoldemLevelOptions] = useState([])
  const onChangeMemberHoldemLevelHandler = option => {
    setMemberHoldemLevelValue(option)
  }

  // 계좌번호

  const [accountNum, setAccountNum] = useState('')

  // edgeName이 있어야 가능 !
  useEffect(() => {
    adminSearchBankSelectList({}).then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })

    adminSearchPartnerSelectList({ memberId }).then(res => {
      res.data.content.map(item =>
        setPartnerSelectOption(prev => [
          ...prev,
          {
            value: item.partnerId,
            label: item.partnerIdPlusLevel,
          },
        ]),
      )
    })

    searchLevelList().then(res => {
      const {
        depositLevelList,
        casinoLiveLevelList,
        casinoSlotLevelList,
        miniGameLevelList,
        sportsGameLevelList,
        virtualGameLevelList,
        friendCompLevelList,
        holdemLevelList,
      } = res.data
      friendCompLevelList.map(item =>
        setMemberFriendCompLevelOptions(prev => [
          ...prev,
          {
            value: item.friendCompLevelName,
            label: `${MemberFriendCompLevelEnum[item.friendCompLevelName]} (추천인 1인당 최대액: ${
              item.friendCompMaxAmount
            }원 / 추천인 라이브카지노 배팅액%: ${item.liveCasinoFriendBenefitBettingPer}% ...)`,
            friendCompMaxAmount: item.friendCompMaxAmount,
            liveCasinoFriendBenefitBettingPer: item.liveCasinoFriendBenefitBettingPer,
            slotCasinoFriendBenefitBettingPer: item.slotCasinoFriendBenefitBettingPer,
            miniGameFriendBenefitBettingPer: item.miniGameFriendBenefitBettingPer,
            miniGameFriendBenefitLossPer: item.miniGameFriendBenefitLossPer,
            sportsFriendBenefitBettingPer: item.sportsFriendBenefitBettingPer,
            sportsFriendBenefitLossPer: item.sportsFriendBenefitLossPer,
          },
        ]),
      )
      depositLevelList.map(item =>
        setMemberDepositLevelOptions(prev => [
          ...prev,
          {
            value: item.depositLevelName,
            label: `${MemberDepositLevelEnum[item.depositLevelName]}`,
            bonusFirstPer: item.bonusFirstPer,
            bonusEveryPer: item.bonusEveryPer,
            bonusOneMaxAmount: item.bonusOneMaxAmount,
            bonusOneLimitAmount: item.bonusOneLimitAmount,
          },
        ]),
      )
      casinoLiveLevelList.map(item =>
        setMemberLiveLevelOptions(prev => [
          ...prev,
          {
            value: item.casinoLevelName,
            label: `${MemberCasinoLevelEnum[item.casinoLevelName]} (본인 콤프율: ${item.livePer}%)`,
            livePer: item.livePer,
          },
        ]),
      )
      casinoSlotLevelList.map(item =>
        setMemberSlotLevelOptions(prev => [
          ...prev,
          {
            value: item.casinoLevelName,
            label: `${MemberCasinoLevelEnum[item.casinoLevelName]} (본인 콤프율: ${item.slotPer}%)`,
            slotPer: item.slotPer,
          },
        ]),
      )
      miniGameLevelList.map(item =>
        setMemberMiniGameLevelOptions(prev => [
          ...prev,
          {
            value: item.miniGameLevelName,
            label: `${MemberMiniGameLevelEnum[item.miniGameLevelName]} (본인 낙첨율:${
              item.miniGameLossPer
            }%/본인 콤프율:${item.miniGameCompPer}%)`,
            miniGameLossPer: item.miniGameLossPer,
            miniGameCompPer: item.miniGameCompPer,
          },
        ]),
      )
      sportsGameLevelList.map(item =>
        setMemberSportsGameLevelOptions(prev => [
          ...prev,
          {
            value: item.sportsGameLevelName,
            label: `${MemberSportsLevelEnum[item.sportsGameLevelName]} (국내형/해외형/스페셜/라이브)`,
            sportsGameLossPer: item.sportsGameLossPer,
            sportsGameCompPer: item.sportsGameCompPer,
          },
        ]),
      )

      virtualGameLevelList.map(item =>
        setMemberVirtualGameLevelOptions(prev => [
          ...prev,
          {
            value: item.virtualGameLevel,
            label: `${MemberVirtualGameLevelEnum[item.virtualGameLevel]}(축구/야구/농구/경마/개경주)`,
          },
        ]),
      )

      holdemLevelList.map(item =>
        setMemberHoldemLevelOptions(prev => [
          ...prev,
          {
            value: item.holdemLevelName,
            label: `${MemberHoldemLevelEnum[item.holdemLevelName]} (본인 낙첨율:${item.holdemLossPer}%/ 본인 콤프율:${
              item.holdemCompPer
            }%)`,
            holdemLossPer: item.holdemLossPer,
            holdemCompPer: item.holdemCompPer,
          },
        ]),
      )
    })
  }, [memberId])

  // 매장, 총판, 부본사, 본사 변수
  const [partnerLine, setPartnerLine] = useState('')

  // 회원 상태
  const [userExchangeDateValue, setUserExchangeDateValue] = useState(null)
  const userExchangeDateOptions = ExchagneDateEnumsSelectOptions()

  const onUserExchangeDateChangeHandler = option => {
    setUserExchangeDateValue(option)
  }

  const [userAuthorityValue, setUserAuthorityValue] = useState(null)
  const userAuthorityValueOptions = UserAuthorityEnumsSelectOptions()

  const onUserAuthorityValueChangeHandler = option => {
    setUserAuthorityValue(option)
  }

  // 회원 상태
  const [memberStatusValue, setMemberStatusValue] = useState(null)

  const memberStatusOptions = MemberModifyStatusEnumsSelectOptions()

  const onMemberStatusChangeHandler = option => {
    setMemberStatusValue(option)
  }

  const [memberTelecomValue, setMemberTelecomeValue] = useState(null)

  const memberTelecomOptions = MemberTelecomEnumsSelectOptions()

  const onMemberTelecomeChangeHandler = option => {
    setMemberTelecomeValue(option)
  }

  const [memberInfo, setMemberInfo] = useState({})
  const [userPointExchangeAuto, setUserPointExchangeAuto] = useState('true')
  const [recommendCodeStatus, setRecommendCodeStatus] = useState('true')
  const [depositBonusStatus, setDepositBonusStatus] = useState('true')

  // 중복 로그인 여부
  const [duplicatedSignIn, setDuplicatedSignIn] = useState('true')

  const [isMoneyIncExchange, setIsMoneyIncExchange] = useState('false')
  const [isMoneyDecExchange, setIsMoneyDecExchange] = useState('false')
  const [isSettlementExchange, setisSetIlementExchange] = useState('false')

  const [lastCasinoAgency, setLastCasinoAgency] = useState('')
  const [lastCasinoVendor, setLastCasinoVendor] = useState('')
  const [lastCasinoGame, setLastCasinoGame] = useState('')
  const [currentAccessGame, setCurrentAccessGame] = useState('')
  // 색상 코드
  const [colorValue, setColorValue] = useState('')
  const [active, setActive] = useState(false)
  const handleChange = changeColor => {
    setColorValue(changeColor.hex)
  }

  // 메모
  const [memo, setMemo] = useState('')
  const [depositMemo, setDepositMemo] = useState('')

  // 테스트 유저 여부
  const [tester, setTester] = useState(false)

  const fetchMemberInfo = async () => {
    setInfoLoading(true)

    await searchMemberInfo({
      memberId,
    })
      .then(res => {
        setMemberInfo(res.data.content)
        setUserKey(res.data.content.memberKey)
        setTester(res.data.content.tester)
        if (res.data.content.userBirthDay !== null) {
          setUserBirthDay(new Date(res.data.content.userBirthDay))
        }
        setUserBirthDayFirst(res.data.content.userBirthDayFirst)
        setUserBirthDaySecond(res.data.content.userBirthDaySecond)
        setUserSkinNum(res.data.content.userSkinNum)
        setMemberPassword(res.data.content.password)
        setPhoneNum(res.data.content.phoneNum)
        setHiddenPhoneNum('*'.repeat(res.data.content.phoneNum.length))
        setNickName(res.data.content.nickName)
        setSlotRollingPer(res.data.content.slotRollingPer)
        setLiveRollingPer(res.data.content.liveRollingPer)
        setLineSlotRollingPer(res.data.content.lineSlotRollingPer)
        setLineLiveRollingPer(res.data.content.lineLiveRollingPer)
        setTopPartnerRollingPer(res.data.content.topPartnerRollingPer)
        setMiniGameMinBetting(res.data.content.miniGameMinBetting.toString().replace(commonReg2, ','))
        setMiniGameMaxBetting(res.data.content.miniGameMaxBetting.toString().replace(commonReg2, ','))
        setMiniGameMaxWinning(res.data.content.miniGameMaxWinning.toString().replace(commonReg2, ','))
        setTotalBettingAmount(res.data.content.totalBettingAmount)
        setTotalWinningAmount(res.data.content.totalWinningAmount)
        setTotalDepositAmount(res.data.content.totalDepositAmount)
        setTotalWithdrawalAmount(res.data.content.totalWithdrawalAmount)
        setTotalHoldemDepositAmount(res.data.content.totalHoldemDepositAmount)
        setTotalHoldemWithdrawalAmount(res.data.content.totalHoldemWithdrawalAmount)
        setAccountHolder(res.data.content.accountHolder)
        setUserPointExchangeAuto(res.data.content.userPointExchangeAuto.toString())
        setRecommendCodeStatus(res.data.content.recommendCodeStatus.toString())
        setDepositBonusStatus(res.data.content.depositBonusStatus.toString())

        // 중복로그인
        setDuplicatedSignIn(res.data.content.duplicatedSignIn.toString())

        setMemberName(res.data.content.memberName)
        setBankNameOptionValue({
          value: res.data.content.bankInfo?.bankId,
          label: res.data.content.bankInfo?.bankName,
        })
        setPartnerSelectOptionValue({
          value: res.data.content.topPartnerId,
          label: res.data.content.topPartnerIdPlusLevel || '본인 최상위[1LV]',
        })
        setAccountNum(res.data.content.accountNum)

        setPartnerLine(res.data.content.partnerLine)
        setMemberStatusValue({
          value: res.data.content.status,
          label: MemberModifyStatusEnums[res.data.content.status],
        })

        setMemberTelecomeValue({
          value: res.data.content.telecom,
          label: MemberTelecomEnums[res.data.content.telecom],
        })

        setUserExchangeDateValue({
          value: res.data.content.userExchangeDate,
          label: ExchagneDateEnums[res.data.content.userExchangeDate],
        })

        setUserAuthorityValue({
          value: res.data.content.userAuthority,
          label: UserAuthorityEnums[res.data.content.userAuthority],
        })

        setMemberDepositLevelValue({
          value: res.data.content.depositLevel.depositLevelName,
          label: `${MemberDepositLevelEnum[res.data.content.depositLevel.depositLevelName]}`,
          bonusFirstPer: res.data.content.depositLevel.bonusFirstPer,
          bonusEveryPer: res.data.content.depositLevel.bonusEveryPer,
          bonusOneMaxAmount: res.data.content.depositLevel.bonusOneMaxAmount,
          bonusOneLimitAmount: res.data.content.depositLevel.bonusOneLimitAmount,
        })

        setMemberLiveLevelValue({
          value: res.data.content.casinoLiveLevel.casinoLevelName,
          label: `${MemberCasinoLevelEnum[res.data.content.casinoLiveLevel.casinoLevelName]} (콤프비율: ${
            res.data.content.casinoLiveLevel.livePer
          })`,
          livePer: res.data.content.casinoLiveLevel.livePer,
        })
        setMemberSlotLevelValue({
          value: res.data.content.casinoSlotLevel.casinoLevelName,
          label: `${MemberCasinoLevelEnum[res.data.content.casinoSlotLevel.casinoLevelName]} (콤프비율: ${
            res.data.content.casinoSlotLevel.slotPer
          })`,
          slotPer: res.data.content.casinoSlotLevel.slotPer,
        })
        setMemberFriendCompLevelValue({
          value: res.data.content.friendCompLevel.friendCompLevelName,
          label: `${
            MemberFriendCompLevelEnum[res.data.content.friendCompLevel.friendCompLevelName]
          } (추천인 1인당 최대액: ${
            res.data.content.friendCompLevel.friendCompMaxAmount
          }원 / 추천인 라이브카지노 배팅액%: ${
            res.data.content.friendCompLevel.liveCasinoFriendBenefitBettingPer
          }% ...)`,
          friendCompMaxAmount: res.data.content.friendCompLevel.friendCompMaxAmount,
          liveCasinoFriendBenefitBettingPer: res.data.content.friendCompLevel.liveCasinoFriendBenefitBettingPer,
          slotCasinoFriendBenefitBettingPer: res.data.content.friendCompLevel.slotCasinoFriendBenefitBettingPer,
          miniGameFriendBenefitBettingPer: res.data.content.friendCompLevel.miniGameFriendBenefitBettingPer,
          miniGameFriendBenefitLossPer: res.data.content.friendCompLevel.miniGameFriendBenefitLossPer,
          sportsFriendBenefitBettingPer: res.data.content.friendCompLevel.sportsFriendBenefitBettingPer,
          sportsFriendBenefitLossPer: res.data.content.friendCompLevel.sportsFriendBenefitLossPer,
        })
        setMemberMiniGameLevelValue({
          value: res.data.content.miniGameLevel.miniGameLevelName,
          label: `${MemberMiniGameLevelEnum[res.data.content.miniGameLevel.miniGameLevelName]} (본인 낙첨율:${
            res.data.content.miniGameLevel.miniGameLossPer
          }/ 본인 콤프율:${res.data.content.miniGameLevel.miniGameCompPer})`,
          miniGameLossPer: res.data.content.miniGameLevel.miniGameLossPer,
          miniGameCompPer: res.data.content.miniGameLevel.miniGameCompPer,
        })
        setMemberSportsGameLevelValue({
          value: res.data.content.sportsGameLevelName,
          label: `${MemberSportsLevelEnum[res.data.content.sportsGameLevelName]}`,
        })
        setMemberVirtualGameLevelValue({
          value: res.data.content.virtualGameLevelName,
          label: `${MemberVirtualGameLevelEnum[res.data.content.virtualGameLevelName]}`,
        })
        setMemberHoldemLevelValue({
          value: res.data.content.holdemLevel.holdemLevelName,
          label: `${MemberHoldemLevelEnum[res.data.content.holdemLevel.holdemLevelName]} (본인 낙첨율:${
            res.data.content.holdemLevel.holdemLossPer
          }/ 본인 콤프율:${res.data.content.holdemLevel.holdemCompPer})`,
          holdemLossPer: res.data.content.holdemLevel.holdemLossPer,
          holdemCompPer: res.data.content.holdemLevel.holdemCompPer,
        })
        setMemo(res.data.content.memo)
        setDepositMemo(res.data.content.depositMemo)

        setIsMoneyIncExchange(res.data.content.isMoneyIncExchange.toString())
        setIsMoneyDecExchange(res.data.content.isMoneyDecExchange.toString())
        setisSetIlementExchange(res.data.content.isSettlementExchange.toString())
        setLastCasinoAgency(res.data.content.lastCasinoAgency || '')
        setLastCasinoVendor(res.data.content.lastCasinoVendor || '')
        setLastCasinoGame(res.data.content.lastCasinoGame || '')
        setCurrentAccessGame(res.data.content.currentAccessGame)
        setColorValue(res.data.content.userColor || '#ffffff')
        setInfoLoading(false)
      })
      .catch(error => {
        setInfoLoading(false)
      })
  }

  const [ignoreSports, setIgnoreSports] = useState(false)
  const [ignoreLiveCasino, setIgnoreLiveCasino] = useState(false)
  const [ignoreSlotCasino, setIgnoreSlotCasino] = useState(false)
  const [ignoreMiniGame, setIgnoreMiniGame] = useState(false)
  const [ignoreHoldem, setIgnoreHoldem] = useState(false)
  const [ignoreVirtualGame, setIgnoreVirtualGame] = useState(false)
  const [ignoreDepositWithdrawal, setIgnoreDepositWithdrawal] = useState(false)
  const [ignoreIncDec, setIgnoreIncDec] = useState(false)

  const fetchUserIgnoreSettlementInfo = async () => {
    // setInfoLoading(true)

    await searchUserIgnoreSettlementInfo({
      userId: memberId,
    })
      .then(res => {
        setIgnoreSports(res.data.ignoreSports)
        setIgnoreLiveCasino(res.data.ignoreLiveCasino)
        setIgnoreSlotCasino(res.data.ignoreSlotCasino)
        setIgnoreMiniGame(res.data.ignoreMiniGame)
        setIgnoreHoldem(res.data.ignoreHoldem)
        setIgnoreVirtualGame(res.data.ignoreVirtualGame)
        setIgnoreDepositWithdrawal(res.data.ignoreDepositWithdrawal)
        setIgnoreIncDec(res.data.ignoreIncDec)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        // setInfoLoading(false)
      })
  }

  useEffect(() => {
    fetchMemberInfo()
    fetchUserIgnoreSettlementInfo()
  }, [memberId])

  const onSubmit = e => {
    if (authority === 'NORMAL') {
      return
    }
    if (infoLoading) return
    e.preventDefault()
    if (!window.confirm(`[${memberInfo?.memberName}] 유저의 정보를 수정하시겠습니까?`)) {
      return
    }

    // if (memberInfo.status === 'CANCEL' || memberInfo.status === 'APPLY') {
    //   alert('유저의 상태가 신청,취소 인 경우에는 회원정보를 변경할 수 없습니다.')
    //   return
    // }

    // if (
    //   memberInfo.status === 'BLOCK' &&
    //   (memberStatusValue.value === 'WAITING' ||
    //     memberStatusValue.status === 'APPLY' ||
    //     memberStatusValue.status === 'CANCEL')
    // ) {
    //   alert('블랙유저는 정상으로만 상태변경이 가능합니다.')
    //   return
    // }

    // if (
    //   memberInfo.status === 'NORMAL' &&
    //   (memberStatusValue.value === 'WAITING' ||
    //     memberStatusValue.status === 'APPLY' ||
    //     memberStatusValue.status === 'CANCEL')
    // ) {
    //   alert('정상유저는 블랙으로만 상태변경이 가능합니다.')
    //   return
    // }
    const body = {
      memberKey: memberInfo.memberKey,
      userSkinNum,
      password: memberPassword,
      phoneNum: !phoneNum ? '' : phoneNum,
      bankId: !bankNameOptionValue?.value ? null : bankNameOptionValue.value,
      accountNum: !accountNum ? '' : accountNum,
      topPartnerId: partnerSelectOptionValue.value,
      partnerLine,
      status: memberStatusValue.value,
      telecom: memberTelecomValue?.value,
      exchangeDate: !userExchangeDateValue?.value ? null : userExchangeDateValue.value,
      userAuthority: userAuthorityValue.value,
      userPointExchangeAuto,
      recommendCodeStatus,
      depositBonusStatus,
      userExchangeDate: !userExchangeDateValue?.value ? null : userExchangeDateValue.value,
      friendCompLevel: memberFriendCompLevelValue.value,
      depositLevel: memberDepositLevelValue.value,
      casinoLiveLevel: memberLiveLevelValue.value,
      casinoSlotLevel: memberSlotLevelValue.value,
      miniGameLevel: memberMiniGameLevelValue.value,
      sportsGameLevel: memberSportsGameLevelValue.value,
      virtualGameLevel: memberVirtualGameLevelValue.value,
      holdemLevel: memberHoldemLevelValue.value,
      miniGameMinBetting: miniGameMinBetting.toString().replaceAll(',', ''),
      miniGameMaxBetting: miniGameMaxBetting.toString().replaceAll(',', ''),
      miniGameMaxWinning: miniGameMaxWinning.toString().replaceAll(',', ''),
      memo,
      depositMemo,
      isMoneyIncExchange,
      isMoneyDecExchange,
      isSettlementExchange,
      userColor: colorValue,
      memberName,
      nickName,
      userBirthDayFirst,
      userBirthDaySecond,
      userBirthDay: !userBirthDay ? null : convertToKst(userBirthDay),
      duplicatedSignIn,
      tester,
    }

    setInfoLoading(true)

    updateMemberInfo(body)
      .then(res => {
        alert('유저 정보가 수정되었습니다.')
        fetchMemberInfo()
        setInfoLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1004') {
          alert(`${message}`)
        } else if (errorCode === 'ADMIN-1003') {
          alert(`${message}`)
        } else if (errorCode === 'PARTNER-1017') {
          alert(`${message}`)
        } else if (errorCode === 'PARTNER-1018') {
          alert(`${message}`)
        } else if (errorCode === 'PARTNER-1019') {
          alert(`${message}`)
        } else {
          alert('유저 정보 수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
        }

        setInfoLoading(false)
      })
  }

  /**
   * 롤링 수정 모달
   */
  const [memberRollingModalShow, setMemberRollingModalShow] = useState(false)
  const memberRollingModalHandler = () => {
    setMemberRollingModalShow(prev => !prev)
  }

  /**
   * 추천코드 수정 모달
   */
  const [recommendedCodeModalShow, setRecommendedCodeModalShow] = useState(false)
  const recommendedCodeModalHandler = () => {
    setRecommendedCodeModalShow(prev => !prev)
  }

  /**
   * 롤링비율 수정 모달
   */
  const [rollingModalShow, setRollingModalShow] = useState(false)
  const rollingModalHandler = () => {
    setRollingModalShow(prev => !prev)
  }

  /**
   * 공배팅 수정 모달
   */
  const [memberZeroBettingModalShow, setMemberZeroBettingModalShow] = useState(false)
  const memberZeroBettingModalHandler = () => {
    setMemberZeroBettingModalShow(prev => !prev)
  }

  /**
   * 환수율 수정 모달
   */
  const [memberRecoveryPerModalShow, setMemberRecoveryPerModalShow] = useState(false)
  const memberRecoveryPerModalHandler = () => {
    setMemberRecoveryPerModalShow(prev => !prev)
  }

  /**
   * 추천인 등록 모달
   */
  const [recommendUserModalShow, setRecommendUserModalShow] = useState(false)
  const recommendUserModalHandler = () => {
    setRecommendUserModalShow(prev => !prev)
  }

  /**
   * 이용게임 수정 모달
   */
  const [disableGameModalShow, setDisableGameModalShow] = useState(false)
  const disableGameModalHandler = () => {
    setDisableGameModalShow(prev => !prev)
  }

  const [deleteApiLoading, setDeleteApiLoading] = useState(false)

  const onClickDeleteUserHandler = () => {
    if (authority === 'NORMAL') {
      return
    }
    if (memberInfo.userAuthority !== 'MEMBER') {
      alert('파트너 영구 삭제는 지원하지 않습니다.')
      return
    }

    if (deleteApiLoading) return

    setDeleteApiLoading(true)

    const body = {
      userKey: memberInfo.memberKey,
    }

    if (
      window.confirm(
        `해당 유저의 관련된 모든 정보가 삭제되며, 복구 불가능합니다. \n[${memberInfo.memberId}] 유저의 정보를 정말로 영구 삭제 하시겠습니까?  \n삭제된 후에 해당 창은 닫힙니다.`,
      )
    ) {
      deleteUser(body)
        .then(res => {
          setDeleteApiLoading(false)
          window.close()
        })
        .catch(error => {
          alert(`${memberInfo.memberId} 유저 정보 삭제 실패했습니다.`)
          setDeleteApiLoading(false)
        })
    } else {
      setDeleteApiLoading(false)
    }
  }

  const onClickDeleteFriendUserHandler = friendId => {
    if (authority === 'NORMAL') {
      return
    }
    if (!friendId) {
      alert('해당 유저의 추천인이 존재하지 않습니다.')
      return
    }
    if (deleteApiLoading) return
    setDeleteApiLoading(true)

    const body = {
      userId: memberInfo.memberId,
      friendId,
    }

    if (
      window.confirm(
        `${memberInfo.memberId}유저와 추천인 ${friendId}의 관계가 해제되며, 복구 불가능합니다. \n[${memberInfo.memberId}] 유저의 추천인 정보를 정말로 해제 하시겠습니까?`,
      )
    ) {
      deleteFriendUser(body)
        .then(res => {
          fetchMemberInfo()
          setDeleteApiLoading(false)
          alert('추천인 삭제가 성공했습니다.')
        })
        .catch(error => {
          alert(`${memberInfo.memberId} 유저의 ${friendId}추천인 삭제 실패했습니다.`)
          setDeleteApiLoading(false)
        })
    } else {
      setDeleteApiLoading(false)
    }
  }

  const popover = (
    <ColorPickerPopover>
      <BlockPicker color={colorValue} onChange={handleChange} />
    </ColorPickerPopover>
  )

  const onChangeRollingStatus = moneySort => {
    if (infoLoading) return
    if (
      !window.confirm(
        `[${memberId}] 유저의 롤링% 정보(출금용)를 해제하시겠습니까? \n해당 기능은 1회성이며, 확인 시 유저는 출금버튼이 활성화 됩니다.`,
      )
    ) {
      return
    }

    const body = {
      moneySort,
      memberId,
    }
    setInfoLoading(true)

    updateMemberRollingStatus(body)
      .then(res => {
        setInfoLoading(false)
        alert('유저의 롤링%(출금용)이 해제되었습니다.')
        window.location.reload()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'CUS-1006') {
          alert(`${message}`)
        } else {
          alert('유저의 롤링%(출금용)이 해제가 실패했습니다.')
        }
      })
  }

  const koType = type => {
    switch (true) {
      case type === 'MONEY':
        return '지갑머니'
      case type === 'ROLLINGPOINT':
        return '롤링 포인트'
      case type === 'LOSINGPOINT':
        return '루징 포인트'
      case type === 'HOLDEM_MONEY':
        return '홀덤 머니'
      default:
        return ''
    }
  }

  const onChangeReset = type => {
    if (infoLoading) return
    if (
      window.confirm(
        `[${memberId}] 유저의 ${koType(
          type,
        )}을 초기화 하시겠습니까? \n초기화는 관리자 머니증감과 달리, 통계에 반영되지 않습니다.`,
      )
    ) {
      setInfoLoading(true)

      const body = {
        userId: memberId,
        type,
      }

      updateUserAssetReset(body)
        .then(res => {
          fetchMemberInfo()
          setInfoLoading(false)
          alert(`유저의 ${koType(type)}이 초기화 되었습니다.`)
          window.location.reload()
        })
        .catch(error => {
          setInfoLoading(false)
          alert(`유저의 ${koType(type)}이 초기화가 실패했습니다.`)
        })
    }
  }

  const onCasinoMoneyReset = type => {
    if (infoLoading) return
    if (
      window.confirm(`[${memberId}] 유저의 카지노 머니를 회수 하시겠습니까? \n회수 시 유저는 더이상 게임이 불가합니다.`)
    ) {
      setInfoLoading(true)

      const body = {
        userId: memberId,
      }

      updateUserCasinoMoneyReset(body)
        .then(res => {
          fetchMemberInfo()
          setInfoLoading(false)
          alert(`[${memberId}]의 이 카지노머니가 회수 되었습니다.`)
          window.location.reload()
        })
        .catch(error => {
          setInfoLoading(false)
          alert(`[${memberId}]의 이 카지노머니 회수가 실패했습니다.`)
        })
    }
  }

  const onHoldemMoneyReset = type => {
    if (infoLoading) return
    if (
      window.confirm(
        `[${memberId}] 유저의 ${koType(
          type,
        )}를 초기화 하시겠습니까? \n바이인 한 금액은 바이아웃전까지 회수가 불가합니다.`,
      )
    ) {
      setInfoLoading(true)

      const body = {
        userId: memberId,
        type,
      }

      updateUserAssetReset(body)
        .then(res => {
          fetchMemberInfo()
          setInfoLoading(false)
          alert(`유저의 ${koType(type)}이 초기화 되었습니다.`)
          window.location.reload()
        })
        .catch(error => {
          setInfoLoading(false)
          alert(`유저의 ${koType(type)}이 초기화가 실패했습니다.`)
        })
    }
  }

  const onClickUserIgnoreSettlement = () => {
    if (infoLoading) return

    setInfoLoading(true)

    const body = {
      userKey,
      userId: memberId,
      ignoreSports,
      ignoreLiveCasino,
      ignoreSlotCasino,
      ignoreMiniGame,
      ignoreHoldem,
      ignoreVirtualGame,
      ignoreDepositWithdrawal,
      ignoreIncDec,
    }

    updateUserIgnoreSettlementInfo(body)
      .then(res => {
        alert('변경 되었습니다.')
      })
      .catch(error => {
        alert('변경 실패했습니다.')
      })
      .finally(() => {
        fetchUserIgnoreSettlementInfo()
        setInfoLoading(false)
      })
  }

  return (
    <>
      {(infoLoading || deleteApiLoading) && <Loading />}
      {memberRollingModalShow && (
        <MemberRollingModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          memberRollingModalShow={memberRollingModalShow}
          memberRollingModalHandler={memberRollingModalHandler}
        />
      )}
      {recommendedCodeModalShow && (
        <RecommendModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          recommendedCodeModalShow={recommendedCodeModalShow}
          recommendedCodeModalHandler={recommendedCodeModalHandler}
        />
      )}
      {recommendUserModalShow && (
        <RecommendUserModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          recommendedCodeModalShow={recommendUserModalShow}
          recommendedCodeModalHandler={recommendUserModalHandler}
        />
      )}
      {rollingModalShow && (
        <RollingPerModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          recommendedCodeModalShow={rollingModalShow}
          recommendedCodeModalHandler={rollingModalHandler}
        />
      )}
      {memberZeroBettingModalShow && (
        <MemberZeroRollingModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          memberZeroBettingModalShow={memberZeroBettingModalShow}
          memberZeroBettingModalHandler={memberZeroBettingModalHandler}
        />
      )}
      {memberRecoveryPerModalShow && (
        <MemberBackRollingModal
          memberId={memberInfo.memberId}
          fetchSearchMember={fetchMemberInfo}
          memberRecoveryPerModalShow={memberRecoveryPerModalShow}
          memberRecoveryPerModalHandler={memberRecoveryPerModalHandler}
        />
      )}
      {disableGameModalShow && (
        <DisableGameModal
          memberId={memberInfo.memberId}
          memberName={memberInfo.memberName}
          partnerLine={memberInfo.partnerLine}
          status={memberInfo.status}
          fetchSearchMember={fetchMemberInfo}
          disableGameModalShow={disableGameModalShow}
          disableGameModalHandler={disableGameModalHandler}
        />
      )}

      <MemberInfoWrap>
        <MemberInfoButtonWrap>
          <button
            type="button"
            style={{
              background: 'gray',
            }}
            onClick={e => {
              window.location.reload()
            }}
          >
            새로고침
          </button>
          <button
            type="button"
            style={{
              background: 'red',
            }}
            onClick={e => {
              onClickDeleteUserHandler()
            }}
          >
            영구삭제
          </button>
        </MemberInfoButtonWrap>
        <Form
          onSubmit={onSubmit}
          validate={memberInfoValidate}
          initialValues={{
            //   memberId,
            memberPassword,
            userSkinNum,
            memberName: memberInfo?.memberName,
            phoneNum,
            memberLiveLevel: memberLiveLevelValue,
            memberSlotLevel: memberSlotLevelValue,
            slotRollingPer,
            liveRollingPer,
            topPartnerRollingPer,
            accountHolder,
            bankName: bankNameOptionValue,
            accountNum,
            partnerLine,
            userAuthority: userAuthorityValue,
            memberStatus: memberStatusValue,
            telecom: memberTelecomValue,
            topPartnerId: partnerSelectOptionValue,
            userExchangeDateValue,
            miniGameMinBetting,
            miniGameMaxBetting,
            miniGameMaxWinning,
            userPointExchangeAuto,
            recommendCodeStatus,
            depositBonusStatus,
            memo,
            depositMemo,
            nickName,
            isMoneyIncExchange,
            isMoneyDecExchange,
            isSettlementExchange,
            colorValue,
            userBirthDay,
            duplicatedSignIn,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <form onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <th>
                      아이디 <br /> 색상코드
                    </th>
                    <td>
                      {memberInfo?.memberId}
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={popover}
                          show={active}
                          onToggle={() => {
                            if (memberInfo.userAuthority === 'MEMBER' || memberInfo.partnerLevel !== 1) return
                            setActive(prev => !prev)
                          }}
                        >
                          <ColorPickerButton type="button" active={active} id="colorValue_id">
                            <ColorPickerColor>{colorValue}</ColorPickerColor>
                            <ColorPickerColorView style={{ backgroundColor: colorValue }} />
                          </ColorPickerButton>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <th>비밀번호</th>
                    <td>
                      <Field name="memberPassword">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              inputMode="latin"
                              value={input.value}
                              onChange={e => {
                                input.onChange(e)
                                setMemberPassword(e.target.value)
                              }}
                              style={{
                                width: '100%',
                              }}
                            />
                            <button
                              type="button"
                              style={{
                                width: '70px',
                                padding: '4px 2px',
                                marginLeft: '5px',
                                background: 'blue',
                                textAlign: 'center',
                                color: 'white',
                                fontWeight: '900',
                                border: 'none',
                              }}
                              onClick={() => {
                                if (window.confirm('비밀번호를 "999999"로 변경 하시겠습니까? ')) {
                                  // setIsPasswordReset(prev => !prev)
                                  setMemberPassword('999999')
                                }
                              }}
                            >
                              초기화
                            </button>
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>이름(예금주)</th>
                    <td>
                      <Field name="memberName">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={memberName}
                              onChange={e => {
                                input.onChange(e)
                                setMemberName(e.target.value)
                              }}
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>닉네임</th>
                    <td>
                      <Field name="nickName">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={nickName}
                              onChange={e => {
                                input.onChange(e)
                                setNickName(e.target.value)
                              }}
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>생년월일</th>
                    <td>
                      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        {' '}
                        {/* {memberInfo?.userBirthDayFirst
                        ? `${memberInfo?.userBirthDayFirst} - ${memberInfo?.userBirthDaySecond}******`
                          : '-'} */}
                        <Field name="userBirthDayFirst">
                          {({ input, meta }) => (
                            <FormInputWrap
                              style={{
                                width: '40%',
                              }}
                            >
                              <input
                                {...input}
                                type="text"
                                inputMode="latin"
                                value={userBirthDayFirst}
                                onChange={e => {
                                  setUserBirthDayFirst(e.target.value)
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                              {meta.error && <Error error={meta.error} mt="3" />}
                            </FormInputWrap>
                          )}
                        </Field>
                        <span style={{ width: '10%', textAlign: 'center', textJustify: 'center' }}>-</span>
                        <Field name="userBirthDaySecond">
                          {({ input, meta }) => (
                            <FormInputWrap
                              style={{
                                width: '10%',
                              }}
                            >
                              <input
                                {...input}
                                type="text"
                                inputMode="latin"
                                value={userBirthDaySecond}
                                onChange={e => {
                                  setUserBirthDaySecond(e.target.value)
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                              {meta.error && <Error error={meta.error} mt="3" />}
                            </FormInputWrap>
                          )}
                        </Field>
                        <span style={{ width: '10%', textAlign: 'center', textJustify: 'center' }}>******</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      {!isShowPhoneNum && phoneNum ? (
                        <>
                          <span
                            // type="button"
                            style={{
                              width: '100%',
                              padding: '4px',
                              textAlign: 'center',
                              color: '#000',
                              fontWeight: '900',
                              border: 'none',
                              display: 'block',
                              letterSpacing: '1px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setIsShowPhoneNum(prev => !prev)
                            }}
                          >
                            {hiddenPhoneNum}
                          </span>
                        </>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Field name="telecom">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <MemberResterBankSelect
                                  {...input}
                                  value={memberTelecomValue}
                                  onChange={option => {
                                    input.onChange(option)
                                    onMemberTelecomeChangeHandler(option)
                                  }}
                                  options={memberTelecomOptions}
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  style={{ width: '100%' }}
                                />
                                {meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                          <Field name="phoneNum">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={phoneNum}
                                  onChange={e => {
                                    input.onChange(e)
                                    setPhoneNum(e.target.value)
                                  }}
                                  placeholder="연락처를 입력하세요"
                                  style={{ width: '100%' }}
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </div>
                      )}
                    </td>
                    <th>은행</th>
                    <td>
                      <Field name="bankName">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              {...input}
                              value={bankNameOptionValue}
                              onChange={option => {
                                onChangeBankNameHandler(option)
                              }}
                              options={bankNameOption}
                              className="react-select"
                              isClearable
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>계좌번호</th>
                    <td>
                      <Field name="accountNum">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={accountNum}
                              onChange={e => {
                                input.onChange(e)
                                setAccountNum(e.target.value)
                              }}
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>가입일</th>
                    <td>{convertToKstDateTime(memberInfo?.registrationDate)}</td>
                    <th>최근 접속일</th>
                    <td>{convertToKstDateTime(memberInfo?.connectionDate)}</td>
                  </tr>
                  <tr>
                    <th>보유 머니</th>
                    <CustomTd moneyAmount={memberInfo?.moneyAmount}>
                      {memberInfo?.moneyAmount?.toString().replace(commonReg2, ',')}원
                      <br />
                      <button
                        type="button"
                        style={{
                          width: '80px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          onChangeReset('MONEY')
                        }}
                      >
                        초기화
                      </button>
                    </CustomTd>
                    <th>카지노 머니</th>
                    <CustomTd moneyAmount={memberInfo?.gameMoney}>
                      {memberInfo?.gameMoney?.toString().replace(commonReg2, ',')}원
                      <br />
                      <button
                        type="button"
                        style={{
                          width: '80px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={onCasinoMoneyReset}
                      >
                        회수
                      </button>
                    </CustomTd>
                    <th>홀덤 머니</th>
                    <CustomTd moneyAmount={memberInfo?.holdemMoneyAmount}>
                      {memberInfo?.holdemMoneyAmount?.toString().replace(commonReg2, ',')}원
                      <br />
                      <button
                        type="button"
                        style={{
                          width: '80px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          onHoldemMoneyReset('HOLDEM_MONEY')
                        }}
                      >
                        초기화
                      </button>
                    </CustomTd>
                    <th>
                      보유 포인트
                      <br />
                      (콤프,롤링,보너스)
                    </th>
                    <CustomTd moneyAmount={memberInfo?.rollingPointAmount}>
                      {memberInfo?.rollingPointAmount?.toString().replace(commonReg2, ',')} P
                      <br />
                      <button
                        type="button"
                        style={{
                          width: '80px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          onChangeReset('ROLLINGPOINT')
                        }}
                      >
                        초기화
                      </button>
                    </CustomTd>
                    <th>보유 루징 포인트</th>
                    <CustomTd moneyAmount={memberInfo?.losingPointAmount}>
                      {memberInfo?.losingPointAmount?.toString().replace(commonReg2, ',')} P
                      <br />
                      <button
                        type="button"
                        style={{
                          width: '80px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          onChangeReset('LOSINGPOINT')
                        }}
                      >
                        초기화
                      </button>
                    </CustomTd>
                  </tr>
                  <tr>
                    <th>유저 권한</th>
                    <td>
                      <Field name="userAuthority">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              {...input}
                              value={userAuthorityValue}
                              onChange={option => {
                                input.onChange(option)
                                onUserAuthorityValueChangeHandler(option)
                              }}
                              options={userAuthorityValueOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>루징 정산일</th>
                    <td>
                      {userAuthorityValue?.value === 'MEMBER' ? (
                        '회원일 경우 수정불가'
                      ) : (
                        <Field name="userExchangeDateValue">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <MemberResterBankSelect
                                {...input}
                                value={userExchangeDateValue}
                                onChange={option => {
                                  input.onChange(option)
                                  onUserExchangeDateChangeHandler(option)
                                }}
                                options={userExchangeDateOptions}
                                className="react-select"
                                classNamePrefix="react-select"
                                style={{ width: '100%' }}
                              />
                              {meta.error && <Error error={meta.error} mt="3" />}
                            </FormInputWrap>
                          )}
                        </Field>
                      )}
                    </td>

                    <th>정산 승인여부</th>
                    <td>
                      {userAuthorityValue?.value === 'MEMBER' ? (
                        '회원일 경우 수정불가'
                      ) : (
                        <>
                          {UserPointExchangeAutoEnumsRadioOptions().map(item => (
                            <FormGroupField
                              key={`index_${item.label}`}
                              style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                            >
                              <Field
                                name={`${item.name}`}
                                onCustomChange={e => {
                                  setUserPointExchangeAuto(e)
                                }}
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={userPointExchangeAuto}
                              />
                            </FormGroupField>
                          ))}
                        </>
                      )}
                    </td>

                    <th>
                      하부 머니증가
                      <br />
                      하부 머니회수
                    </th>
                    <td>
                      {userAuthorityValue?.value === 'MEMBER' ? (
                        '회원일 경우 수정불가'
                      ) : (
                        <>
                          {IsMoneyExchangeRadioOptions().map(item => (
                            <FormGroupField
                              key={`index__${item.label}`}
                              style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                            >
                              <Field
                                name="isMoneyIncExchange"
                                onCustomChange={e => {
                                  setIsMoneyIncExchange(e)
                                }}
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={isMoneyIncExchange}
                              />
                            </FormGroupField>
                          ))}
                          <br />
                          {IsMoneyDecExchangeRadioOptions().map(item => (
                            <FormGroupField
                              key={`index__${item.label}`}
                              style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                            >
                              <Field
                                name="isMoneyDecExchange"
                                onCustomChange={e => {
                                  setIsMoneyDecExchange(e)
                                }}
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={isMoneyDecExchange}
                              />
                            </FormGroupField>
                          ))}
                        </>
                      )}
                    </td>
                    <th>하부 정산비율 수정 </th>
                    <td>
                      {userAuthorityValue?.value === 'MEMBER' ? (
                        '회원일 경우 수정불가'
                      ) : (
                        <>
                          {IsSettlementExchangeRadioOptions().map(item => (
                            <FormGroupField
                              key={`index___${item.label}`}
                              style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                            >
                              <Field
                                name={`${item.name}`}
                                onCustomChange={e => {
                                  setisSetIlementExchange(e)
                                }}
                                component={renderRadioButtonField}
                                label={item.label}
                                radioValue={item.radioValue}
                                initialValue={isSettlementExchange}
                              />
                            </FormGroupField>
                          ))}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>최상위 파트너</th>
                    <td>{memberInfo?.topOfTopPartnerName}</td>
                    <th>직영 파트너</th>
                    <td>{memberInfo?.topPartnerName}</td>
                    <th>직영 파트너 정산방식</th>
                    <td style={{ color: 'red' }}>{PartnerLosingType[memberInfo?.settlementType]}</td>
                    <th>파트너 라인</th>
                    <td colSpan={3}>
                      <Tooltip text={memberInfo?.partnerLine} placement="bottom">
                        <span
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          {memberInfo?.partnerLine}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ color: 'orange' }}>총 배팅액</th>
                    <td>{totalBettingAmount?.toString().replace(commonReg2, ',')}원</td>
                    <th style={{ color: 'orange' }}>총 당첨액</th>
                    <td>{totalWinningAmount?.toString().replace(commonReg2, ',')}원</td>
                    <th style={{ color: 'orange', fontWeight: '600' }}>총 배팅액 - 총 당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.totalBettingProfitAmount}>
                      {memberInfo?.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                    <th>직영 파트너 이동</th>
                    <td>
                      <Field name="partnerSelect">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              {...input}
                              value={partnerSelectOptionValue}
                              onChange={option => {
                                onChangePartnerSelectHandler(option)
                              }}
                              options={partnerSelectOption}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>유저 상태</th>
                    <td>
                      <Field name="memberStatus">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              {...input}
                              value={memberStatusValue}
                              onChange={option => {
                                input.onChange(option)
                                onMemberStatusChangeHandler(option)
                              }}
                              options={memberStatusOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <th style={{ color: 'lightblue' }}>개별 롤링%(출금용) 수정</th>
                    <td>
                      <button
                        type="button"
                        style={{
                          width: '40px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={rollingModalHandler}
                      >
                        수정
                      </button>
                    </td>
                    <th style={{ color: 'lightblue' }}>출금롤링 제한해제</th>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <button
                          type="button"
                          style={{
                            width: 'maxContent',
                            height: '24px',
                            padding: '3px 5px',
                            background: 'green',
                            textAlign: 'center',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                          }}
                          onClick={() => {
                            onChangeRollingStatus('NORMAL_MONEY')
                          }}
                        >
                          일반 제한해제(1회성)
                        </button>
                        <button
                          type="button"
                          style={{
                            width: 'maxContent',
                            height: '24px',
                            padding: '3px 5px',
                            background: 'green',
                            textAlign: 'center',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                          }}
                          onClick={() => {
                            onChangeRollingStatus('HOLDEM_MONEY')
                          }}
                        >
                          홀덤 제한해제(1회성)
                        </button>
                      </div>
                    </td>
                    <th style={{ color: '#fa8c84' }}>입금보너스 허용여부</th>
                    <td>
                      {DepositBonusStatusEnumsRadioOptions().map(item => (
                        <FormGroupField
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name="depositBonusStatus"
                            onCustomChange={e => {
                              setDepositBonusStatus(e)
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={depositBonusStatus.toString()}
                          />
                        </FormGroupField>
                      ))}
                    </td>
                    <th>강제 로그아웃</th>
                    <td>
                      {memberInfo.loginNow && (
                        <MemberLogOut memberId={memberInfo.memberId} fetchSearchMember={() => {}} />
                      )}
                    </td>
                    <th>강제 새로고침</th>
                    <td>
                      {memberInfo.loginNow && (
                        <MemberRefresh userId={memberInfo.memberId} fetchSearchMember={() => {}} />
                      )}
                    </td>
                  </tr>

                  <DepositTr>
                    <th>입금 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberCasinoLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberDepositLevelValue}
                              onChange={option => {
                                input.onChange(option)
                                onChangeMemberDepositLevelHandler(option)
                              }}
                              options={memberDepositLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>총 입금액(일반/홀덤)</th>
                    <td>
                      {totalDepositAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {totalHoldemDepositAmount?.toString().replace(commonReg2, ',')}원
                    </td>
                    <th>총 출금액(일반/홀덤)</th>
                    <td>
                      {totalWithdrawalAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {totalHoldemWithdrawalAmount?.toString().replace(commonReg2, ',')}원
                    </td>
                    <th colSpan={2} style={{ fontWeight: '600' }}>
                      총 입금액 - 총 출금액(일반/홀덤)
                    </th>
                    <CustomTd moneyAmount={memberInfo?.totalMoneyProfitAmount}>
                      {memberInfo?.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {memberInfo?.totalHoldemMoneyProfitAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                  </DepositTr>
                  <LiveGameTr>
                    <th>라이브 콤프레벨</th>
                    <td colSpan={2}>
                      <Field name="memberLiveLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberLiveLevelValue}
                              onChange={option => {
                                input.onChange(option)
                                onChangeMemberLiveLevelHandler(option)
                              }}
                              options={memberLiveLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>
                      라이브 총 배팅액
                      <br />
                      (타이포함,공배팅제외)
                    </th>
                    <td>{memberInfo?.liveGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>
                      라이브 총 당첨액
                      <br />
                      (타이포함,공배팅제외)
                    </th>
                    <td>{memberInfo?.liveGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>
                      라이브 배팅액-당첨액
                      <br />
                      (타이포함,공배팅제외)
                    </th>
                    <CustomTd moneyAmount={memberInfo?.liveGameProfitAmount}>
                      {memberInfo?.liveGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </LiveGameTr>
                  <SlotGameTr>
                    <th>슬롯 콤프레벨</th>
                    <td colSpan={2}>
                      <Field name="memberSlotLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberSlotLevelValue}
                              onChange={option => {
                                input.onChange(option)
                                onChangeMemberSlotLevelHandler(option)
                              }}
                              options={memberSlotLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                              style={{ width: '100%' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>슬롯 총 배팅액</th>
                    <td>{memberInfo?.slotGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>슬롯 총 당첨액</th>
                    <td>{memberInfo?.slotGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>슬롯 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.slotGameProfitAmount}>
                      {memberInfo?.slotGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </SlotGameTr>
                  <SportsGameTr>
                    <th>스포츠 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberSportsGameLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberSportsGameLevelValue}
                              onChange={option => {
                                onChangeMemberSportsGameLevelHandler(option)
                              }}
                              options={memberSportsGameLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>스포츠 총 배팅액</th>
                    <td>{memberInfo?.sportsGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>스포츠 총 당첨액</th>
                    <td>{memberInfo?.sportsGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>스포츠 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.sportsGameProfitAmount}>
                      {memberInfo?.sportsGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </SportsGameTr>
                  <VirtualGameTr>
                    <th>가상게임 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberVirtualGameLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberVirtualGameLevelValue}
                              onChange={option => {
                                onChangeMemberVirtualGameLevelHandler(option)
                              }}
                              options={memberVirtualGameLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>가상게임 총 배팅액</th>
                    <td>{memberInfo?.virtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>가상게임 총 당첨액</th>
                    <td>{memberInfo?.virtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>가상게임 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.virtualGameProfitAmount}>
                      {memberInfo?.virtualGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </VirtualGameTr>
                  <MiniGameTr>
                    <th>미니게임 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberMiniGameLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberMiniGameLevelValue}
                              onChange={option => {
                                onChangeMemberMiniGameLevelHandler(option)
                              }}
                              options={memberMiniGameLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>미니게임 총 배팅액</th>
                    <td>{memberInfo?.miniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>미니게임 총 당첨액</th>
                    <td>{memberInfo?.miniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>미니게임 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.miniGameProfitAmount}>
                      {memberInfo?.miniGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </MiniGameTr>
                  <HoldemTr>
                    <th>홀덤게임 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberHoldemLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberHoldemLevelValue}
                              onChange={option => {
                                onChangeMemberHoldemLevelHandler(option)
                              }}
                              options={memberHoldemLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>홀덤 총 배팅액</th>
                    <td>{memberInfo?.holdemBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>홀덤 총 당첨액</th>
                    <td>{memberInfo?.holdemWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th colSpan={2}>홀덤 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.holdemProfitAmount}>
                      {memberInfo?.holdemProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </HoldemTr>
                  <FriendTr>
                    <th>추천인 레벨</th>
                    <td colSpan={2}>
                      <Field name="memberFriendCompLevel">
                        {({ input, meta }) => (
                          <CustomFormInputWrap>
                            <CustomSelect
                              {...input}
                              value={memberFriendCompLevelValue}
                              onChange={option => {
                                onChangeMemberFriendCompLevelHandler(option)
                              }}
                              options={memberFriendCompLevelOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </CustomFormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th style={{ color: '#66fae1' }}>
                      추천인 허용여부 <br />{' '}
                    </th>
                    <td>
                      {RecommendCodeStatusEnumsRadioOptions().map(item => (
                        <FormGroupField
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name="recommendCodeStatus"
                            onCustomChange={e => {
                              setRecommendCodeStatus(e)
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={recommendCodeStatus.toString()}
                          />
                        </FormGroupField>
                      ))}
                    </td>
                    <th style={{ color: '#66fae1' }}>내 추천코드</th>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3px' }}>
                        <div>
                          {memberInfo?.recommendedCode}
                          <button
                            type="button"
                            style={{
                              width: '40px',
                              height: '24px',
                              padding: '3px 2px',
                              background: 'green',
                              textAlign: 'center',
                              color: 'white',
                              fontWeight: '900',
                              border: 'none',
                              marginLeft: '10px',
                            }}
                            onClick={recommendedCodeModalHandler}
                          >
                            수정
                          </button>
                        </div>
                        <button
                          type="button"
                          style={{
                            width: 'max-content',
                            height: '24px',
                            padding: '3px 5px',
                            background: 'green',
                            textAlign: 'center',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                            marginLeft: '10px',
                          }}
                          onClick={() => {
                            recommendUserModalHandler(memberInfo?.friendId)
                          }}
                        >
                          피추천인 등록
                        </button>
                      </div>
                    </td>
                    <th colSpan={2}>
                      피추천인 수 <span style={{ fontSize: '11px' }}>(내 코드를 통해 가입한 회원)</span> / 나의 추천인ID
                    </th>
                    <td>
                      {memberInfo?.recommendedPersonCount?.toString().replace(commonReg2, ',')}명 <br />
                      {memberInfo?.friendId ? `${memberInfo?.friendId} [ ${memberInfo?.friendName} ]` : '-'}
                      <button
                        type="button"
                        style={{
                          width: '40px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'orange',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          onClickDeleteFriendUserHandler(memberInfo?.friendId)
                        }}
                      >
                        해제
                      </button>
                    </td>
                  </FriendTr>
                  <tr>
                    <th>
                      에볼루션 스킨번호
                      <br /> (아너링크)
                    </th>
                    <td>
                      <Field name="userSkinNum">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={userSkinNum}
                              onChange={e => {
                                input.onChange(e)
                                setUserSkinNum(e.target.value)
                              }}
                              style={{ width: '100%', marginRight: '5px' }}
                            />
                            <span style={{ width: '40px' }}>번</span>
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>
                      카지노 공배팅비율 <br /> (SLOT / LIVE)
                    </th>
                    <td>
                      {memberInfo?.zeroSlotRollingPer}/{memberInfo?.zeroLiveRollingPer}
                      <button
                        type="button"
                        style={{
                          width: '40px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={memberZeroBettingModalHandler}
                      >
                        수정
                      </button>
                    </td>
                    <th>
                      슬롯 환수율
                      <br />
                      (골드링크 파싱전용)
                    </th>
                    <td>
                      {memberInfo?.recoveryPer}
                      <button
                        type="button"
                        style={{
                          width: '40px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={memberRecoveryPerModalHandler}
                      >
                        수정
                      </button>
                    </td>
                    <th>
                      카지노 접속 현황
                      <Tooltip text="캐싱된 값이라서 실제로는 이용을 안하고 있을 수 있습니다." placement="bottom">
                        <span>
                          <CustomFaInfoCircle />
                        </span>
                      </Tooltip>
                    </th>
                    <td>
                      {currentAccessGame ? (
                        <span style={{ color: 'red', fontWeight: '600' }}>접속중</span>
                      ) : (
                        <span style={{ color: 'blue', fontWeight: '600' }}>미접속</span>
                      )}
                    </td>
                    <th>마지막 카지노 접속정보</th>
                    <td>
                      <span>
                        {!lastCasinoAgency ? '-' : `[${lastCasinoAgency}] ${lastCasinoVendor} - ${lastCasinoGame}`}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th>최소 배팅액(미니게임)</th>
                    <td>
                      <Field name="miniGameMinBetting">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={miniGameMinBetting}
                              onChange={e => {
                                input.onChange(e)
                                setMiniGameMinBetting(e.target.value)
                              }}
                              style={{ width: '100%', marginRight: '5px' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>최대 배팅액(미니게임)</th>
                    <td>
                      <Field name="miniGameMaxBetting">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={miniGameMaxBetting}
                              onChange={e => {
                                input.onChange(e)
                                setMiniGameMaxBetting(e.target.value)
                              }}
                              style={{ width: '100%', marginRight: '5px' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>최대 당첨액(미니게임)</th>
                    <td>
                      <Field name="maxMumFolderSize">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={miniGameMaxWinning}
                              onChange={e => {
                                input.onChange(e)
                                setMiniGameMaxWinning(e.target.value)
                              }}
                              style={{ width: '100%', marginRight: '5px' }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>중복 로그인</th>
                    <td>
                      {DuplicatedSignInEnumsRadioOptions().map(item => {
                        return (
                          <FormGroupField
                            key={`index_${item.label}_si`}
                            style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                          >
                            <Field
                              name="duplicatedSignIn"
                              onCustomChange={e => {
                                setDuplicatedSignIn(e)
                              }}
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={duplicatedSignIn.toString()}
                            />
                          </FormGroupField>
                        )
                      })}
                    </td>
                    <th>이용게임 제한</th>
                    <td>
                      <button
                        type="button"
                        style={{
                          width: '40px',
                          height: '24px',
                          padding: '3px 2px',
                          background: 'green',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          marginLeft: '10px',
                        }}
                        onClick={disableGameModalHandler}
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      테스트 유저 설정
                      <Tooltip text="모든 정산에서 제외됩니다." placement="bottom">
                        <span>
                          <CustomFaInfoCircle />
                        </span>
                      </Tooltip>
                    </th>
                    <td>
                      <CheckBoxWrapper>
                        <label htmlFor="tester">
                          <input
                            type="checkbox"
                            id="tester"
                            checked={tester}
                            onChange={() => {
                              setTester(prev => !prev)
                            }}
                          />
                          <span>설정</span>
                        </label>
                      </CheckBoxWrapper>
                    </td>
                    <th>
                      정산/통계 제외
                      <Tooltip text="해당 유저의 배팅에 대해서만 제외됩니다." placement="bottom">
                        <span>
                          <CustomFaInfoCircle />
                        </span>
                      </Tooltip>
                    </th>
                    <td colSpan={7}>
                      <CheckBoxWrapper>
                        <label htmlFor="ignoreDepositWithdrawal">
                          <input
                            type="checkbox"
                            id="ignoreDepositWithdrawal"
                            checked={ignoreDepositWithdrawal}
                            onChange={() => {
                              setIgnoreDepositWithdrawal(prev => !prev)
                            }}
                          />
                          <span>입금/출금</span>
                        </label>
                        <label htmlFor="ignoreSports">
                          <input
                            type="checkbox"
                            id="ignoreSports"
                            checked={ignoreSports}
                            onChange={() => {
                              setIgnoreSports(prev => !prev)
                            }}
                          />
                          <span>스포츠</span>
                        </label>
                        <label htmlFor="ignoreLiveCasino">
                          <input
                            type="checkbox"
                            id="ignoreLiveCasino"
                            checked={ignoreLiveCasino}
                            onChange={() => {
                              setIgnoreLiveCasino(prev => !prev)
                            }}
                          />
                          <span>라이브 카지노</span>
                        </label>
                        <label htmlFor="ignoreSlotCasino">
                          <input
                            type="checkbox"
                            id="ignoreSlotCasino"
                            checked={ignoreSlotCasino}
                            onChange={() => {
                              setIgnoreSlotCasino(prev => !prev)
                            }}
                          />
                          <span>슬롯</span>
                        </label>
                        <label htmlFor="ignoreMiniGame">
                          <input
                            type="checkbox"
                            id="ignoreMiniGame"
                            checked={ignoreMiniGame}
                            onChange={() => {
                              setIgnoreMiniGame(prev => !prev)
                            }}
                          />
                          <span>미니게임</span>
                        </label>
                        <label htmlFor="ignoreHoldem">
                          <input
                            type="checkbox"
                            id="ignoreHoldem"
                            checked={ignoreHoldem}
                            onChange={() => {
                              setIgnoreHoldem(prev => !prev)
                            }}
                          />
                          <span>홀덤</span>
                        </label>
                        <label htmlFor="ignoreVirtualGame">
                          <input
                            type="checkbox"
                            id="ignoreVirtualGame"
                            checked={ignoreVirtualGame}
                            onChange={() => {
                              setIgnoreVirtualGame(prev => !prev)
                            }}
                          />
                          <span>가상게임</span>
                        </label>
                        <label htmlFor="ignoreIncDec">
                          <input
                            type="checkbox"
                            id="ignoreIncDec"
                            checked={ignoreIncDec}
                            onChange={() => {
                              setIgnoreIncDec(prev => !prev)
                            }}
                          />
                          <span>머니증감</span>
                        </label>
                        <Button type="button" size="sm" variant="outline-danger" onClick={onClickUserIgnoreSettlement}>
                          수정
                        </Button>
                      </CheckBoxWrapper>
                    </td>
                  </tr>

                  <tr>
                    <th>메모</th>
                    <td colSpan={4}>
                      <Field name="memo">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <textarea
                              {...input}
                              type="text"
                              value={memo}
                              onChange={e => {
                                input.onChange(e)
                                setMemo(e.target.value)
                              }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>입금메모</th>
                    <td colSpan={4}>
                      <Field name="depositMemo">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <textarea
                              {...input}
                              type="text"
                              value={depositMemo}
                              onChange={e => {
                                input.onChange(e)
                                setDepositMemo(e.target.value)
                              }}
                            />
                            {meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                  </tr>
                </tbody>
              </table>
              {
                {
                  SUPER: (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <button
                        type="submit"
                        style={{
                          width: '200px',
                          height: '45px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: 'red',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: '900',
                          border: 'none',
                          fontSize: '25px',
                          marginTop: '5px',
                          marginBottom: '20px',
                        }}
                        onClick={onSubmit}
                      >
                        수정 하기
                      </button>
                    </div>
                  ),
                }[authority]
              }
            </form>
          )}
        </Form>
      </MemberInfoWrap>
    </>
  )
}

export default MemberInfoUpdate

MemberInfoUpdate.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const CheckBoxWrapper = styled.div`
  padding: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    cursor: pointer;
    padding: 3px;

    margin-bottom: 0;

    input {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 16px;
      margin-left: 5px;
    }

    &:hover {
      color: blue;
    }
  }
`

const CustomFaInfoCircle = styled(FaInfoCircle)`
  width: 14px;
  height: auto;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
`

const checkNumber = checkMoney => {
  return Math.sign(checkMoney)
}

const CustomTd = styled.td`
  color: ${props => (checkNumber(props.moneyAmount) > 0 ? 'red' : 'blue')};
  font-weight: 600;
`

const SlotGameTr = styled.tr`
  th {
    color: #59a6f7 !important;
  }
`

const DepositTr = styled.tr`
  th {
    color: #f26b83 !important;
  }
`
const LiveGameTr = styled.tr`
  th {
    color: #ec54f7 !important;
  }
`

const SportsGameTr = styled.tr`
  th {
    color: #35f01d !important;
  }
`

const VirtualGameTr = styled.tr`
  th {
    color: #ff600a !important;
  }
`

const MiniGameTr = styled.tr`
  th {
    color: yellow !important;
  }
`

const HoldemTr = styled.tr`
  th {
    color: #a966fa !important;
  }
`

const FriendTr = styled.tr`
  th {
    color: #66fae1 !important;
  }
`

const CustomFormInputWrap = styled(FormInputWrap)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomSelect = styled(MemberResterBankSelect)`
  width: 90%;
  border: solid 1px black;
  font-size: 14px;
`

const MemberInfoButtonWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  button {
    width: 100px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    outline: none;
  }
`

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 400px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 200px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
