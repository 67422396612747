import React from 'react'
import styled from 'styled-components'
import { getRevolutionGroupType, HoldemResultStatusEnums } from '../../../enums/Holdem/HoldemBettingEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { colorLightText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const HoldemBettingInfoData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저ID(명) - 권한',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            {original.uid}({original.userName}) - {NoteAuthorityEnums[original.userAuthority]}
          </>
        )
      },
    },
    {
      Header: '배팅번호',
      accessor: 'logId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '타입',
      accessor: 'groupType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{original.groupType ? getRevolutionGroupType(original.groupType) : '-'}</>
      },
    },
    {
      Header: '방번호',
      accessor: 'roomId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '블라인드(SB/BB)',
      accessor: 'blind',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.blind || '-'
      },
    },
    {
      Header: '배팅시간',
      accessor: 'bettingAt',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.bettingAt)
      },
    },
    {
      Header: '배팅액 (P)',
      accessor: 'bettingAmountCasino',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row

        return (
          <span style={{ fontWeight: '500' }}>{original.realBettingAmount.toString().replace(commonReg2, ',')}</span>
        )
      },
    },
    {
      Header: '당첨금 (P)',
      accessor: 'bettingResultAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'red', fontWeight: '500' }}>
            {original.realWinningAmount?.toString()?.replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '환율',
      accessor: 'krw',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span>{original.krw?.toString()?.replace(commonReg2, ',')}</span>
      },
    },
    {
      Header: '배팅액 (KRW)',
      accessor: 'fiatBettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ fontWeight: '500' }}>
            {original.fiatRealBettingAmount.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '당첨금 (KRW)',
      accessor: 'fiatWinningAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'red', fontWeight: '500' }}>
            {original.fiatRealWinningAmount?.toString()?.replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '결과',
      accessor: 'bettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <BettingResultWrap bettingResult={original.bettingResult}>
            {HoldemResultStatusEnums[original.bettingResult]}
          </BettingResultWrap>
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default HoldemBettingInfoData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'green'
    default:
      return colorLightText
  }
}

const BettingResultWrap = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${props => getColor(props.bettingResult)};
`
